import { SET_FUNDING_STREAMS } from '../actions/FundingStreamsActions'

const initialState = false

const FundingStreamsReducer = function (state = initialState, action) {
  
  switch (action.type) {
    case SET_FUNDING_STREAMS: {
      
      return action.data
    }
    default: {
      return state
    }
  }
}

export default FundingStreamsReducer
