export const SET_FUNDING_STREAMS = 'SET_FUNDING_STREAMS'

export function setFundingStreams (value) {
  return dispatch => {
    dispatch({
      type: SET_FUNDING_STREAMS,
      data: value
    })
  }
}
