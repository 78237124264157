import { Trans, t } from '@lingui/macro'
import {
  Button,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { animated, useSpring } from '@react-spring/web'
import { portalLanguagesData } from 'app/appSettings'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { insertValueToSFObject } from 'app/views/forms/Form'
import { requiredTrans } from 'app/views/forms/formTranslations'
import { MUEditedByLabel } from 'app/views/forms/multiuser/components/MUEditedByLabel'
import {
  endEditingField,
  startEditingField,
  unlockFieldWithoutChanges,
  updateLockedFieldValue
} from 'app/views/forms/multiuser/grpcMultiuserEdit'
import { useField, useFormikContext } from 'formik'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import {
  boundingExtent,
  createEmpty,
  extend,
  getHeight,
  getWidth
} from 'ol/extent'
import GeoJSON from 'ol/format/GeoJSON'
import 'ol/ol.css'
import { fromLonLat } from 'ol/proj'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { VariableSizeList } from 'react-window'
import { RLayerCluster, RLayerVector, RMap, ROSM } from 'rlayers'
import { RCircle, RFill, RStroke, RStyle, RText } from 'rlayers/style'
import tinycolor from 'tinycolor2'
import { myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { AutocompleteMuncipalities } from './FormCensusDivisionMuncipalityElements'
import { CenzusDivisionSelectProvince } from './FormCensusDivisionRegionalElements'
import caCitiesPointers from './ca_cities.json'
import caCities from './canada_cities_data.json'
import caProvinces from './canada_provinces.json'
import cenzusDivisionsById from './census_divisions_by_id.json'
import caDivisions from './cenzus_divisions.json'

export const canadaCenter = fromLonLat([-105.3809, 62.227])
const pruidToDivisionIdent = {
  10: 'NL',
  11: 'PE',
  12: 'NS',
  13: 'NB',
  24: 'QC',
  35: 'ON',
  46: 'MB',
  47: 'SK',
  48: 'AB',
  59: 'BC',
  60: 'YT',
  61: 'NT',
  62: 'NU'
}

const pruidToProvince = {
  10: 'Newfoundland and Labrador',
  11: 'Prince Edward Island',
  12: 'Nova Scotia',
  13: 'New Brunswick',
  24: 'Québec',
  35: 'Ontario',
  46: 'Manitoba',
  47: 'Saskatchewan',
  48: 'Alberta',
  59: 'British Columbia',
  60: 'Yukon',
  61: 'Northwest Territories',
  62: 'Nunavut'
}

const provinceToProvinceIdent = {
  'Newfoundland and Labrador': 'NL',
  'Prince Edward Island': 'PE',
  'Nova Scotia': 'NS',
  'New Brunswick': 'NB',
  Québec: 'QC',
  Ontario: 'ON',
  Manitoba: 'MB',
  Alberta: 'AB',
  Saskatchewan: 'SK',
  'British Columbia': 'BC',
  Yukon: 'YT',
  'Northwest Territories': 'NT',
  Nunavut: 'NU'
}

export const provinceAbbrevationToProvince = Object.fromEntries(
  Object.entries(provinceToProvinceIdent).map(a => a.reverse())
)

const getCenzusId = (obj, withSuffix = true) => {
  let id = obj.CDNAME || obj.name
  const add = obj.province_code || pruidToDivisionIdent[obj.PRUID]
  if (add) {
    id += ' (' + add + ')'
  }
  if (withSuffix) {
    id += ' [' + myI18n._(t`Cenzus divison`) + ']'
  }
  return id
}

export const formCenzusDivisionDefaultValue = (obj, info, item) => {
  const { typeProps } = item
  if (!typeProps.connectedTo || !typeProps.connectedTo[0] || !obj) {
    return {
      selectedScope: [],
      impactsProvincialNorth: 'None',
      scopeType: 'Neighbourhood',
      pilotPhase: null,
      pilotPhaseScopeType: null
    }
  }
  const {
    cenzusDivision,
    impactsProvincialNorth,
    pilotPhase,
    regionalScope,
    cities,
    provinces,
    pilotPhaseScopeType
  } = typeProps.connectedTo[0]
  let selectedScope = []

  if (cenzusDivision && obj[cenzusDivision.name]) {
    const sfValue = obj[cenzusDivision.name]
    sfValue.split(', ').forEach(id => {
      selectedScope.push(id + '_cenzus')
    })
  }
  if (cities && obj[cities.name]) {
    const sfValue = obj[cities.name]
    sfValue.split(', ').forEach(id => {
      //const parsedId = id.replace(/ *\[[^)]*\] */g, '')
      selectedScope.push(id.trim() + '_city')
    })
  }
  if (provinces && obj[provinces.name]) {
    const sfValue = obj[provinces.name]
    sfValue.split(', ').forEach(id => {
      if (id === 'Canada') {
        selectedScope.push('Canada')
      } else {
        const parsedId = id.replace(/ *\[[^)]*\] */g, '')
        selectedScope.push(parsedId.trim() + '_province')
      }
    })
  }
  let scopeTypeValue = regionalScope ? obj[regionalScope.name] : 'Neighbourhood'
  let pilotPhaseValue = pilotPhase && obj[pilotPhase.name]
  let pilotPhaseScopeTypeValue =
    pilotPhaseScopeType && obj[pilotPhaseScopeType.name]
  if (scopeTypeValue !== 'National') {
    pilotPhaseValue = null
    pilotPhaseScopeTypeValue = null
  }
  if (['Neighbourhood', 'Municipal'].includes(scopeTypeValue)) {
    selectedScope = selectedScope.filter(id => id.includes('_city'))[0]
  } else if (pilotPhase && obj[pilotPhase.name] === 'No') {
    selectedScope = ['Canada']
  }

  return {
    selectedScope,
    impactsProvincialNorth: impactsProvincialNorth
      ? obj[impactsProvincialNorth.name]
      : 'None',
    scopeType: scopeTypeValue,
    pilotPhase: pilotPhaseValue,
    pilotPhaseScopeType: pilotPhaseScopeTypeValue
  }
}

function parseValueToSF (value = []) {
  const toRet = []
  if (!Array.isArray(value)) {
    value = [value]
  }
  value.forEach(idString => {
    if (idString === 'Canada') {
      toRet.push('Canada')
    } else {
      if (geoData[idString]) {
        const { type, province, id } = geoData[idString]
        toRet.push(id)
        // if (type === 'city') {
        //   toRet.push(id + ' [' + province + ']') //[City] ')
        // } else {
        //   toRet.push(id)
        // }
        // else if (type === 'province') {
        //   toRet.push(id + ' [Province]')
        // } else if (type === 'cenzus') {
        //   toRet.push(id + ' [Cenzus Division]')
        // }
      }
    }
  })
  return toRet.join(', ')
}

export const formCenzusDivisionExtractKey = ({
  saveMap,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId
}) => {
  const {
    cenzusDivision,
    impactsProvincialNorth,
    pilotPhase,
    regionalScope,
    pilotPhaseScopeType,
    cities,
    provinces
  } = connectedProps
  const { scopeType } = value
  let selected = value.selectedScope || []
  if (!Array.isArray(selected)) {
    selected = [selected]
  }
  if (impactsProvincialNorth) {
    let toSet = value.impactsProvincialNorth || 'None'
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: toSet,
      fieldProps: impactsProvincialNorth,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }

  if (['Neighbourhood', 'Municipal'].includes(scopeType)) {
    const municipality = selected[0]
    const provinceAbr = geoData[municipality]?.province
    if (cenzusDivision) {
      const censusId = provinceAbr
        ? geoData[municipality].cenzusDivision + ' (' + provinceAbr + ')'
        : null
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: censusId,
        fieldProps: cenzusDivision,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
    if (provinces) {
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: provinceAbr ? provinceAbbrevationToProvince[provinceAbr] : null,
        fieldProps: provinces,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
  } else {
    if (cenzusDivision) {
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: parseValueToSF(selected.filter(id => id.includes('_cenzus'))),
        fieldProps: cenzusDivision,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
    if (provinces) {
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: parseValueToSF(
          selected.filter(id => id.includes('_province') || id === 'Canada')
        ),
        fieldProps: provinces,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
  }

  if (cities) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: parseValueToSF(selected.filter(id => id.includes('_city'))),
      fieldProps: cities,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (pilotPhase) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.pilotPhase,
      fieldProps: pilotPhase,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (regionalScope) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.scopeType,
      fieldProps: regionalScope,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (pilotPhaseScopeType) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.pilotPhaseScopeType,
      fieldProps: pilotPhaseScopeType,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
}

export const geoData = {
  Canada: {
    label: {
      en: 'Canada',
      fr: 'Canada',
      'iu-cans': 'Canada'
    },
    type: 'canada'
  },
  ...Object.fromEntries(
    caDivisions.features.map(obj => {
      return [
        getCenzusId(obj.properties, false) + '_cenzus',
        {
          id: getCenzusId(obj.properties, false),
          province: pruidToDivisionIdent[obj.properties.PRUID],
          type: 'cenzus',
          provincialNorth:
            cenzusDivisionsById[obj.properties.CDUID].provincialNorth,
          label: Object.fromEntries(
            Object.keys(portalLanguagesData).map(key => {
              const data = cenzusDivisionsById[obj.properties.CDUID]
              let name = data.name
              if (data.nameIU && key === 'iu-cans') {
                name = data.nameIU
              }
              return [
                key,
                name +
                  ' [' +
                  cenzusDivisionsById[obj.properties.CDUID]
                    ?.census_division_type +
                  ']'
              ]
            })
          )
        }
      ]
    })
  ),
  ...Object.fromEntries(
    caCities.map(obj => {
      const censusDivision = Object.values(cenzusDivisionsById).find(cd => {
        return (
          cd.name.replaceAll(',', '') ===
            obj.census_division.replaceAll('  ', ' ') &&
          obj.province_code === pruidToDivisionIdent[cd.PR_UID]
        )
      })

      if (!censusDivision) {
        console.log('No census division found for city', obj)
      }
      return [
        obj.name + ' [' + obj.province_code + ']_city',
        {
          province: obj.province_code,
          cenzusDivision: obj.census_division,
          id: obj.name + ' [' + obj.province_code + ']',
          type: 'city',
          provincialNorth: censusDivision?.provincialNorth,
          muncipalLabel: Object.fromEntries(
            Object.keys(portalLanguagesData).map(key => {
              let name = obj.name
              if (obj.nameIU && key === 'iu-cans') {
                name = obj.nameIU
              }
              return [key, name + ' (' + obj.province_code + ')']
            })
          ),
          label: Object.fromEntries(
            Object.keys(portalLanguagesData).map(key => {
              let name = obj.name
              if (obj.nameIU && key === 'iu-cans') {
                name = obj.nameIU
              }
              return [
                key,
                name +
                  ' (' +
                  obj.province_code +
                  ') [' +
                  myI18n._(t`City`) +
                  ']'
              ]
            })
          )
        }
      ]
    })
  ),
  ...Object.fromEntries(
    caProvinces.features.map(obj => {
      return [
        obj.properties.name + '_province',
        {
          type: 'province',
          location: obj.center,
          id: obj.properties.name,
          provincialNorth:
            obj.properties.name === 'Yukon' ||
            obj.properties.name === 'Northwest Territories' ||
            obj.properties.name === 'Nunavut',
          label: Object.fromEntries(
            Object.keys(portalLanguagesData).map(key => [
              key,
              <>
                <Trans id={obj.properties.name} />
                {' [' + myI18n._(t`Province`) + ']'}
              </>
            ])
          )
        }
      ]
    })
  )
}

const AutocompleteWithGeographicalOptions = ({
  onlyProvinces,
  onlyCenzusDivision,
  avaliableProvinces,
  scopeType,
  disabled,
  ...props
}) => {
  const classes = useStyles()
  const langVersion = useSelector(state => state.user.language)
  /** flag to check if the select panel is closed */
  const [closed, setClosed] = useState(true)

  return (
    <Autocomplete
      multiple
      fullWidth
      disableListWrap
      disableCloseOnSelect
      disableClearable
      disabled={disabled}
      options={Object.keys(geoData)
        .filter(item => {
          if (scopeType === 'Regional' && item.includes('_cenzus')) {
            const provinceId =
              provinceAbbrevationToProvince[geoData[item].province] +
              '_province'
            if (!props.value.includes(provinceId)) {
              return false
            }
          }
          if (onlyCenzusDivision && !item.includes('_cenzus')) {
            return false
          }
          if (onlyProvinces) {
            return item.includes('_province')
          } else if (avaliableProvinces && avaliableProvinces.length > 0) {
            const provinceAbbrevation = geoData[item].province
            return avaliableProvinces.includes(provinceAbbrevation)
          }
          return true
        })
        .sort((a, b) => a.localeCompare(b))}
      filterSelectedOptions
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGeohraphicalOptionsGroup}
      renderInput={params => (
        <TextField {...params} variant='outlined' label={props.label} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            key={option}
            label={getLabelFromTranslationData({
              data: geoData[option]?.label,
              langVersion
            })}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={option => (
        <Typography noWrap>
          {getLabelFromTranslationData({
            data: geoData[option]?.label,
            langVersion
          })}
        </Typography>
      )}
      onClose={e => {
        props?.onClose(e)
        setClosed(true)
      }}
      onBlur={e => {
        if (closed) {
          props?.onClose(e)
        }
      }}
      {...props}
    />
  )
}

export const formCenzusDivisionError = error => {
  if (error.selectedScope) {
    return error.selectedScope
  }
  return error
}

export const formCensusDivisionValidation = item =>
  Yup.object({
    selectedScope: Yup.mixed()
      .checkIfMuniciplaityIsRequired(requiredTrans)
      .nullable()
  }).checkCensusDivisionValidity(item)

Yup.addMethod(Yup.mixed, 'checkIfMuniciplaityIsRequired', function (trans) {
  return this.test('checkIfMuniciplaityIsRequired', null, function (value) {
    const { path, createError, parent } = this
    const {
      scopeType = 'Neighbourhood',
      pilotPhaseScopeType,
      pilotPhase
    } = parent
    const isPilotPhase = pilotPhase === 'Yes'
    const scopeToCheck = isPilotPhase ? pilotPhaseScopeType : scopeType
    if (['Neighbourhood', 'Municipal'].includes(scopeToCheck) && !value) {
      return createError({
        path,
        message: trans
      })
    }
    return true
  })
})

const atLeastOneCenzusPerProvinceTrans = (
  <Trans>
    CENZUS_DIVISION_ELEMENT_VALIDATION_ERROR_AT_LEAST_ONE_VALUE_PER_REGION
  </Trans>
)
const cenzusDivisionRequiredTrans = (
  <Trans>CENZUS_DIVISION_ELEMENT_SELECT_CENZUS_REQUIRED_LABEL</Trans>
)

Yup.addMethod(Yup.object, 'checkCensusDivisionValidity', function (item) {
  return this.test('checkCensusDivisionValidity', null, function (object) {
    const { path, createError, parent } = this
    const {
      selectedScope = [],
      pilotPhase,
      pilotPhaseScopeType = 'Neighbourhood',
      scopeType = 'Neighbourhood'
    } = object
    const { avaliableProvinces = [] } = item.typeProps
    const onlyOneProvinceAvaliable = avaliableProvinces.length === 1
    let notEnoughSelectedRegional = false
    const scopeToCheck = pilotPhase === 'Yes' ? pilotPhaseScopeType : scopeType
    if (scopeToCheck === 'Regional' && Array.isArray(selectedScope)) {
      const selectedPerProvince = Object.fromEntries(
        selectedScope
          .filter(id => id.includes('_province'))
          .map(id => [provinceToProvinceIdent[id.split('_')[0]], []])
      )
      selectedScope
        .filter(id => !id.includes('_province'))
        .forEach(id => {
          const data = geoData[id]
          if (data.province) {
            selectedPerProvince[data.province].push(id)
          }
        })
      notEnoughSelectedRegional = Object.values(selectedPerProvince).some(
        array => array.length === 0
      )
    }
    if (notEnoughSelectedRegional) {
      return createError({
        path,
        message: onlyOneProvinceAvaliable
          ? cenzusDivisionRequiredTrans
          : atLeastOneCenzusPerProvinceTrans
      })
    }
    return true
  })
})

// /**
//  * Form element which renders a map of Canada and allows selecting a set of geolocation data - Provinces, Cenzus Division and citites.
//  * @category Form
//  * @subcategory Form elements
//  * @component
//  * @returns {JSX.Element}
//  * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
//  * @param  {Object[]} [typeProps.predefinedGroups] A list of groups, predefined in editor which user can select, to instantly select a set of geolocation data.
//  * @param  {Object[]} [typeProps.avaliableProvinces] A list of provinces that will be avaliable to select from on the map. If no provinces are provided, then all of provinces will be avaliable to select from.
//  */
export function FormCensusDivision ({
  id,
  useMultiuser,
  formId,
  typeProps,
  disabled,
  formikRef,
  ...props
}) {
  const [prevCord, setPrevCord] = useState({ x: 0, y: 0 })
  const [current, setCurrent] = useState(null)
  const ref = useRef()
  const xDiff = window.innerWidth - prevCord.x
  const elementWidth = ref?.current?.offsetWidth || 30
  const switchSides = xDiff < elementWidth + 30
  const animStyles = useSpring({
    position: 'fixed',
    left: switchSides ? prevCord.x - 30 - elementWidth : prevCord.x + 30,
    top: prevCord.y,
    zIndex: 100,
    pointerEvents: 'none'
  })
  const user = useSelector(state => state.user)
  const { values = {} } = useFormikContext()
  const muInfo = values.muInfo
  /** is component locked by multiuser */
  const locked = muInfo[id]?.locked
  const lockId = muInfo?.lockId
  /** id of user who locked component */
  const lockedBy = muInfo[id]?.user
  const userId = props?.muBag?.userId
  const muUsers = values?.muUsers
  /** color of user who locked component */
  const userColor = muUsers?.[lockedBy]?.color
  /** name of user who locked component */
  const userName = muUsers?.[lockedBy]?.name
  /** is component disabled by multiuser */
  const disabledByMultiuser = locked && lockedBy !== userId

  return (
    <>
      {current && (
        <animated.div style={animStyles}>
          <Paper style={{ padding: 5 }} ref={ref}>
            {getLabelFromTranslationData({
              data: geoData[getCenzusId(current.values_, false) + '_cenzus']
                .label,
              langVersion: user.language
            })}
          </Paper>
        </animated.div>
      )}
      <FormCensusDivisionMap
        setPrevCord={setPrevCord}
        current={current}
        setCurrent={setCurrent}
        typeProps={typeProps}
        id={id}
        useMultiuser={useMultiuser}
        formId={formId}
        disabled={disabled || disabledByMultiuser}
        formikRef={formikRef}
        muBag={props.muBag}
        locked={locked}
        lockId={lockId}
        lockedBy={lockedBy}
        userId={userId}
        userColor={userColor}
        userName={userName}
      />
    </>
  )
}

export const scopeOptions = [
  {
    value: 'Neighbourhood',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_NEIGHBOURHOOD_OPTION</Trans>
  },
  {
    value: 'Municipal',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_MUNCIPAL_OPTION</Trans>
  },
  {
    value: 'Regional',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_REGIONAL_OPTION</Trans>
  },
  {
    value: 'Provincial or territorial',
    label: (
      <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_PROVINCE_OR_TERRITORY_OPTION</Trans>
    )
  },
  {
    value: 'National',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_National_OPTION</Trans>
  }
]

const initialMapView = { center: canadaCenter, zoom: 3.3 }

const FormCensusDivisionMap = ({
  setPrevCord,
  current,
  setCurrent,
  typeProps,
  id,
  formikRef,
  disabled,
  muBag,
  useMultiuser,
  locked,
  lockId,
  lockedBy,
  userId,
  userColor,
  userName
}) => {
  const [field, meta, helpers] = useField(id)
  const { value = {} } = field
  const { setFieldValue } = useFormikContext()

  const { enqueueSnackbar } = useSnackbar()

  /** is component locked by user and is user updating data to the server now */
  const [isUpdating, setIsUpdating] = useState(false)
  /** value for the future server update if user is updating data to the server now */
  const [valueToUpdate, setValueToUpdate] = useState(null)

  /** Not every FormCensusDivisionMap data changing needs to be send to the server to form a history.
   * So we need to save history value in the state and update it only when it needs to be updated. */
  const [historyValue, setHistoryValue] = useState(value)

  /** if there is value to update and user is not updating component now, update it */
  useEffect(() => {
    if (valueToUpdate && !isUpdating) {
      setMuFieldValue(valueToUpdate.id, valueToUpdate.value)
      setValueToUpdate(null)
    }
  }, [valueToUpdate, isUpdating])

  /** function to set field value
   * @param {string} id - id of field to update. Can be nested (e.g. 'id.subId')
   * @param {any} value - value to set
   */
  const setMuFieldValue = (id, value) => {
    /** if multiuser is not used, update value without multiuser */
    if (!useMultiuser) {
      return setFieldValue(id, value)
    }
    /** if field is locked by other user, do not update value */
    if (locked && lockedBy !== userId) {
      return
    }

    /** as recieved id can be nested, we need to get id of element to update and property to update */
    const [_id, prop] = id.split('.')
    let newValue = value

    /** if property is provided, we need to update this property of field only */
    if (prop) {
      newValue = { ...historyValue, [prop]: value }
    }
    /** compare new value with history value using lodash library. if values are equal, unlock field without changes */
    if (_.isEqual(historyValue, newValue)) {
      return unlockFieldWithoutChanges({
        onFail: () => {
          enqueueSnackbar(<Trans>Error ocurred while saving your data</Trans>, {
            variant: 'error'
          })
        },
        onSuccess: () => {},
        fieldId: field.name,
        token: muBag.token,
        lockId
      })
    }

    /** if user is updating field, do not update it now and save value to update in the future */
    if (isUpdating) {
      setHistoryValue(newValue)
      return setValueToUpdate({ id, value })
    }

    setIsUpdating(true)
    if (useMultiuser) {
      /** send update to multiuser server and unlock field */
      endEditingField({
        onFail: () => {
          enqueueSnackbar(<Trans>Error ocurred while saving your data</Trans>, {
            variant: 'error'
          })
          setIsUpdating(false)
        },
        onSuccess: () => {
          setHistoryValue(newValue)
          setIsUpdating(false)
        },
        lockId,
        fieldId: field.name,
        fieldValue: newValue,
        token: muBag.token
      })
    }
  }

  const {
    selectedScope = [],
    pilotPhase,
    pilotPhaseScopeType = 'Neighbourhood',
    scopeType = 'Neighbourhood'
  } = value
  const [view, setView] = React.useState(initialMapView)
  const [movingMap, setMovingMap] = React.useState(false)
  const chipContainer = useRef()
  const {
    predefinedGroups = [],
    avaliableProvinces = [],
    isOrganizationVariant // indicates if a form element is a variant of census division for organizations
  } = typeProps
  const onlyOneProvinceAvaliable = avaliableProvinces.length === 1
  const user = useSelector(state => state.user)
  const isPilotPhase = pilotPhase === 'Yes' && !isOrganizationVariant
  const scopeToCheck = isPilotPhase ? pilotPhaseScopeType : scopeType
  const municipalDisplay = ['Neighbourhood', 'Municipal'].includes(scopeToCheck)
  const regionalDisplay = ['Regional', 'Provincial or territorial'].includes(
    scopeToCheck
  )
  const provincialDisplay = scopeToCheck === 'Provincial or territorial'
  const onlyRegionalDisplay = scopeToCheck === 'Regional'

  /** This function is used to check if selected scope is in the north and should we display question about impacts in the north */
  const getIsNorth = () => {
    if (!selectedScope) return false
    if (typeof selectedScope === 'string') {
      return geoData[selectedScope]?.provincialNorth
    }
    return selectedScope.some(id => {
      return geoData[id]?.provincialNorth
    })
  }
  const isNorth = getIsNorth()

  const nationalDisplay = scopeToCheck === 'National'
  const provinceSelected =
    regionalDisplay && selectedScope.some(id => id.includes('_province'))
  const hideMap =
    municipalDisplay ||
    (!provinceSelected && !nationalDisplay) ||
    (isPilotPhase && !scopeToCheck)
  const elementsSpacing = 15

  const getAvaliableCenzusDivisions = useCallback(() => {
    let toRet = []
    if (avaliableProvinces && avaliableProvinces.length > 0) {
      const provinces = avaliableProvinces.map(
        id => provinceToProvinceIdent[id.split('_')[0]]
      )
      toRet = [...divisionsFeatures].filter(feature => {
        const provinceAbbreviation = pruidToDivisionIdent[feature.values_.PRUID]
        return provinces.includes(provinceAbbreviation)
      })
    } else {
      toRet = divisionsFeatures
    }
    return toRet
  }, [avaliableProvinces])

  // this useEffect used to zoom province to fit the screen when only one province is avaliable
  useEffect(() => {
    if (onlyOneProvinceAvaliable) {
      const value = scopeType
      let zoom = 5
      const provinceId = avaliableProvinces[0]
      if (
        provinceId === 'Nunavut_province' ||
        provinceId === 'Northwest Territories_province'
      ) {
        zoom = 4.2
      } else if (
        provinceId === 'Nova Scotia_province' ||
        provinceId === 'Prince Edward Island_province'
      ) {
        zoom = 6
      }
      setView({
        zoom,
        center: fromLonLat(geoData[provinceId].location)
      })
    }
  }, [onlyOneProvinceAvaliable, avaliableProvinces])

  const setMapMovingCallback = useCallback(e => {
    setMovingMap(true)
  }, [])
  const setMapNotMovingCallback = useCallback(e => {
    setMovingMap(false)
  }, [])

  const addValueCallback = useCallback(
    (newId, removable) => {
      if (formikRef) {
        const { setFieldValue, values } = formikRef.current
        const toSet = [...values[id].selectedScope]
        if (removable && toSet.includes(newId)) {
          if (toSet.includes(newId)) {
            toSet.splice(toSet.indexOf(newId), 1)
            setMuFieldValue(`${id}.selectedScope`, toSet)
          }
        } else {
          if (!toSet.includes(newId)) {
            toSet.push(newId)
            setMuFieldValue(`${id}.selectedScope`, toSet)
          }
        }
      }
    },
    [formikRef, id]
  )

  const PilotPhaseQuestionComponent = () => {
    if (isOrganizationVariant) return null

    return (
      <div>
        <FormLabel>
          <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION</Trans>
        </FormLabel>

        <RadioGroup
          value={pilotPhase}
          onChange={e => {
            const toSet = { ...field.value }
            toSet.pilotPhase = e.target.value
            if (e.target.value === 'No') {
              toSet.selectedScope = 'Canada'
            } else if (toSet.selectedScope === 'Canada') {
              toSet.selectedScope = []
            }
            setMuFieldValue(id, toSet)
          }}
        >
          <FormControlLabel
            disabled={disabled}
            value={'Yes'}
            control={<Radio />}
            label={<Trans>Yes</Trans>}
          />
          <FormControlLabel
            disabled={disabled}
            value={'No'}
            control={<Radio />}
            label={<Trans>No</Trans>}
          />
        </RadioGroup>
      </div>
    )
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={hideMap ? 12 : 6} style={{ padding: 10 }}>
          {predefinedGroups.length > 0 && !municipalDisplay && (
            <Grid container style={{ marginBottom: 10 }}>
              {predefinedGroups.map((obj, index) => {
                const { options = [], labelEN, labelFR } = obj
                return (
                  <Grid
                    item
                    key={index}
                    style={{ paddingRight: 5, paddingTop: 5 }}
                  >
                    <Button
                      color='primary'
                      variant='contained'
                      disabled={disabled}
                      onClick={e => {
                        const toSet = { ...field.value }
                        options.forEach(opt => {
                          if (!toSet.selectedScope.includes(opt)) {
                            toSet.selectedScope.push(opt)
                          }
                        })
                        setMuFieldValue(id, toSet)
                      }}
                    >
                      <Icon style={{ marginRight: 5 }}>add</Icon>
                      {user.language === 'en_US' ? labelEN : labelFR}
                    </Button>
                  </Grid>
                )
              })}
            </Grid>
          )}

          <div style={{ marginTop: elementsSpacing }}>
            <FormLabel>
              {isOrganizationVariant ? (
                <Trans>
                  CENZUS_DIVISION_ELEMENT_SCOPE_ORGANIZATION_VARIANT
                </Trans>
              ) : (
                <Trans>CENZUS_DIVISION_ELEMENT_SCOPE</Trans>
              )}
            </FormLabel>
            <RadioGroup
              value={scopeType}
              onChange={(event, value) => {
                const toSet = {
                  ...field.value,
                  pilotPhase: null,
                  pilotPhaseScopeType: null,
                  scopeType: value
                }
                if (value === 'Neighbourhood' || value === 'Municipal') {
                  if (Array.isArray(toSet.selectedScope)) {
                    toSet.selectedScope = ''
                  }
                } else {
                  if (!Array.isArray(toSet.selectedScope)) {
                    toSet.selectedScope = []
                  } else if (value === 'Provincial or territorial') {
                    toSet.selectedScope = [...selectedScope].filter(id =>
                      id.includes('_province')
                    )
                  } else if (value === 'National') {
                    toSet.selectedScope = []
                    setView(initialMapView)
                  }
                  if (onlyOneProvinceAvaliable) {
                    if (value === 'Regional') {
                      toSet.selectedScope.push(avaliableProvinces[0])
                    } else if (value === 'Provincial or territorial') {
                      toSet.selectedScope = [avaliableProvinces[0]]
                    }
                  }
                }
                setMuFieldValue(id, toSet)
              }}
            >
              {scopeOptions
                .filter(obj =>
                  onlyOneProvinceAvaliable ? obj.value !== 'National' : true
                )
                .map(obj => {
                  return (
                    <FormControlLabel
                      disabled={disabled}
                      value={obj.value}
                      control={<Radio />}
                      label={obj.label}
                    />
                  )
                })}
            </RadioGroup>

            {/* <CenzusDivisionSelectProvince
              disabled={disabled}
              id={id}
              selected={selectedScope}
              avaliableProvinces={avaliableProvinces}
              scopeType={scopeToCheck}
              setView={setView}
              isPilotPhase={isPilotPhase}
              setMuFieldValue={setMuFieldValue}
            /> */}
          </div>

          {scopeType === 'National' && isPilotPhase && (
            <div style={{ marginTop: elementsSpacing }}>
              <PilotPhaseQuestionComponent />
              <div style={{ marginTop: elementsSpacing }}>
                <FormLabel>
                  <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_PILOT_PHASE</Trans>
                </FormLabel>
              </div>
              <RadioGroup
                value={pilotPhaseScopeType}
                onChange={(event, value) => {
                  const toSet = {
                    ...field.value,
                    pilotPhaseScopeType: value
                  }
                  if (value === 'Neighbourhood' || value === 'Municipal') {
                    if (Array.isArray(toSet.selectedScope)) {
                      toSet.selectedScope = ''
                    }
                  } else {
                    if (!Array.isArray(toSet.selectedScope)) {
                      toSet.selectedScope = []
                    } else if (value === 'Provincial or territorial') {
                      toSet.selectedScope = [...selectedScope].filter(id =>
                        id.includes('_province')
                      )
                    }
                  }
                  setMuFieldValue(id, toSet)
                }}
              >
                {scopeOptions
                  .filter(obj => obj.value !== 'National')
                  .map(obj => {
                    return (
                      <FormControlLabel
                        disabled={disabled}
                        value={obj.value}
                        control={<Radio />}
                        label={obj.label}
                      />
                    )
                  })}
              </RadioGroup>
            </div>
          )}

          <div style={{ paddingTop: elementsSpacing }}>
            {municipalDisplay ? (
              <div>
                <FormLabel>
                  {isPilotPhase ? (
                    <Trans>
                      CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE
                    </Trans>
                  ) : (
                    <Trans>CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY</Trans>
                  )}
                </FormLabel>
                <AutocompleteMuncipalities
                  id={id}
                  value={selectedScope || ''}
                  disabled={disabled}
                  avaliableProvinces={avaliableProvinces.map(
                    id => provinceToProvinceIdent[id.split('_')[0]]
                  )}
                  scopeType={scopeToCheck}
                  onChange={(e, value) => {
                    setFieldValue(`${id}.selectedScope`, value)
                    if (useMultiuser) {
                      updateLockedFieldValue({
                        lockId,
                        fieldId: id,
                        fieldValue: { ...field.value, selectedScope: value },
                        ...muBag
                      })
                    }
                  }}
                  onFocus={e => {
                    if (useMultiuser) {
                      /** lock field for editing */
                      startEditingField({
                        onFail: () => {
                          enqueueSnackbar(
                            <Trans>Error ocurred while saving your data</Trans>,
                            {
                              variant: 'error'
                            }
                          )
                        },
                        onSuccess: () => {},
                        fieldId: field.name,
                        token: muBag.token,
                        userId: muBag.userId
                      })
                    }
                  }}
                  onClose={e => {
                    /** this element */
                    const $this = e.target
                    /** currently focused element */
                    const $focused = document.activeElement
                    /** if focused element is this element, do not unlock field */
                    if ($this === $focused) {
                      return
                    }
                    if (useMultiuser) {
                      /** set field value and unlock field */
                      setMuFieldValue(id, value)
                    }
                  }}
                  label={<div />}
                  renderOption={option => (
                    <Typography noWrap>
                      {getLabelFromTranslationData({
                        data: geoData[option]?.muncipalLabel,
                        langVersion: user.language
                      })}
                    </Typography>
                  )}
                />
                {/* label that shows username who locked field */}
                <div>
                  <MUEditedByLabel color={userColor} userName={userName} />
                </div>
                {isNorth && (
                  <div>
                    <ImpactsProvincialNorth
                      setMuFieldValue={setMuFieldValue}
                      field={field}
                      id={id}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                {nationalDisplay && !pilotPhaseScopeType && (
                  <PilotPhaseQuestionComponent />
                )}

                {(regionalDisplay ||
                  (nationalDisplay && pilotPhase === 'Yes')) && (
                  <>
                    <CenzusDivisionSelectProvince
                      disabled={disabled}
                      id={id}
                      selected={selectedScope}
                      avaliableProvinces={avaliableProvinces}
                      scopeType={scopeToCheck}
                      setView={setView}
                      isPilotPhase={isPilotPhase}
                      setMuFieldValue={setMuFieldValue}
                      isOrganizationVariant={isOrganizationVariant}
                    />
                    {isNorth &&
                      (!isPilotPhase || provincialDisplay) &&
                      !(onlyRegionalDisplay && !isPilotPhase) && (
                        <div>
                          <ImpactsProvincialNorth
                            setMuFieldValue={setMuFieldValue}
                            field={field}
                            id={id}
                            disabled={disabled}
                          />
                        </div>
                      )}
                  </>
                )}

                {Boolean(
                  (scopeToCheck === 'Regional' ||
                    (nationalDisplay && isPilotPhase)) &&
                    selectedScope.some(id => id.includes('_province'))
                ) && (
                  <>
                    <div
                      style={{ paddingBottom: 10, marginTop: elementsSpacing }}
                    >
                      <FormLabel>
                        {scopeToCheck === 'Regional' &&
                          (isPilotPhase ? (
                            <Trans>CENZUS_DIVISION_ELEMENT_SEARCH_FOR_CENZUS_DIVISIONS_PILOT_PHASE</Trans>
                          ) : (
                            <Trans>CENZUS_DIVISION_ELEMENT_SEARCH_FOR_CENZUS_DIVISIONS_ORGANIZATION_VARIANT</Trans>
                          ))}
                        {scopeToCheck === 'National' && (
                          <Trans>
                            CENZUS_DIVISION_ELEMENT_SELECT_PILOT_PHASE_SCOPE
                          </Trans>
                        )}
                      </FormLabel>
                    </div>
                    <AutocompleteWithGeographicalOptions
                      value={selectedScope || []}
                      disabled={disabled}
                      scopeType={scopeToCheck}
                      onlyCenzusDivision={scopeToCheck === 'Regional'}
                      avaliableProvinces={avaliableProvinces.map(
                        id => provinceToProvinceIdent[id.split('_')[0]]
                      )}
                      onChange={(e, value) => {
                        setFieldValue(`${id}.selectedScope`, value)
                        if (useMultiuser) {
                          updateLockedFieldValue({
                            lockId,
                            fieldId: id,
                            fieldValue: {
                              ...field.value,
                              selectedScope: value
                            },
                            ...muBag
                          })
                        }
                      }}
                      onFocus={e => {
                        if (useMultiuser) {
                          /** lock field for editing */
                          startEditingField({
                            onFail: () => {
                              enqueueSnackbar(
                                <Trans>
                                  Error ocurred while saving your data
                                </Trans>,
                                {
                                  variant: 'error'
                                }
                              )
                            },
                            onSuccess: () => {},
                            fieldId: field.name,
                            token: muBag.token,
                            userId: muBag.userId
                          })
                        }
                      }}
                      onClose={e => {
                        /** this element */
                        const $this = e.target
                        /** currently focused element */
                        const $focused = document.activeElement
                        /** if focused element is this element, do not unlock field */
                        if ($this === $focused) {
                          return
                        }
                        if (useMultiuser) {
                          /** set field value and unlock field */
                          setMuFieldValue(id, value)
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => null}
                    />

                    <FormErrorLabel id={id} />

                    {/* label that shows username who locked field */}
                    <div>
                      <MUEditedByLabel color={userColor} userName={userName} />
                    </div>
                    {Array.isArray(selectedScope) && selectedScope.length > 0 && (
                      <div style={{ marginTop: 15 }}>
                        <FormLabel>
                          <Trans>CENZUS_DIVISION_ELEMENT_SELECTED_LABEL</Trans>
                        </FormLabel>
                        <Grid
                          ref={chipContainer}
                          item
                          xs
                          container
                          alignItems='flex-start'
                        >
                          {selectedScope
                            .filter(id => !id.includes('_province'))
                            .map(chipId => (
                              <Chip
                                label={getLabelFromTranslationData({
                                  data: geoData[chipId]?.label,
                                  langVersion: user.language
                                })}
                                style={{ marginRight: 5, marginBottom: 5 }}
                                disabled={disabled}
                                onDelete={e => {
                                  const toSet = { ...field.value }
                                  toSet.selectedScope.splice(
                                    toSet.selectedScope.indexOf(chipId),
                                    1
                                  )
                                  setMuFieldValue(id, toSet)
                                }}
                              />
                            ))}
                        </Grid>
                        <div style={{ paddingTop: 10 }}>
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={selectedScope.length === 0}
                            onClick={e => {
                              const toSet = { ...field.value }
                              toSet.selectedScope = toSet.selectedScope.filter(
                                id => id.includes('_province')
                              )
                              setMuFieldValue(id, toSet)
                            }}
                          >
                            <Trans>
                              CENZUS_DIVISION_ELEMENT_CLEAR_ALL_BUTTON
                            </Trans>
                          </Button>
                        </div>
                        {isNorth && (
                          <div style={{ marginTop: '20px' }}>
                            <ImpactsProvincialNorth
                              setMuFieldValue={setMuFieldValue}
                              field={field}
                              id={id}
                              disabled={disabled}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>

        {!hideMap && (
          <Grid
            item
            xs={6}
            style={{
              padding: 10
            }}
          >
            <div
              onMouseLeave={e => {
                setCurrent(null)
              }}
              onMouseMove={e => {
                if (current && !movingMap) {
                  setPrevCord({
                    x: e.nativeEvent.pageX,
                    y: e.nativeEvent.pageY
                  })
                }
              }}
              onWheel={e => {
                setMovingMap(true)
              }}
            >
              <RMap
                width={'100%'}
                height={'700px'}
                view={[view, setView]}
                initial={initialMapView}
                extent={boundingExtent([
                  fromLonLat([-143, 90]),
                  fromLonLat([-50, 40])
                ])}
                onPointerDrag={setMapMovingCallback}
                onMoveEnd={setMapNotMovingCallback}
              >
                <ROSM />

                {scopeToCheck === 'Provincial or territorial' ||
                (scopeType === 'National' && pilotPhase !== 'Yes') ? (
                  <NonInteractiveCenzusDivisonsLayer
                    value={selectedScope}
                    pilotPhase={pilotPhase}
                    getAvaliableCenzusDivisions={getAvaliableCenzusDivisions}
                    scopeType={scopeToCheck}
                    isOrganizationVariant={isOrganizationVariant}
                  />
                ) : (
                  <CenzusDivisionsLayer
                    valueChanged={selectedScope.length}
                    value={selectedScope}
                    current={current}
                    addValue={addValueCallback}
                    setCurrent={setCurrent}
                    getAvaliableCenzusDivisions={getAvaliableCenzusDivisions}
                    disabled={disabled || movingMap}
                  />
                )}
              </RMap>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

/** This component is used to display question about impacts when user selects provinces in the north
 * @param {Object} props - component props
 * @param {Function} props.setMuFieldValue - function to set field value
 * @param {Object} props.field - field object
 * @param {string} props.id - id of field
 * @param {boolean} props.disabled - is component disabled
 * @returns {JSX.Element}
 */
const ImpactsProvincialNorth = ({ setMuFieldValue, field, id, disabled }) => {
  const impactsProvincialNorth = field?.value?.impactsProvincialNorth

  useEffect(() => {
    /** set default value for impactsProvincialNorth */
    setMuFieldValue(
      `${id}.impactsProvincialNorth`,
      field?.value?.impactsProvincialNorth || 'Impacts'
    )

    /** when user deselects north provinces this element is unmounted and impactsProvincialNorth is set to None */
    return () => {
      setMuFieldValue(`${id}.impactsProvincialNorth`, 'None')
    }
  }, [])

  return (
    <div>
      <FormLabel>
        <Trans>CENZUS_DIVISION_ELEMENT_NORTH_FOCUS_QUESTION</Trans>
      </FormLabel>

      <RadioGroup
        value={impactsProvincialNorth}
        onChange={e => {
          const toSet = { ...field.value }
          toSet.impactsProvincialNorth = e.target.value
          setMuFieldValue(id, toSet)
        }}
      >
        <FormControlLabel
          disabled={disabled}
          value='Focus'
          control={<Radio />}
          label={<Trans>Yes</Trans>}
        />
        <FormControlLabel
          disabled={disabled}
          value='Impacts'
          control={<Radio />}
          label={<Trans>No</Trans>}
        />
      </RadioGroup>
    </div>
  )
}


export const extentFeatures = (features, resolution) => {
  const extent = createEmpty()
  for (const f of features) extend(extent, f.getGeometry().getExtent())
  return Math.round(
    Math.round(0.35 * (getWidth(extent) + getHeight(extent))) / resolution
  )
}

const provincesFeatures = new GeoJSON({
  featureProjection: 'EPSG:3857'
  //featureProjection: 'EPSG:4617'
}).readFeatures(caProvinces)

const divisionsFeatures = new GeoJSON({
  featureProjection: 'EPSG:3857'
  //featureProjection: 'EPSG:4617'
}).readFeatures(caDivisions)

const NonInteractiveCenzusDivisonsLayer = React.memo(
  ({
    value,
    getAvaliableCenzusDivisions,
    pilotPhase,
    scopeType,
    isOrganizationVariant
  }) => {
    const theme = useTheme()
    const { main, dark } = theme.palette.primary
    const tcMain = tinycolor(main)
    tcMain.setAlpha(0.4)
    const provincial = scopeType === 'Provincial or territorial'
    const national = scopeType === 'National'

    return (
      <>
        <RLayerVector zIndex={5} features={getAvaliableCenzusDivisions()}>
          <RStyle
            render={useCallback(
              (feature, resolution) => {
                const provinceId =
                  pruidToProvince[feature.values_.PRUID] + '_province'
                const isSelected =
                  value.includes(
                    getCenzusId(feature.values_, false) + '_cenzus'
                  ) ||
                  (provincial && value.includes(provinceId)) ||
                  (national && pilotPhase === 'No') ||
                  (national && isOrganizationVariant)
                let borderColor = main
                let fillColor = 'transparent'
                if (isSelected) {
                  borderColor = dark
                  fillColor = tcMain.toRgbString()
                }
                return (
                  <>
                    <RStroke color={borderColor} width={1} />
                    <RFill color={fillColor} />
                  </>
                )
              },
              [value, provincial, national, pilotPhase]
            )}
          />
        </RLayerVector>

        <RLayerVector zIndex={5} features={provincesFeatures}>
          <RStyle
            render={useCallback(
              feature => {
                const isSelected = value.includes(
                  feature.values_.name + '_province'
                )
                return (
                  <RStroke
                    color={isSelected ? dark : 'transparent'}
                    width={2}
                  />
                )
              },
              [value]
            )}
          />
        </RLayerVector>
      </>
    )
  }
)

const CenzusDivisionsLayer = React.memo(
  ({
    current,
    disabled,
    setCurrent,
    value,
    valueChanged,
    addValue,
    getAvaliableCenzusDivisions
  }) => {
    const theme = useTheme()
    const { main, dark } = theme.palette.primary
    const tcMain = tinycolor(main)
    tcMain.setAlpha(0.4)

    return (
      <>
        <RLayerVector
          zIndex={5}
          features={getAvaliableCenzusDivisions()}
          onPointerEnter={useCallback(
            e => {
              if (!disabled) {
                setCurrent(e.target)
              }
            },
            [disabled]
          )}
          onPointerMove={useCallback(
            e => {
              if (!disabled && e.target !== current) {
                setCurrent(e.target)
              }
            },
            [disabled, current]
          )}
          onPointerLeave={useCallback(
            e => {
              if (current === e.target && !disabled) {
                setCurrent(null)
              }
            },
            [current, disabled]
          )}
          onClick={useCallback(
            e => {
              if (!disabled) {
                let id = getCenzusId(e.target.values_, false) + '_cenzus'
                addValue(id, true)
                e.stopPropagation()
              }
            },
            [disabled]
          )}
        >
          <RStyle
            render={useCallback(
              (feature, resolution) => {
                const provinceId =
                  pruidToProvince[feature.values_.PRUID] + '_province'
                if (!value.includes(provinceId)) {
                  return null
                }
                const isSelected = value.includes(
                  getCenzusId(feature.values_, false) + '_cenzus'
                )
                const isHovered = feature === current
                let borderColor = main
                let fillColor = 'transparent'
                if (isSelected) {
                  borderColor = dark
                  fillColor = tcMain.toRgbString()
                }
                if (isHovered) {
                  //fillColor = selectedHoverColor
                  borderColor = dark
                }

                return (
                  <>
                    <RStroke color={borderColor} width={isHovered ? 5 : 1} />
                    <RFill color={fillColor} />
                  </>
                )
              },
              [current, value, valueChanged]
            )}
          />
        </RLayerVector>

        <RLayerVector zIndex={5} features={provincesFeatures}>
          <RStyle
            render={useCallback(
              feature => {
                const isSelected = value.includes(
                  feature.values_.name + '_province'
                )
                return (
                  <RStroke
                    color={isSelected ? dark : 'transparent'}
                    width={4}
                  />
                )
              },
              [value, valueChanged]
            )}
          />
        </RLayerVector>
      </>
    )
  }
)

const citiesFeatures = new GeoJSON({
  featureProjection: 'EPSG:3857'
}).readFeatures(caCitiesPointers)
const cityDisabledColor = 'rgba(124, 124, 124, 0.7)'

const CitiesClusterLayer = React.memo(
  ({
    current,
    value,
    addValue,
    setCurrent,
    disableCitySelect,
    getAvaliableCities,
    setPrevCord
  }) => {
    const theme = useTheme()
    const cacheSize = 1024 * 5
    const { main, dark } = theme.palette.primary
    const tcMain = tinycolor(main)

    return (
      <>
        <RLayerCluster
          zIndex={10}
          distance={40}
          features={getAvaliableCities()}
          onPointerLeave={useCallback(e => setCurrent(null), [])}
          onPointerMove={useCallback(e => {
            const features = e.target.values_.features
            if (features.length === 1) {
              //console.log(e.target.values_.features[0].values_.name)
              setCurrent(e.target.values_.features[0])
              e.stopPropagation()
            }
            setPrevCord({
              x: e.originalEvent.screenX,
              y: e.originalEvent.screenY
            })
          }, [])}
          onClick={useCallback(
            e => {
              const features = e.target.values_.features
              if (features.length === 1) {
                const target = features[0].values_
                if (
                  (!disableCitySelect ||
                    value.join(';').includes(target.name)) &&
                  current?.values_?.name === target.name
                ) {
                  const id = target.name + '_city'
                  addValue(id, true)
                  e.stopPropagation()
                }
                if (value.join(';').includes(target.name)) {
                  e.stopPropagation()
                }
              }
            },
            [disableCitySelect, current]
          )}
        >
          <RStyle
            cacheSize={cacheSize}
            cacheId={useCallback(
              (feature, resolution) =>
                feature.get('features').length > 1
                  ? '#' + extentFeatures(feature.get('features'), resolution)
                  : '$' + feature.get('features')[0].values_.name,
              []
            )}
            render={useCallback(
              (feature, resolution) => {
                const size = feature.get('features').length
                if (size > 1) {
                  const radius = extentFeatures(
                    feature.get('features'),
                    resolution
                  )
                  return (
                    <React.Fragment>
                      <RCircle radius={radius}>
                        <RFill
                          color={tcMain
                            .setAlpha(
                              Math.min(0.8, 0.4 + Math.log(size / 10) / 20)
                            )
                            .toRgbString()}
                        />
                      </RCircle>
                      <RText text={size.toString()}>
                        <RFill color='#fff' />
                        <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                      </RText>
                    </React.Fragment>
                  )
                }
                const unclusteredFeature = feature.get('features')[0]
                const name = unclusteredFeature.values_.name
                const isSelected = value.join(';').includes(name)
                const isHovered = name === current?.values_?.name
                let color = main
                if (isSelected) {
                  color = dark
                } else if (disableCitySelect) {
                  color = cityDisabledColor
                }
                return (
                  <React.Fragment>
                    <RCircle
                      radius={
                        isSelected || (isHovered && !disableCitySelect) ? 14 : 8
                      }
                    >
                      <RFill color={color} />
                    </RCircle>
                    {/* <RText text={isSelected ? name + '\n[SELECTED]' : name}>
                      <RFill color='#fff' />
                      <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                    </RText> */}
                  </React.Fragment>
                )
              },
              [value, disableCitySelect, current]
            )}
          />
        </RLayerCluster>
        {/* //Rendering Text separatly is necessary because rendering Text with Shape changes onClick hitbox in a really werid way */}
        <RLayerCluster zIndex={10} distance={40} features={citiesFeatures}>
          <RStyle
            cacheSize={cacheSize}
            cacheId={useCallback((feature, resolution) => {
              const size = feature.get('features').length
              if (size > 1) {
                return 'NULL'
              }
              return 'NAME_' + feature.get('features')[0].values_.name
            }, [])}
            render={useCallback((feature, resolution) => {
              const size = feature.get('features').length
              if (size > 1) {
                return null
              }
              const unclusteredFeature = feature.get('features')[0]
              return (
                <RText text={unclusteredFeature.values_.name}>
                  <RFill color='#fff' />
                  <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                </RText>
              )
            }, [])}
          />
        </RLayerCluster>
      </>
    )
  }
)

const LISTBOX_PADDING = 8 // px
const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

function renderRow (props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache (data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

export const renderGeohraphicalOptionsGroup = params => [
  <ListSubheader key={params.key} component='div'>
    {params.group}
  </ListSubheader>,
  params.children
]

export const ListboxComponent = React.forwardRef(function ListboxComponent (
  props,
  ref
) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

// /**
//  * Helper component to render FormCenzusDivision element in print mode
//  * and readabale format in other places
//  * @param {object} census - converted value from FormCenzusDivision element
//  */
export const CensusDivisionPrint = ({ census }) => {
  return (
    <Paper elevation={6} style={{ marginTop: 10, padding: 10 }}>
      <Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>CENZUS_DIVISION_ELEMENT_SCOPE</Trans>
          </Typography>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {census.scopeType}
          </Typography>
        </Grid>
        {(census.muncipality) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.muncipality}
            </Typography>
          </Grid>
        )}
        {(census.provinces) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.provinces}
            </Typography>
          </Grid>
        )}
        {(census.selected) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_SELECTED_LABEL</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.selected}
            </Typography>
          </Grid>
        )}
        {(census.pilotPhase) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.pilotPhase}
            </Typography>
          </Grid>
        )}
        {(census.pilotPhaseScopeType) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_SELECT_PILOT_PHASE_SCOPE</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.pilotPhaseScopeType}
            </Typography>
          </Grid>
        )}
        {(census.pilotPhaseMuncipality) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>
                CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE
              </Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.pilotPhaseMuncipality}
            </Typography>
          </Grid>
        )}
        {(census.pilotPhaseProvinces) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES_PILOT_PHASE</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {census.pilotPhaseProvinces}
            </Typography>
          </Grid>
        )}
        {(census.pilotPhaseSelected) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_SELECTED_LABEL</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {typeof census.pilotPhaseSelected === "string" ? <Trans>{census.pilotPhaseSelected}</Trans>: census.pilotPhaseSelected}
            </Typography>
          </Grid>
        )}
        {(census.impactsProvincialNorth) && (
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>CENZUS_DIVISION_ELEMENT_NORTH_FOCUS_QUESTION</Trans>
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {typeof census.impactsProvincialNorth === "string" ? <Trans>{census.impactsProvincialNorth}</Trans>: census.impactsProvincialNorth}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export function cenzusDivisionParseValueToCompare (value, props) {
  const keys = Object.keys(value)
  // create a new object which not contains the keys with no value
  const valueToCompare = keys.reduce((acc, key) => {
    if (value[key] !== undefined && value[key] !== null && value[key] !== '') {
      acc[key] = value[key]
    }
    return acc
  }, {})
  return valueToCompare
}
