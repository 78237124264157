import { Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Alert, AlertTitle } from '@material-ui/lab'
import { prequalificationApprovedStages } from 'app/services/sfAuth/sfData/sfPrequalification'
import { getInitialValues } from 'app/views/forms/FormHelpersFormik'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormPage, handleFormSave, mapFormElements } from '../../forms/Form'
import { constructValidationSchema } from '../../forms/FormHelpersValidation'
import { connectedObjectQuery } from '../../forms/FormsHelpersQueries'
import { constructFormAddressString } from '../../forms/common/Common'
import ProgressSnackbar from '../../page-layouts/CustomSnackbars'
import { EligibilityCard } from './EligibilityCard'

export const ConfigurableEligibilityDialog = ({
  open = false,
  configurations,
  activePrequalificationFundingStreams,
  forms,
  fetchData,
  handleClose
}) => {
  const currentOrganization = useSelector(state => state.organization)
  const fundingStreams = useSelector(state => state.fundingStreams.streams)
  const user = useSelector(state => state.user)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [activeStep, setActiveStep] = useState(0)
  const [validationSchema, setValidationSchema] = useState(null)
  const [connectedMap, setConnectedMap] = useState({})
  const [loadingForm, setLoadingForm] = useState(false)
  const langVersion = useSelector(
    state => state.user?.language?.split('_')[0] || 'en'
  )
  const history = useHistory()
  const formRef = useRef()
  const avaliableOrganizations = useSelector(
    state => state.avaliableOrganizations
  )
  const dispatch = useDispatch()
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  useEffect(() => {
    setActiveStep(0)
  }, [open])

  const returnInitialTouched = formQuizId => {
    let quizForm
    const toRet = {}
    forms.some(form => {
      if (form.id === formQuizId) {
        quizForm = form
      }
      return form.id === formQuizId
    })
    if (!quizForm) {
      return toRet
    }
    quizForm.config?.sections?.forEach(section => {
      section.elements.forEach(element => {
        toRet[element.id] = true
      })
    })
    return toRet
  }

  const returnInitialValues = (formQuizId, connectedMap) => {
    let quizForm
    forms.some(form => {
      if (form.id === formQuizId) {
        quizForm = form
      }
      return form.id === formQuizId
    })
    if (!quizForm) {
      return {}
    }
    return getInitialValues({
      data: quizForm.config,
      connectedMap
    })
  }

  const returnValidationSchema = formQuizId => {
    let quizForm
    forms.some(form => {
      if (form.id === formQuizId) {
        quizForm = form
      }
      return form.id === formQuizId
    })
    if (!quizForm) {
      return null
    }
    return constructValidationSchema({ data: quizForm.config })
  }

  const submitQuiz = (values, formikHelpers) => {
    const { setSubmitting, resetForm } = formikHelpers
    const toSubmit = { ...values }
    const { configUsed } = values
    let quizForm, connectedObjects, goToForm, goTo, fundingStream
    const recordTypeId = values.recordTypeId
    configurations.some(config => {
      if (config.id === configUsed) {
        goTo = config.form
        fundingStream = config.fundingStream
        return true
      }
      return false
    })
    forms.forEach(form => {
      if (form.id === values.formQuizId) {
        quizForm = form
        connectedObjects = form.config.objectsConnected
      }
    })
    const isPrequalification = toSubmit.isPrequalification

    setSubmitting(true)
    const creatingSnackbar = enqueueSnackbar(null, {
      persist: true,
      content: key =>
        ProgressSnackbar(<Trans>Creating new application...</Trans>)
    })

    const connectedMap = Object.fromEntries(
      connectedObjects.map(obj => [
        obj.identId,
        {
          sfObject: {
            attributes: {
              type: obj.type
            }
          }
        }
      ])
    )

    const baseValues = {
      Opportunity: {
        AccountId: currentOrganization.id,
        RecordTypeId: recordTypeId,
        Grant_Form__c: goTo,
        Funding_Stream__c: fundingStream,
        Eligibility_Quiz_Form__c: values.formQuizId
      },
      Pre_Qualification__c: {
        Funding_Stream__c: fundingStream,
        Organization_Legal_Name__c: currentOrganization.id,
        RecordTypeId: toSubmit.prequalificationRecordType,
        Eligibility_Quiz_Form__c: values.formQuizId
      },
      Account: {
        Id: currentOrganization.id
      }
    }

    const baseToSave = Object.fromEntries(
      connectedObjects.map(obj => [obj.identId, baseValues[obj.type] || {}])
    )

    delete toSubmit.prequalificationRecordType
    delete toSubmit.isPrequalification
    delete toSubmit.applicationType
    delete toSubmit.formQuizId
    delete toSubmit.recordTypeId
    delete toSubmit.configUsed

    return handleFormSave({
      values: toSubmit,
      elementsMap: mapFormElements(quizForm.config, langVersion),
      extraInfo: {
        typeFormElements: goToForm && Object.values(mapFormElements(goToForm))
      },
      reduxBag: {
        dispatch,
        user,
        organization: currentOrganization,
        avaliableOrganizations
      },
      connectedMap,
      baseToSave
    }).then(
      result => {
        if (isPrequalification) {
          closeSnackbar(creatingSnackbar)
          enqueueSnackbar(<Trans>PREQUALIFICATION_CREATED</Trans>, {
            variant: 'success'
          })
          history.push('/grants/prequalification/' + result[0].id)
        } else {
          if (goTo) {
            closeSnackbar(creatingSnackbar)
            enqueueSnackbar(<Trans>Application created!</Trans>, {
              variant: 'success'
            })
            history.push('/grants/grant-application/' + result[0].results[0].id)
          } else {
            return fetchData().then(() => {
              closeSnackbar(creatingSnackbar)
              setSubmitting(false)
              handleClose()
              resetForm()
              enqueueSnackbar(<Trans>Application created!</Trans>, {
                variant: 'success'
              })

              const id = result[0].results[0].id
              history.push(`/grants/Application/${id}`)
            })
          }
        }
      },
      reject => {
        closeSnackbar(creatingSnackbar)
        setSubmitting(false)
        enqueueSnackbar(
          <Trans>Error ocurred while creating application!</Trans>,
          {
            variant: 'error'
          }
        )
        console.log(reject)
      }
    )
  }

  const returnFormData = id => {
    let quizForm
    forms.some(form => {
      if (form.id === id) {
        quizForm = form
      }
      return form.id === id
    })
    if (!quizForm) {
      return null
    }
    return quizForm
  }

  useEffect(() => {
    formRef.current.validateForm()
  }, [validationSchema])

  const isDisabled = item => {
    return (
      Array.isArray(item.disableInProvinces) &&
      item.disableInProvinces.includes(currentOrganization.province) &&
      !item.inTesting
    )
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={{}}
      initialTouched={{}}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={submitQuiz}
    >
      {({
        values,
        setValues,
        resetForm,
        errors,
        handleSubmit,
        isSubmitting,
        setTouched,
        validateForm
      }) => {
        const { formQuizId } = values
        let quizForm, connectedObjects
        if (formQuizId) {
          forms.some(form => {
            if (form.id === formQuizId) {
              quizForm = form
            }
            return form.id === formQuizId
          })
        }
        const steps = [{ label: <Trans>Select application type</Trans> }]
        let quizElements
        if (quizForm) {
          connectedObjects = quizForm.config.objectsConnected
          steps.push(
            ...quizForm.config.sections.map(section => ({
              label: section?.title?.[langVersion]
            }))
          )
          if (activeStep > 0) {
            quizElements = quizForm.config.sections[activeStep - 1].elements
          }
        }

        const activeConfigs = configurations.filter(config => {
          if (config.startTime) {
            if (!moment.utc().isAfter(moment(config.startTime).utc())) {
              return false
            }
          }
          if (config.endTime) {
            if (moment.utc().isAfter(moment(config.endTime).utc())) {
              return false
            }
          }
          if (!config.quiz) {
            return false
          }
          if (config.inTesting) {
            return user.role.includes('TESTER')
          }
          return true
        })

        return (
          <Dialog
            open={open}
            maxWidth='md'
            fullWidth
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle id='alert-dialog-slide-title'>
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='space-between'
              >
                <Trans>Create new application</Trans>
                <IconButton
                  onClick={() => {
                    handleClose()
                    resetForm({})
                  }}
                  color='default'
                  disabled={isSubmitting}
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ minHeight: 500 }}>
              {activeConfigs.length > 1 ? (
                <Stepper alternativeLabel activeStep={activeStep}>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <Alert severity='info'>
                  <AlertTitle>
                    <Trans>
                      Currently there are no avaliable grants to apply to
                    </Trans>
                  </AlertTitle>
                </Alert>
              )}
              {activeStep === 0 && (
                <Grid
                  container
                  direction='row'
                  alignItems='stretch'
                  justify='center'
                >
                  {activeConfigs
                    .map((item, index) => {
                      let order = item.order
                      if (!order && order !== 0) {
                        order = index
                      }
                      return { ...item, order }
                    })
                    .sort((a, b) => {
                      if (isDisabled(a) && !isDisabled(b)) {
                        return 1
                      } else if (isDisabled(b) && !isDisabled(a)) {
                        return -1
                      } else {
                        return a.order - b.order
                      }
                    })
                    .map((item, index) => {
                      const fundingStream = fundingStreams.find(
                        fs => fs.id === item.fundingStream
                      )

                      const isPrequalification =
                        fundingStream?.prequalificationForm &&
                        fundingStream?.prequalificationEligibilityQuiz

                      const prequalificationStage =
                        isPrequalification &&
                        activePrequalificationFundingStreams[item.fundingStream]
                          ?.stage

                      const approvedPrequalification =
                        prequalificationStage &&
                        prequalificationApprovedStages.includes(
                          prequalificationStage
                        )

                      const prequalificationId =
                        isPrequalification &&
                        activePrequalificationFundingStreams[item.fundingStream]
                          ?.id

                      return (
                        <Grid item xs={6} key={index}>
                          <EligibilityCard
                            isPrequalification={isPrequalification}
                            currentStage={prequalificationStage}
                            prequalificationId={prequalificationId}
                            disabled={isDisabled(item)}
                            disabledMessage={
                              isDisabled(item) && (
                                <Trans>
                                  Your organization home province is invalid for
                                  this application type
                                </Trans>
                              )
                            }
                            selectApplicationType={({
                              formId,
                              quizId,
                              recordTypeId
                            }) => {
                              const data = returnFormData(quizId)
                              setLoadingForm(true)
                              connectedObjectQuery(data.config, {
                                langVersion,
                                id: constructFormAddressString({
                                  objectsConnected:
                                    data.config.objectsConnected,
                                  ids: {
                                    Account: currentOrganization.id
                                  }
                                }),
                                enqueueSnackbar
                              }).then(({ connectedMap }) => {
                                setConnectedMap(connectedMap)
                                setLoadingForm(false)
                                const toSet = { ...values }
                                toSet.configUsed = item.id
                                toSet.applicationType = formId
                                toSet.formQuizId = quizId
                                toSet.recordTypeId = recordTypeId

                                toSet.prequalificationRecordType =
                                  fundingStream.prequalificationRecordType
                                toSet.isPrequalification =
                                  isPrequalification &&
                                  !approvedPrequalification
                                const initValue = returnInitialValues(
                                  quizId,
                                  connectedMap
                                )
                                const touched = returnInitialTouched(quizId)
                                const newValidationSchema =
                                  returnValidationSchema(quizId)
                                setValidationSchema(newValidationSchema)
                                setTouched(touched)
                                setValues({ ...toSet, ...initValue })
                                validateForm()
                              })
                            }}
                            handleNext={quizId => {
                              if (quizId) {
                                handleNext()
                              }
                            }}
                            {...item}
                            quiz={
                              !approvedPrequalification && isPrequalification
                                ? fundingStream?.prequalificationEligibilityQuiz
                                : item.quiz
                            }
                          />
                        </Grid>
                      )
                    })}
                </Grid>
              )}
              {loadingForm && <Loading />}
              {quizElements && !loadingForm && (
                <FormPage
                  sections={quizForm.config.sections}
                  elements={quizElements}
                  values={values}
                  errors={errors}
                  langVersion={langVersion}
                  connectedMap={connectedMap}
                  disabled={isSubmitting}
                />
              )}
            </DialogContent>
            <DialogActions>
              {activeStep > 0 && (
                <Button
                  onClick={() => {
                    handleBack()
                  }}
                  color='primary'
                  variant='contained'
                  disabled={isSubmitting}
                >
                  <Trans>Back</Trans>
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  onClick={() => {
                    handleNext()
                  }}
                  color='primary'
                  variant='contained'
                  disabled={isSubmitting}
                >
                  <Trans>Next</Trans>
                </Button>
              )}
              {activeStep === steps.length - 1 && connectedObjects && (
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit}
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    connectedObjects.length === 0
                  }
                >
                  <Trans>Create new application</Trans>
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )
      }}
    </Formik>
  )
}
