import { Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { FormLabel } from 'app/views/common/labels/FormLabel'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { languages } from 'translation/I18nConnectedProvider'
import { getMainConnected } from '../../Form'
import { NumberFormatDefault } from '../../common/Common'
import { ConfigureSelectActions } from '../../common/ConfigureSelectActions'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import {
  validFieldTypesForPicklist,
  validFieldTypesForTextField
} from '../../editor/FormWizard'
import { FormPicklist } from './FormPicklist'
import DebouncedTextField from '../../common/DebouncedTextField'

export const FormEditorPicklist = ({
  typeProps = {},
  showEditableProps,
  elementType,
  id,
  langVersion,
  editMode,
  depth,
  showPrintProps,
  showPdfProps,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const user = useSelector(state => state.user)

  const {
    options = [],
    printIcon,
    required,
    requiredAll,
    forceRequired,
    picklistType,
    isConnected,
    dynamic,
    componentWidth,
    maxOptions
  } = typeProps

  if (!editMode) {
    return (
      <FormPicklist
        preview
        typeProps={typeProps}
        id={id}
        {...props}
        langVersion={langVersion}
      />
    )
  }
  const { connectedField, connectedObject } = getMainConnected({
    typeProps,
    ...props
  })
  const { objects, objectsConnected } = values
  let fieldsToConnect = []
  const avaliableObjectsMap = {}
  objectsConnected.forEach(obj => {
    avaliableObjectsMap[obj.identId] = obj
  })
  connectedObject &&
    objects.some(obj => {
      if (!avaliableObjectsMap[connectedObject]) {
        return false
      }
      const bool = obj.name === avaliableObjectsMap[connectedObject].type
      if (bool) {
        fieldsToConnect = obj.fields
      }
      return bool
    })

  const fieldsMap = {}
  fieldsToConnect.forEach(field => {
    fieldsMap[field.name] = field
  })
  fieldsToConnect = fieldsToConnect.filter(item =>
    validFieldTypesForTextField.includes(item.type)
  )
  const forceReadOnly = connectedField && connectedField.readOnly
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container style={{ padding: 10 }} direction='column'>
      {showEditableProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField) || forceReadOnly}
              disabled={!connectedField || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.requiredAll
                delete toSet.componentWidth
                delete toSet.maxOptions
                // delete toSet.picklistType
                toSet.options = []
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {readOnly || showPrintProps ? (
        <>
          <TextField
            select
            variant='outlined'
            label={<Trans>Print list icon</Trans>}
            fullWidth
            value={printIcon || 'none'}
            style={{ marginTop: 10, marginBottom: 10 }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.printIcon = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            <MenuItem value='none' key='none'>
              <Trans>None</Trans>
            </MenuItem>
            {[
              'supervised_user_circle',
              'accessibility_new',
              'public',
              'fiber_manual_record',
              'payments',
              'location_city',
              'paid',
              'work'
            ].map(item => (
              <MenuItem value={item} key={item}>
                <Icon>{item}</Icon>
              </MenuItem>
            ))}
          </TextField>
          <Alert severity='info' variant='outlined'>
            <Trans>
              You can't change this element to an input. Either connected field
              or whole form is marked as read only
            </Trans>
          </Alert>
        </>
      ) : (
        showEditableProps && (
          <>
            <FormControlLabel
              disabled={readOnly || forceRequired}
              style={{ marginBottom: 5 }}
              control={
                <Checkbox
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    if (e.target.checked) {
                      delete toSet.requiredAll
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />

            {picklistType === 'multiselect' && (
              <FormControlLabel
                disabled={readOnly || forceRequired}
                style={{ marginBottom: 5 }}
                control={
                  <Checkbox
                    checked={Boolean(requiredAll)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.requiredAll = e.target.checked
                      if (e.target.checked) {
                        delete toSet.required
                      }
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Required (All)</Trans>}
              />
            )}

            {picklistType === 'expandable' && (
              <DebouncedTextField
                variant='outlined'
                label={<Trans>Component width</Trans>}
                fullWidth
                value={componentWidth}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.componentWidth = e.currentTarget.value
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            )}
          </>
        )
      )}

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForPicklist}
        allowReadOnlyFields
        clearOnReadOnlyField={[
          'required',
          'requiredAll',
          'componentWidth',
          'maxOptions'
        ]}
        handleDisconnect={toSet => {
          delete toSet.dynamic
          if (toSet.options) {
            toSet.options.forEach(option => {
              delete option.apiValue
            })
          }
        }}
      />

      {showEditableProps && (
        <FormControl>
          <FormLabel component='legend'>
            <Trans>List type</Trans>
          </FormLabel>
          <RadioGroup
            defaultValue='singleselect'
            value={picklistType || 'singleselect'}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.picklistType = e.currentTarget.value
              // delete toSet.connectedField
              delete toSet.requiredAll
              delete toSet.componentWidth
              if (e.currentTarget.value !== 'multiselect') {
                if (toSet.options) {
                  toSet.options.forEach(option => {
                    delete option.clearOnSelect
                  })
                }
                delete toSet.maxOptions
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            <FormControlLabel
              disabled={
                Boolean(
                  connectedField && connectedField.type !== 'multipicklist'
                ) || forceReadOnly
              }
              value='multiselect'
              control={<Radio />}
              label={<Trans>Multiselect</Trans>}
            />
            {
              <FormControlLabel
                disabled={
                  Boolean(
                    connectedField && connectedField.type === 'multipicklist'
                  ) || forceReadOnly
                }
                value='singleselect'
                control={<Radio />}
                label={<Trans>Single select (Radio group)</Trans>}
              />
            }
            {
              <FormControlLabel
                disabled={
                  Boolean(
                    connectedField && connectedField.type === 'multipicklist'
                  ) || forceReadOnly
                }
                value='expandable'
                control={<Radio />}
                label={<Trans>Single select (Dropdown)</Trans>}
              />
            }
          </RadioGroup>
        </FormControl>
      )}

      {typeProps.picklistType !== 'expandable' && (
        <FormControlLabel
          style={{ marginBottom: 5 }}
          control={
            <Checkbox
              checked={typeProps.isRow}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.isRow = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>FORM_EDITOR_PICKLIST_IS_ROW_LABEL</Trans>}
        />
      )}

      <FormControlLabel
        disabled={!connectedField}
        style={{ marginBottom: 5 }}
        control={
          <Checkbox
            checked={Boolean(dynamic)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.dynamic = e.target.checked
              if (e.target.checked) {
                toSet.options = []
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Dynamic picklist</Trans>}
      />
      {picklistType === 'multiselect' && !readOnly && showEditableProps && (
        <DebouncedTextField
          variant='outlined'
          label={<Trans>Max options to select</Trans>}
          fullWidth
          value={maxOptions || ''}
          InputProps={{ inputComponent: NumberFormatDefault }}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.maxOptions = e.target.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        />
      )}

      {(readOnly || !showEditableProps) && (
        <Alert severity='info' variant='outlined'>
          <Trans>
            For read only view all options selected by user will be displayed
            regardless of configuration. If element is marked as Dynamic
            Picklist it will use labels from Selesforce. If it is not it will
            use labels configured below
          </Trans>
        </Alert>
      )}

      {!dynamic && (
        <div style={{ marginTop: 5 }}>
          <Grid container direction='row' alignItems='center'>
            <Typography>
              <Trans>Picklist options</Trans>
            </Typography>
            <IconButton
              onClick={() => {
                const title = languages.reduce((acc, lang) => {
                  acc[lang] = ''
                  return acc
                }, {})
                options.push({ title, apiValue: '' })
                typeProps.options = options
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
            <Button
              disabled={!connectedField}
              style={{ marginLeft: 10 }}
              color='primary'
              variant='contained'
              onClick={() => {
                const fieldInfo = fieldsMap[connectedField.name]
                const toSet = fieldInfo?.picklistValues
                  .map(sfObj => {
                    if (sfObj.active) {
                      return {
                        title: {
                          [sfObj.labelLang]: sfObj.label
                        },
                        apiValue: sfObj.value
                      }
                    }
                    return null
                  })
                  .filter(item => item)
                typeProps.options = toSet || []
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Trans>Use values from object definition</Trans>
            </Button>
          </Grid>
          <Grid container direction='column'>
            {options.map((item, index) => {
              const selectActions = item.selectActions || []
              return (
                <div key={index}>
                  <Grid
                    item
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    wrap='nowrap'
                    style={{ paddingTop: 10 }}
                  >
                    {index + 1 + '. '}

                    <Grid
                      item
                      xs={8}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <ConfigureMultilanguageTextField
                        value={item.title}
                        label={<Trans>Option label</Trans>}
                        handleChange={value => {
                          const toSet = { ...typeProps }
                          toSet.options[index].title = value
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        useDebounce
                      />
                    </Grid>

                    {isConnected && (
                      <Grid item xs={4}>
                        <DebouncedTextField
                          variant='outlined'
                          label={<Trans>API value</Trans>}
                          fullWidth
                          value={item.apiValue}
                          onChange={e => {
                            const toSet = { ...typeProps }
                            toSet.options[index].apiValue =
                              e.currentTarget.value
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                            // setOptionFR(e.currentTarget.value)
                          }}
                        />
                      </Grid>
                    )}

                    <div style={{ width: 50 }}>
                      <IconButton
                        disabled={index === 0}
                        onClick={() => {
                          const toSet = { ...typeProps }
                          const toMove = toSet.options[index]
                          const toReplace = toSet.options[index - 1]
                          toSet.options[index - 1] = toMove
                          toSet.options[index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>arrow_upward</Icon>
                      </IconButton>
                    </div>

                    <div style={{ width: 50 }}>
                      <IconButton
                        disabled={index === typeProps.options.length - 1}
                        onClick={() => {
                          const toSet = { ...typeProps }
                          const toMove = toSet.options[index]
                          const toReplace = toSet.options[index + 1]
                          toSet.options[index + 1] = toMove
                          toSet.options[index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>arrow_downward</Icon>
                      </IconButton>
                    </div>

                    <div style={{ width: 50 }}>
                      <IconButton
                        onClick={() => {
                          const toSet = { ...typeProps }
                          toSet.options.splice(index, 1)
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  </Grid>
                  {!(forceReadOnly || readOnly) && showEditableProps && (
                    <>
                      <Grid container direction='row'>
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={Boolean(item.requireDetails)}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].requireDetails =
                                  e.target.checked
                                dispatch({
                                  type: 'FIELD',
                                  injectable,
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: { ...toSet }
                                })
                              }}
                            />
                          }
                          label={<Trans>Require details</Trans>}
                        />
                        {picklistType === 'multiselect' && (
                          <FormControlLabel
                            style={{ marginLeft: 15 }}
                            control={
                              <Checkbox
                                checked={Boolean(item.clearOnSelect)}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].clearOnSelect =
                                    e.target.checked
                                  dispatch({
                                    type: 'FIELD',
                                    injectable,
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: { ...toSet }
                                  })
                                }}
                              />
                            }
                            label={
                              <Trans>
                                Clear and disable other options on select
                              </Trans>
                            }
                          />
                        )}
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={Boolean(item.hasTooltip)}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].hasTooltip =
                                  e.target.checked
                                delete toSet.options[index].tooltip
                                dispatch({
                                  type: 'FIELD',
                                  injectable,
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: { ...toSet }
                                })
                              }}
                            />
                          }
                          label={<Trans>Has tooltip?</Trans>}
                        />
                        {Boolean(item.hasTooltip) && (
                          <Grid
                            container
                            direction='row'
                            style={{ padding: 20, marginRight: 35 }}
                          >
                            <ConfigureMultilanguageTextField
                              value={item.tooltip}
                              label={<Trans>Option tooltip</Trans>}
                              handleChange={value => {
                                const toSet = { ...typeProps }
                                toSet.options[index].tooltip = value
                                dispatch({
                                  type: 'FIELD',
                                  injectable,
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: toSet
                                })
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {showEditableProps && (
                        <ConfigureSelectActions
                          depth={depth}
                          typeProps={typeProps}
                          id={id}
                          index={index}
                          langVersion={langVersion}
                          selectActions={selectActions}
                        />
                      )}
                    </>
                  )}
                  {connectedObject && (
                    <div
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingBottom: 10
                      }}
                    >
                      <Autocomplete
                        freeSolo={false}
                        value={
                          options[index].connectedField
                            ? options[index].connectedField.label
                            : null
                        }
                        onChange={(e, value) => {
                          const toSet = { ...typeProps }
                          let obj
                          fieldsToConnect.some(item => {
                            const bool = item.label === value
                            if (bool) {
                              obj = item
                            }
                            return bool
                          })
                          toSet.options[index].connectedField = obj
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        style={{ marginTop: 15 }}
                        fullWidth
                        options={fieldsToConnect.map(item => item.label)}
                        renderInput={params => (
                          <TextField
                            variant='outlined'
                            {...params}
                            label={<Trans>Connected field</Trans>}
                          />
                        )}
                      />
                    </div>
                  )}
                  <Divider style={{ marginBottom: 5 }} />
                </div>
              )
            })}
          </Grid>
        </div>
      )}

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
    </Grid>
  )
}
