import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfDefaultFontSize } from '../../common/Common'

export function FormNumericSliderPDF ({ value, title, typeProps, ...props }) {
  const { max = 100, min = 0 } = typeProps
  let valueString =
    value +
    ' (' +
    myI18n?._(t`min`) +
    '. ' +
    String(min) +
    ', ' +
    myI18n?._(t`max`) +
    '. ' +
    String(max) +
    ')'

  return (
    <View>
      <Text style={{ fontWeight: 'bold', fontSize: pdfDefaultFontSize }}>
        {title}
      </Text>
      <Text style={{ fontSize: pdfDefaultFontSize }}>
        {value && valueString}
      </Text>
    </View>
  )
}
