import { Typography } from '@material-ui/core'
import { setFundingStreams } from 'app/redux/actions/FundingStreamsActions'
import { setTutorialsFromSteps } from 'app/redux/actions/TutorialsActions'
import {
  grpcGlobalLogOut,
  grpcGlobalLogin
} from 'app/services/grpcMultiuserGlobal'
import {
  checkIfOrganizationsAreStale,
  getUsersOrganizationsJoinRequests
} from 'app/services/sfAuth/sfData/sfAccount'
import { getCaseTypes } from 'app/services/sfAuth/sfData/sfCase'
import { enforceQuerySuccess } from 'app/services/sfAuth/sfData/sfCommon'
import { getExternalReviewer } from 'app/services/sfAuth/sfData/sfReviews'
import { getTutorialStepsParsed } from 'app/services/sfAuth/sfData/sfTutorial'
import { checkUserProfileValidity } from 'app/services/sfAuth/sfData/sfUser'
import { pingServer } from 'app/views/forms/multiuser/grpcMultiuserEdit'
import React, { Fragment, useEffect } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { setCaseTypes } from '../redux/actions/CaseDataActions'
import { setConfigurationData } from '../redux/actions/ConfigurationActions'
import { userLoggedIn, userLoggedOut } from '../redux/actions/LoginActions'
import {
  setAvaliableOrganizations,
  setOrganization
} from '../redux/actions/OrganizationActions'
import { setUserData } from '../redux/actions/UserActions'
import localStorageService from '../services/localStorageService'
import sfAuthService from '../services/sfAuth/SFAuthService'
import sfOauthConfig from '../services/sfAuth/sfAuthConfig'
import { authRoles, hasRole } from './authRoles'
const crypto = require('crypto')

/**
 * handle login and authorization for SF
 * @component
 * @param children
 * @param maintenance disable the site completely and show a maintenance message
 * @returns {JSX.Element}
 * @constructor
 */
const Auth = ({ children, maintenance }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { login, user } = useSelector(state => state)
  const [networkError, setNetworkError] = React.useState()

  useBeforeunload(event => {
    if (user.multiuserLoginToken) {
      grpcGlobalLogOut({
        token: user.multiuserLoginToken
      })
    }
  })

  useEffect(() => {
    let user = localStorageService.getItem('auth_user')
    dispatch(setUserData(user))
    if (!maintenance) {
      checkSfAuth(user)
    }
  }, [])

  useEffect(() => {
    let handle
    if (user.multiuserLoginToken) {
      handle = setInterval(() => {
        pingServer({
          userId: user.userId,
          token: user.multiuserLoginToken,
          onReject: () => {
            console.log('global ping fail')
            dispatch(
              setUserData({
                ...user,
                multiuserAuthenticated: false
              })
            )
          },
          onSuccess: () => {
            console.log('global ping success')
          }
        })
      }, 4000)
    }

    return () => {
      clearInterval(handle)
      if (user.multiuserLoginToken) {
        grpcGlobalLogOut({
          token: user.multiuserLoginToken
        })
      }
    }
  }, [user.multiuserLoginToken])

  useEffect(() => {
    if (
      !user.multiuserAuthenticated &&
      user.access_token &&
      user.tabId &&
      hasRole(user.role, authRoles.tester)
    ) {
      console.log('try login', user.access_token, user.tabId)
      grpcGlobalLogin({
        token: user.access_token,
        userId: user.userId,
        username: user.displayName,
        userInfo: JSON.stringify({}),
        browserTabId: user.tabId,
        onSuccess: ({ sessionToken }) => {
          console.log('success global login', sessionToken)
          dispatch(
            setUserData({
              ...user,
              multiuserAuthenticated: true,
              multiuserLoginToken: sessionToken
            })
          )
        },
        onFail: result => {
          console.log('failed global login', result)
          //dispatch(setUserData(user))
        }
      })
    }
  }, [user])

  const checkSfAuth = user => {
    let sentState = {
      redirect_uri: location.pathname
    }
    const tabId = crypto.randomBytes(8).toString('hex')
    window.localStorage.setItem('tabId', tabId)
    console.log('match', location, login, user, user && user.access_token)
    if (login.success) {
      console.log('user logged in')
    } else if (user && user.access_token && user.userId) {
      console.log('read user saved', user)
      sfAuthService
        .checkLogin(user)
        .then(
          ({ user, state, configuration, tutorialSteps }) => {
            dispatch(setUserData(user))
            dispatch(setConfigurationData(configuration))
            sfAuthService.setUser(user)
            dispatch(userLoggedIn())
          },
          e => {
            console.error('user check failed', e, user)
            sfAuthService.setUser(null)
            dispatch(userLoggedOut())
            sfAuthService.doLogin(sentState)
          }
        )
        .catch(e => {
          console.error('cant login from stored user', e, login)
          sfAuthService.setUser(null)
          dispatch(userLoggedOut())
          sfAuthService.doLogin(sentState)
        })
    } else {
      sfAuthService
        .parseToken()
        .then(
          ({
            user,
            state,
            organization,
            configuration,
            avaliableOrganizations,
            fundingStreams
          }) => {
            dispatch(setFundingStreams({ streams: fundingStreams }))

            if (!sfOauthConfig.isInternal) {
              enforceQuerySuccess(() =>
                getUsersOrganizationsJoinRequests(user.userId)
              ).then(requests => {
                dispatch(
                  setUserData({ organizationsPendingRequests: requests.length })
                )
              })
              enforceQuerySuccess(() =>
                checkUserProfileValidity(user.userId).then(isValid => {
                  setUserData({ isUserProfileValid: Boolean(isValid) })
                })
              )
            }
            enforceQuerySuccess(() =>
              checkIfOrganizationsAreStale(user.userId)
            ).then(staleOrganizations => {
              dispatch(setUserData({ staleOrganizations }))
            })
            enforceQuerySuccess(() => getCaseTypes()).then(caseTypes => {
              dispatch(setCaseTypes(caseTypes))
            })
            enforceQuerySuccess(() => getTutorialStepsParsed()).then(
              tutorialSteps => {
                dispatch(setTutorialsFromSteps(tutorialSteps))
              }
            )
            dispatch(setAvaliableOrganizations(avaliableOrganizations))
            dispatch(setOrganization(organization))
            dispatch(setConfigurationData(configuration))
            dispatch(userLoggedIn())
            dispatch(setUserData({ ...user, tabId }))

            getExternalReviewer(user.userObject.contactId).then(result => {
              if (result[0]) {
                dispatch(
                  setUserData({ ...user, hasExternalReviewProfile: true })
                )
              }
            })

            try {
              console.log('redirect', state, history.location.pathname)
              if (
                !String(history.location.pathname).includes(
                  'ApplicantInformation'
                ) ||
                // always redirect on internal, skip validity check
                sfOauthConfig.isInternal
              ) {
                history.push({
                  pathname: state.redirect_uri
                })
              }
            } catch (e) {
              console.error("can't redirect going to root", e, user, state)
              // window.location = '/'
            }
          }
        )
        .catch(e => {
          console.error('cant login', e, login)
          if (e.message === 'Network Error') {
            return setNetworkError(e.message)
          }
          sfAuthService.doLogin(sentState)
        })
    }
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (maintenance) {
    return (
      <>
        <div
          style={{
            position: 'fixed',
            left: 0,
            textAlign: 'center',
            right: 0,
            top: 'calc(50% - 100px)',
            margin: 'auto',
            height: '200px',
            width: '400px'
          }}
        >
          <Typography variant='h6'>
            Temporarily Under Maintenance
            <br />
            We'll be back shortly
          </Typography>
          <br />
          <br />
          <Typography variant='h6'>
            Temporairement en maintenance
            <br />
            Nous serons bientôt de retour
          </Typography>
        </div>
      </>
    )
  }
  return (
    <Fragment>
      {/* {networkError && (
        <Dialog open={true}>
          <DialogTitle>{networkError}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans>CONNECTION_ERROR_POP_UP_ERROR_OCURRED</Trans>
            </DialogContentText>
            <DialogContentText>
              <Trans>CONNECTION_ERROR_POP_UP_INSTRUCTIONS</Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={handleRefresh}>
              <Trans>REFRESH</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
      {children}
    </Fragment>
  )
}

export default Auth
