import React from "react";

type FormContextType = {
  network: any;
};

const FormContext = React.createContext<FormContextType>({
  network: null,
});

export function useFormContext() {
  const value = React.useContext(FormContext);
  if (!value) {
    throw new Error("useSession must be wrapped in a <SessionProvider />");
  }
  return value;
}

export function FormContextProvider(
  props: React.PropsWithChildren<FormContextType>
) {
  return (
    <FormContext.Provider
      value={{
        ...props,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
}
