import SFAuthService, { NO_ID_PROVIDED, NO_USER } from "../SFAuthService";
import { mapSFToForm } from "../sfDataService";

export const PREQUALIFICATION_STAGE_APPROVED = "Approved";

export const prequalificationApprovedStages = [PREQUALIFICATION_STAGE_APPROVED];

export const mapFields = {
  direction: "in",
  Stage__c: "stage",
  Application_Form__c: "applicationForm",
  Name: "name",
  Id: "id",
  Funding_Stream__c: "fundingStream",
  LastModifiedDate: "lastModifiedDate",
  CreatedDate: "createdDate",
  Community_Delete__c: "deleted",
  Organization_Legal_Name__c: "organizationId",
  Assigned_Program_Manager__c: "assignedProgramManager",
  Assigned_Program_Manager__r: {
    key: "assignedProgramManagerDetails",
    in: (obj, toRet) => {
      return {
        name: obj.Assigned_Program_Manager__r?.Name,
        mail: obj.Assigned_Program_Manager__r?.Email,
        phone: obj.Assigned_Program_Manager__r?.Phone,
      };
    },
    out: (res) => res,
  },
};

interface SFPrequalification {
  id: string;
  name: string;
}

export const deletePrequalification = (id: string): Promise<unknown> => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (!id) {
    return Promise.reject(NO_ID_PROVIDED);
  }
  return conn
    .sobject("Pre_Qualification__c")
    .update({ Id: id, Community_Delete__c: true });
};

export const updatePrequalification = (
  toUpdate: Record<string, any>
): Promise<unknown> => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (!toUpdate || !toUpdate.Id) {
    return Promise.reject(NO_ID_PROVIDED);
  }
  return conn.sobject("Pre_Qualification__c").update(toUpdate);
};

export const getPrequalifications = ({
  parsed = true,
  searchParams = {},
}: {
  parsed: boolean;
  searchParams: Record<string, unknown>;
}): Promise<unknown> => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Pre_Qualification__c")
    .find({ ...searchParams, Community_Delete__c: false })
    .select("*, Assigned_Program_Manager__r.*")
    .then((results) => {
      if (parsed) {
        return results.map((obj) => mapSFToForm(mapFields, obj));
      } else {
        return results;
      }
    });
};
