import { Trans } from '@lingui/macro'
import { defaultFormPdfFontFamily } from '../../pdf-components/FormPdf'

const styles = {
  icon: {
    width: 25,
    height: 25
  },
  link: {
    margin: 0,
    marginRight: 5,
    fontSize: 12,
    color: 'black'
  },
  imgContainer: {
    width: 30,
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 16,
    fontFamily: defaultFormPdfFontFamily,
    marginBottom: 8,
    marginLeft: 8
  },
  helpText: {
    fontSize: 12,
    margin: 0
  }
}

export const FormUploadFilesPdfEditor = ({ value = [], title, ...props }) => {
  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div style={styles.imgContainer}>
          <img src='/assets/images/pdf/download.jpg' style={styles.icon} />
        </div>
        <p style={styles.link}>
          <Trans>FORM_UPLOAD_FILES_PDF_EDITOR_PLACEHOLDER</Trans>
        </p>
      </div>
      <p style={styles.helpText}>
        <Trans>FORM_UPLOAD_FILES_PDF_EDITOR_HELPTEXT_PLACEHOLDER</Trans>
      </p>
    </div>
  )
}
