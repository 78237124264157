import { Trans } from '@lingui/macro'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { useDispatch } from 'react-redux'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { FormHtml } from './FormHtmlInjector'

export const FormEditorHtml = ({
  editMode,
  depth,
  typeProps,
  showPdfProps,
  showPdfComponent,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const { html, pdfFontSize } = typeProps
  if (!editMode) {
    return <FormHtml editor {...props} typeProps={typeProps} />
  }
  return (
    <div>
      <ConfigureMultilanguageTextField
        value={html}
        label={<Trans>Html</Trans>}
        handleChange={value => {
          const toSet = { ...typeProps }
          toSet.html = value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
          showFontSizeOption={true}
        />
      )}
    </div>
  )
}
