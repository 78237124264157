import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  createTheme,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  MuiThemeProvider,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TableCell,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { EgretLayoutSettings } from 'app/EgretLayout/settings'
import sfAuthService, { NO_USER } from 'app/services/sfAuth/SFAuthService'
import CustomDatePicker from 'app/views/common-components/CustomDatePicker'
import { CurrencyFormated } from 'app/views/common/Formats'
import { FormLabel } from 'app/views/common/labels/FormLabel'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import * as crypto from 'crypto'
import { Field, useFormikContext } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { normalizeNumberValue } from '../../../../../utils/valueUtils'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { FormConnectToObject } from '../../../common/FormConnectToObject'
import { mapFormEditorElements } from '../../../editor/FormWizard'
import { requiredTrans } from '../../../formTranslations'

export const formICCEBudgetDefaultValue = (obj, additionalInfo, item) => {
  const { defaultYear, budgetLinesType } = item.typeProps
  const defaultYearValue = moment.utc(defaultYear).year()
  if (!obj || !obj.Budget_Lines__r) {
    return {
      startYear: defaultYearValue,
      administrativeCosts: 0,
      fiscalYears: []
    }
  } else {
    const administrativeId =
      additionalInfo.budgetLinesRecordTypes['Administrative Costs'].recordTypeId
    let adminLine
    let budgetLines = obj.Budget_Lines__r.records
    if (budgetLinesType) {
      budgetLines = budgetLines.filter(obj => obj.Type__c === budgetLinesType)
    }
    budgetLines.some(record => {
      if (record.RecordTypeId === administrativeId) {
        adminLine = record
      }
      return record.RecordTypeId === administrativeId
    })
    const fiscalYears = {}
    budgetLines.forEach(record => {
      const year = moment.utc(record.Fiscal_Year__c).year()
      if (record.RecordTypeId !== administrativeId) {
        const toRet = {
          category: record.Category__c,
          year: record.Fiscal_Year__c,
          description: record.Description__c,
          otherDonors: record.Other_sources__c,
          totalCost: record.Total_Grant_Request__c,
          // unit: record.Value__c ? String(record.Value__c) : null,
          // unitCost: record.Unit_Cost__c ? String(record.Unit_Cost__c) : null,
          id: record.Id
        }
        if (!fiscalYears[year]) {
          fiscalYears[year] = [toRet]
        } else {
          fiscalYears[year].push(toRet)
        }
      }
    })
    const fiscalYearsToSet = Object.keys(fiscalYears)
      .map(key => {
        const year = fiscalYears[key]
        return {
          budgetLines: [...year],
          year: key
        }
      })
      .sort((a, b) => a.year - b.year)

    return {
      startYear: adminLine
        ? moment.utc(adminLine.Fiscal_Year__c).year()
        : defaultYearValue,
      administrativeCosts: adminLine ? adminLine.Unit_Cost__c : 0,
      id: adminLine ? adminLine.Id : null,
      fiscalYears: fiscalYearsToSet
    }
  }
}

export const formICCEBudgetValueToText = (value, question, info) => {
  if (info.saveFailed) {
    const toRet = languages.reduce((acc, lang) => {
      acc[lang] = myI18n?._(t`Data is too complex to show differences!`)
      return acc
    }, {})
    return toRet
  }
  const toRet = languages.reduce((acc, lang) => {
    acc[lang] = (
      <div>
        <Trans>Data is too complex to show differences!</Trans>
        <div style={{ color: '#e0a912' }}>
          <Trans>
            Overriding will ony save changes to already existing budet lines. It
            will not delete any newly added lines. Manual cleanup may be
            required.
          </Trans>
        </div>
      </div>
    )
    return toRet
  })
}

export const formICCEBudgetExtractKey = ({
  value,
  additionalSFInfo,
  saveMap,
  connectedObjectId,
  item,
  ...props
}) => {
  const { budgetLinesType } = item.typeProps
  const inner = []
  if (!value.id) {
    inner.push({
      RecordTypeId:
        additionalSFInfo.budgetLinesRecordTypes?.['Administrative Costs']
          .recordTypeId,
      Is_Admin_Line__c: true,
      Type__c: budgetLinesType,
      Fiscal_Year__c: moment.utc().set('year', value.startYear),
      Unit_Cost__c: normalizeNumberValue(value.administrativeCosts)
    })
  } else {
    inner.push({
      Id: value.id,
      Is_Admin_Line__c: true,
      Type__c: budgetLinesType,
      Fiscal_Year__c: moment.utc().set('year', value.startYear),
      Unit_Cost__c: normalizeNumberValue(value.administrativeCosts)
    })
  }
  value.fiscalYears.forEach((year, yIndex) => {
    year.budgetLines.forEach(line => {
      const toRet = {
        Id: line.id,
        Category__c: line.category,
        Fiscal_Year__c: moment
          .utc()
          .set('year', Number(value.startYear + yIndex)),
        // Value__c: Number(line.unit),
        Type__c: budgetLinesType,
        Description__c: line.description,
        Other_sources__c: normalizeNumberValue(line.otherDonors),
        Total_Grant_Request__c: normalizeNumberValue(line.totalCost)
        // Unit_Cost__c: Number(line.unitCost)
      }
      // if (line.id.indexOf('fake') !== -1) {
      //   delete toRet.Id
      // }
      inner.push(toRet)
    })
  })
  if (saveMap[connectedObjectId].Budget_Lines__r) {
    saveMap[connectedObjectId].Budget_Lines__r = [
      ...saveMap[connectedObjectId].Budget_Lines__r,
      ...inner
    ]
  } else {
    saveMap[connectedObjectId].Budget_Lines__r = inner
  }
}

export const ICCETheme = createTheme({
  ...EgretLayoutSettings.themes.chtcWhite,
  palette: {
    primary: {
      main: 'rgba(150,56,33,1)'
    },
    secondary: {
      main: 'rgba(255,184,28,1)'
    }
  },
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        justifyContent: 'flex-end'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 0
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        zIndex: 0
      },
      fixedLeft: {
        zIndex: 0
      }
    }
  }
})

export const FormICCEBudgetPrint = ({ value, title, ...props }) => {
  const { fiscalYears } = value
  return (
    <div style={{ flex: 1 }}>
      <b>
        <div
          style={{
            fontSize: 21,
            width: '100%',
            color: '#757575',
            textAlign: 'center'
          }}
        >
          {title}
        </div>
      </b>
      {fiscalYears.map(obj => {
        const { year, budgetLines } = obj
        return (
          <>
            <div style={{ padding: 8, textAlign: 'center' }}>
              <Typography className='form-print-subtitle'>
                <Trans>Year</Trans>
              </Typography>
              <Typography style={{ fontSize: 21 }}>{year}</Typography>
            </div>
            <Paper elevation={6} style={{ marginTop: 10, padding: 10 }}>
              <Grid container>
                <Grid item xs style={{ padding: 4 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Category</Trans>
                  </Typography>
                </Grid>
                <Grid item xs style={{ padding: 4 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Description</Trans>
                  </Typography>
                </Grid>
                <Grid item xs style={{ padding: 4 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Total cost</Trans>
                  </Typography>
                </Grid>
                <Grid item xs style={{ padding: 4 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Other donors</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {budgetLines.map((obj, index) => {
                const { category, description, totalCost, otherDonors } = obj
                return (
                  <Grid container key={index}>
                    <Grid item xs style={{ padding: 4 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <Trans id={category} />
                      </Typography>
                    </Grid>

                    <Grid item xs style={{ padding: 4 }}>
                      <Typography style={{ minHeight: 21 }}>
                        {description}
                      </Typography>
                    </Grid>
                    <Grid item xs style={{ padding: 4 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <CurrencyFormated value={totalCost} />
                      </Typography>
                    </Grid>
                    <Grid item xs style={{ padding: 4 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <CurrencyFormated value={otherDonors} />
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Paper>
          </>
        )
      })}
    </div>
  )
}

export const currencyFormat = new Intl.NumberFormat('en-CA', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})

function NumberFormatCurrency (props) {
  const { inputRef, id, value = '', ...other } = props
  let formattedValue = ''
  if (value) {
    const num = String(value).replaceAll(',', '')
    formattedValue = parseFloat(Number(num).toFixed(2))
  }
  return (
    <NumberFormat
      {...other}
      value={formattedValue}
      getInputRef={inputRef}
      thousandSeparator=','
      allowNegative={false}
      isNumericString={false}
    />
  )
}

const deleteFiscalYear = item => {
  const toDelete = []
  item.budgetLines.forEach(line => {
    if (line.id.indexOf('fake') === -1) {
      toDelete.push(line.id)
    }
  })
  if (toDelete.length === 0) {
    return Promise.resolve()
  } else {
    const conn = sfAuthService.getConnection()
    if (!conn) {
      return Promise.reject(NO_USER)
    }
    return conn.sobject('Budget_Line__c').delete(toDelete)
  }
}

const deleteBudgetLine = item => {
  if (item.id.indexOf('fake') === -1) {
    const conn = sfAuthService.getConnection()
    if (!conn) {
      return Promise.reject(NO_USER)
    }
    return conn.sobject('Budget_Line__c').delete(item.id)
  } else {
    return Promise.resolve()
  }
}

Yup.addMethod(Yup.object, 'checkICCEBudget', function (requestedId) {
  return this.test('checkICCEBudget', null, function (object) {
    if (!requestedId) {
      return true
    }
    const { path, createError, parent } = this
    const { fiscalYears, administrativeCosts } = object
    const toCheck = parent[requestedId] ? Number(parent[requestedId]) : 0
    let sum = 0
    const costs = normalizeNumberValue(administrativeCosts) || 0
    fiscalYears.forEach(year => {
      year.budgetLines.forEach(item => {
        if (item.totalCost) {
          sum += normalizeNumberValue(item.totalCost)
        }
      })
    })
    sum += costs
    if (sum > toCheck) {
      return createError({
        path: path + '.sum',
        message: (
          <Trans>
            Amount requested from ICCE cannot exceed total amount requested!
          </Trans>
        )
      })
    }
    return true
  })
})

Yup.addMethod(Yup.object, 'checkICCEBudgetAdminCost', function (requestedId) {
  return this.test('checkICCEBudgetAdminCost', null, function (object) {
    const { path, createError, parent } = this
    const { administrativeCosts } = object
    const toCheck = parent[requestedId] ? Number(parent[requestedId]) : 0
    const costs = normalizeNumberValue(administrativeCosts) || 0
    if (toCheck > 0 && costs / toCheck > 0.1) {
      return createError({
        path: path + '.administrativeCosts',
        message: (
          <Trans>
            Administrative costs need be lower then 10% of overall budget.
          </Trans>
        )
      })
    }
    return true
  })
})

const errorFromPath = (path, error) => {
  if (!error) {
    return null
  } else {
    let a = error
    path.forEach(p => {
      if (a && a[p]) {
        a = a[p]
      }
    })
    if (a && a.props) {
      return myI18n?._(a.props.id)
    } else {
      return null
    }
  }
}

export const formICCEBudgetError = error => {
  return (
    <div>
      {errorFromPath(['sum'], error)}
      {errorFromPath(['fiscalYears'], error)}
      <ul>
        {error.fiscalYears &&
          error.fiscalYears.map &&
          error.fiscalYears.map((item, yearIndex) => {
            if (!item) {
              return null
            }
            return (
              <li key={yearIndex}>
                <u>
                  <Trans>Fiscal Year</Trans> {Number(yearIndex + 1) + '. '}
                </u>
                {errorFromPath(
                  ['fiscalYears', yearIndex, 'budgetLines'],
                  error
                )}
                <ul>
                  {item.budgetLines &&
                    item.budgetLines.map &&
                    item.budgetLines.map((budget, lineIndex) => {
                      if (!budget) {
                        return null
                      }
                      const category = errorFromPath(
                        [
                          'fiscalYears',
                          yearIndex,
                          'budgetLines',
                          lineIndex,
                          'category'
                        ],
                        error
                      )
                      const description = errorFromPath(
                        [
                          'fiscalYears',
                          yearIndex,
                          'budgetLines',
                          lineIndex,
                          'description'
                        ],
                        error
                      )
                      const totalCost = errorFromPath(
                        [
                          'fiscalYears',
                          yearIndex,
                          'budgetLines',
                          lineIndex,
                          'totalCost'
                        ],
                        error
                      )
                      return (
                        <li key={lineIndex}>
                          <Trans>Budget line</Trans>{' '}
                          {Number(lineIndex + 1) + '. '}
                          <ul>
                            {category && (
                              <li>
                                <b>
                                  <Trans>Category</Trans>:{' '}
                                </b>
                                {category}
                              </li>
                            )}
                            {description && (
                              <li>
                                <b>
                                  <Trans>Description</Trans>:{' '}
                                </b>
                                {description}
                              </li>
                            )}
                            {totalCost && (
                              <li>
                                <b>
                                  <Trans>Total cost</Trans>:{' '}
                                </b>
                                {totalCost}
                              </li>
                            )}
                          </ul>
                        </li>
                      )
                    })}
                </ul>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export const formICCEBudgetValidation = item => {
  const { requestedAmount, required } = item.typeProps
  let fiscalYearValidation = Yup.array().of(
    Yup.object({
      budgetLines: Yup.array()
        .of(
          Yup.object({
            category: Yup.string().nullable().required(requiredTrans),
            description: Yup.string().nullable().required(requiredTrans),
            totalCost: Yup.string().nullable().required(requiredTrans)
          })
        )
        .ensure()
        .min(
          1,
          <Trans>
            You have to add at least one budget line to fiscal year!
          </Trans>
        )
    })
  )

  if (required) {
    fiscalYearValidation = fiscalYearValidation
      .ensure()
      .min(
        1,
        <Trans>You have to add at least one fiscal year to budget!</Trans>
      )
  }
  return Yup.object({
    fiscalYears: fiscalYearValidation
  })
    .checkICCEBudget(requestedAmount)
    .checkICCEBudgetAdminCost(requestedAmount)
    .label(<Trans>Budget</Trans>)
}

export const FormICCEBudgetDisplay = ({ budget }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const adminLine = budget.adminLine
  const startYear = adminLine
    ? moment.utc(adminLine.year).year()
    : moment.utc().year()
  const administrativeCosts = adminLine ? adminLine.unitCost : 0
  const fiscalYears = Object.keys(budget)
    .filter(key => key !== 'adminLine')
    .map(key => {
      const lines = budget[key]
      return {
        budgetLines: [...lines],
        year: key
      }
    })

  const sumFiscalYear = index => {
    let sum = 0
    fiscalYears[index].budgetLines.forEach(item => {
      if (item.totalCost) {
        sum += normalizeNumberValue(item.totalCost)
      }
    })
    return sum
  }

  const totalSum = () => {
    let sum = 0
    fiscalYears.forEach((year, index) => {
      sum += sumFiscalYear(index)
    })
    return sum
  }

  const totalOtherDonors = () => {
    let sum = 0
    fiscalYears.forEach((year, index) => {
      year.budgetLines.forEach(line => {
        if (line.otherDonors) {
          const toAdd = String(line.otherDonors).replaceAll(',', '')
          sum += Number(toAdd)
        }
      })
    })
    return sum
  }

  const yearLabel = index => {
    return startYear + index + '/' + Number(startYear + 1 + index)
  }

  return (
    <Paper style={{ padding: 20 }}>
      <MuiThemeProvider theme={ICCETheme}>
        <Grid container direction='column' justify='center'>
          <div style={{ textAlign: 'center' }}>
            {<h3>{<Trans>Budget</Trans>}</h3>}
          </div>
        </Grid>
        <Stepper
          nonLinear
          activeStep={currentStep}
          orientation='horizontal'
          alternativeLabel
        >
          {fiscalYears.map((section, index) => {
            return (
              <Step
                alt
                itemType='step'
                id='step'
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={e => setCurrentStep(index)}
              >
                <StepLabel id='label'>{yearLabel(index)}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {fiscalYears[currentStep] && (
          <>
            <MUIDataTable
              data={fiscalYears[currentStep].budgetLines.map((item, index) => ({
                ...item,
                actions: index
              }))}
              options={{
                textLabels: muiTextLabels(myI18n),
                filter: false,
                pagination: false,
                selectableRows: 'none',
                print: false,
                download: false,
                viewColumns: false
              }}
              title={
                <Grid container direction='row'>
                  <Typography variant='h5'>
                    <b>
                      <Trans>Fiscal Year</Trans>{' '}
                      <span>{yearLabel(currentStep)}</span>
                    </b>
                  </Typography>
                </Grid>
              }
              columns={[
                {
                  name: 'category',
                  label: myI18n?._(t`Category`),
                  options: {
                    customHeadLabelRender: props => {
                      return (
                        <div style={{ marginLeft: 20 }}>
                          <Trans>Category</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      return (
                        <div
                          style={{
                            flex: 1,
                            marginLeft: 20,
                            minWidth: 300
                          }}
                        >
                          {value}
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'description',
                  label: myI18n?._(t`Description`),
                  options: {
                    customHeadLabelRender: props => {
                      return (
                        <div>
                          <Trans>Description</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      return (
                        <div
                          style={{
                            flex: 1,
                            minWidth: 300
                          }}
                        >
                          {value}
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'totalCost',
                  label: myI18n?._(t`Total Requested to ICCE`),
                  options: {
                    customHeadLabelRender: props => {
                      return (
                        <div>
                          <Trans>Total Requested to ICCE</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      return 'CAD ' + currencyFormat.format(value)
                    }
                  }
                },
                {
                  name: 'otherDonors',
                  label: myI18n?._(t`Unit cost`),
                  options: {
                    customHeadLabelRender: props => {
                      return (
                        <div>
                          <Trans>Other Donors</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      return 'CAD ' + currencyFormat.format(value)
                    }
                  }
                }
              ]}
            />
          </>
        )}
        <Paper style={{ padding: 15 }}>
          <Typography variant='h5' style={{ marginBottom: 10 }}>
            <b>
              <Trans>Total</Trans>
            </b>
          </Typography>
          <Grid container direction='column'>
            <List>
              {fiscalYears.map((section, index) => (
                <ListItem key={index}>
                  <Grid container direction='row' alignItems='center'>
                    <Icon>attach_money</Icon>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginLeft: 10
                      }}
                    >
                      <Trans>SUB TOTAL</Trans>
                      {' ('}
                      <Trans>FISCAL YEAR</Trans> {yearLabel(index)}
                      {'): '}
                    </Typography>
                    <div
                      style={{
                        fontSize: 18,
                        marginLeft: 10
                      }}
                    >
                      {'CAD ' + currencyFormat.format(sumFiscalYear(index))}
                    </div>
                  </Grid>
                </ListItem>
              ))}
              <ListItem key='administrative'>
                <Grid container direction='row' alignItems='center'>
                  <Icon>history_edu</Icon>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    <Trans>Administrative costs, to a maximum of 10%</Trans>:
                  </Typography>
                  <div
                    style={{
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    {'CAD ' + currencyFormat.format(administrativeCosts)}
                  </div>
                </Grid>
              </ListItem>
              <ListItem key='totalRequested'>
                <Grid container direction='column'>
                  <Grid container direction='row' alignItems='center'>
                    <Icon>local_atm</Icon>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 21,
                        marginLeft: 10
                      }}
                    >
                      <Trans>TOTAL REQUESTED FROM ICCE</Trans>
                    </Typography>
                    <div
                      style={{
                        fontSize: 18,
                        marginLeft: 10
                      }}
                    >
                      {'CAD ' + currencyFormat.format(totalSum())}
                    </div>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem key='totalOther'>
                <Grid container direction='row' alignItems='center'>
                  <Icon>local_atm</Icon>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 21,
                      marginLeft: 10
                    }}
                  >
                    <Trans>TOTAL FROM OTHER DONORS</Trans>
                  </Typography>
                  <div
                    style={{
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    {'CAD ' + currencyFormat.format(totalOtherDonors())}
                  </div>
                </Grid>
              </ListItem>
              <ListItem key='total'>
                <Grid container direction='row' alignItems='center'>
                  <Icon>monetization_on</Icon>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 21,
                      marginLeft: 10
                    }}
                  >
                    <Trans>SUB TOTAL</Trans>
                  </Typography>
                  <div
                    style={{
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    {'CAD ' +
                      currencyFormat.format(
                        Number(
                          totalSum() + totalOtherDonors() + administrativeCosts
                        )
                      )}
                  </div>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Paper>
      </MuiThemeProvider>
    </Paper>
  )
}

export const FormICCEBudget = ({
  id,
  disabled,
  langVersion,
  saveDisabled,
  editMode,
  connectedObject,
  title,
  typeProps = {},
  ...props
}) => {
  const { baseCategories = [], requestedAmount } = typeProps
  const toSuggest = baseCategories.map(item => item.title?.[langVersion])

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  return (
    <Field name={id}>
      {({ form, field, meta }) => {
        return (
          <FormICCEBudetWrapped
            id={id}
            saveDisabled={saveDisabled}
            disabled={disabled}
            requestedAmount={requestedAmount}
            title={title}
            toSuggest={toSuggest}
            {...form}
            {...field}
            meta={meta}
          />
        )
      }}
    </Field>
  )
}

const FormICCEBudetWrapped = ({
  title,
  disabled,
  requestedAmount,
  toSuggest,
  setFieldTouched,
  setFieldValue,
  showPrintProps,
  saveDisabled,
  id,
  meta,
  value
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [deleting, setDeleting] = React.useState(false)
  const [currentStep, setStep] = React.useState(0)
  const fieldValue = value || {}

  const {
    fiscalYears = [],
    startYear = moment.utc().year(),
    administrativeCosts
  } = fieldValue

  const getFieldName = (tableMeta, name) => {
    const index = tableMeta.rowData[tableMeta.rowData.length - 1]
    return `.fiscalYears[${currentStep}].budgetLines[${index}].` + name
  }

  const sumFiscalYear = index => {
    let sum = 0
    fiscalYears[index].budgetLines.forEach(item => {
      if (item.totalCost) {
        sum += normalizeNumberValue(item.totalCost)
      }
    })
    return sum
  }

  const totalSum = () => {
    let sum = 0
    fiscalYears.forEach((year, index) => {
      sum += sumFiscalYear(index)
    })
    return sum
  }

  const totalAdministrativeCosts = () => {
    let sum = 0
    if (administrativeCosts) {
      var u = String(administrativeCosts).replaceAll(',', '')
      sum += Number(u)
    }
    return sum
  }

  const totalOtherDonors = () => {
    let sum = 0
    fiscalYears.forEach((year, index) => {
      year.budgetLines.forEach(line => {
        if (line.otherDonors) {
          const toAdd = String(line.otherDonors).replaceAll(',', '')
          sum += Number(toAdd)
        }
      })
    })
    return sum
  }

  const yearLabel = index => {
    return startYear + index + '/' + Number(startYear + 1 + index)
  }

  if (!requestedAmount && !showPrintProps) {
    return (
      <div style={{ color: 'red' }}>
        <b>
          <Trans>
            Either requested amount field were not connected in form editor or
            the field referenced was deleted!
          </Trans>
        </b>
      </div>
    )
  }

  return (
    <MuiThemeProvider theme={ICCETheme}>
      <Grid container direction='column' justify='center'>
        <div style={{ textAlign: 'center' }}>
          <h3>{title}</h3>
        </div>
        <Grid container direction='row' justify='center'>
          <CustomDatePicker
            disabled={disabled}
            style={{ marginRight: 15 }}
            value={moment.utc().set('year', startYear)}
            label={<Trans>Budget start year</Trans>}
            views={['year']}
            inputVariant='outlined'
            onChange={e => {
              const year = moment.utc(e).year()
              const newValue = { ...fieldValue }
              newValue.startYear = year
              setFieldValue(id, newValue)
            }}
          />
          <Button
            disabled={disabled}
            color='primary'
            variant='contained'
            onClick={e => {
              const newValue = { ...fieldValue }
              if (!newValue.fiscalYears) {
                newValue.fiscalYears = []
              }
              newValue.fiscalYears.push({
                budgetLines: []
              })
              setFieldValue(id, newValue)
            }}
          >
            <Trans>Add new fiscal year to budget</Trans>
          </Button>
        </Grid>
      </Grid>
      <Stepper
        nonLinear
        activeStep={currentStep}
        orientation='horizontal'
        alternativeLabel
      >
        {fiscalYears.map((section, index) => {
          return (
            <Step
              alt
              itemType='step'
              id='step'
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={e => setStep(index)}
            >
              <StepLabel id='label'>
                {yearLabel(index)}{' '}
                <IconButton
                  disabled={disabled || saveDisabled}
                  onClick={() => {
                    const newValue = { ...fieldValue }
                    const toDelete = newValue.fiscalYears[index]
                    deleteFiscalYear(toDelete).then(
                      r => {
                        enqueueSnackbar(<Trans>Deleted fiscal year!</Trans>, {
                          variant: 'success'
                        })
                        setDeleting(false)
                        newValue.fiscalYears.splice(index, 1)
                        setFieldValue(id, newValue)
                      },
                      reject => {
                        enqueueSnackbar(
                          <Trans>
                            Error ocurred while deleting fiscal year!
                          </Trans>,
                          {
                            variant: 'error'
                          }
                        )
                        setDeleting(false)
                      }
                    )
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {fiscalYears[currentStep] && (
        <>
          <MUIDataTable
            data={fiscalYears[currentStep].budgetLines.map((item, index) => ({
              ...item,
              // totalCost: calculateTotalCost(item),
              actions: index
            }))}
            options={{
              textLabels: muiTextLabels(myI18n),
              filter: false,
              pagination: false,
              selectableRows: 'none',
              print: false,
              download: false,
              viewColumns: false
            }}
            title={
              <Grid container direction='row'>
                <Typography variant='h5'>
                  <b>
                    <Trans>Fiscal Year</Trans>{' '}
                    <span>{yearLabel(currentStep)}</span>
                  </b>
                </Typography>
                <Button
                  disabled={disabled}
                  color='primary'
                  variant='contained'
                  style={{ marginLeft: 10 }}
                  onClick={e => {
                    const newValue = { ...fieldValue }
                    newValue.fiscalYears[currentStep].budgetLines.push({
                      id: 'fake' + crypto.randomBytes(8).toString('hex')
                    })
                    setFieldValue(id, newValue)
                  }}
                >
                  <Trans>Add new budget line</Trans>
                  <Icon style={{ marginLeft: 5 }}>add</Icon>
                </Button>
              </Grid>
            }
            columns={[
              {
                name: 'category',
                label: myI18n?._(t`Category`),
                options: {
                  customHeadLabelRender: props => {
                    return (
                      <div style={{ paddingLeft: 20 }}>
                        <Trans>Category</Trans>
                      </div>
                    )
                  },
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <div
                        style={{
                          flex: 1,
                          paddingLeft: 20,
                          minWidth: 300
                        }}
                      >
                        <Field name={id + getFieldName(tableMeta, 'category')}>
                          {({ field, form, meta }) => {
                            return (
                              <>
                                <Autocomplete
                                  disabled={disabled}
                                  {...field}
                                  value={value || ''}
                                  freeSolo
                                  fullWidth
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      id + getFieldName(tableMeta, 'category'),
                                      value
                                    )
                                  }}
                                  options={toSuggest}
                                  renderInput={params => (
                                    <TextField
                                      {...field}
                                      {...params}
                                      onBlur={e => {
                                        if (!meta.touched) {
                                          setFieldTouched(
                                            id +
                                              getFieldName(
                                                tableMeta,
                                                'category'
                                              )
                                          )
                                        }
                                      }}
                                      error={Boolean(
                                        meta.error && meta.touched
                                      )}
                                      value={value || ''}
                                      variant='outlined'
                                    />
                                  )}
                                />
                                <div style={{ height: 22 }}>
                                  <FormErrorLabel
                                    id={
                                      id + getFieldName(tableMeta, 'category')
                                    }
                                  />
                                </div>
                              </>
                            )
                          }}
                        </Field>
                      </div>
                    )
                  }
                }
              },
              {
                name: 'description',
                label: myI18n?._(t`Description`),
                options: {
                  customHeadLabelRender: props => {
                    return (
                      <div>
                        <Trans>Description</Trans>
                      </div>
                    )
                  },
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <div
                        style={{
                          flex: 1,
                          minWidth: 300
                        }}
                      >
                        <Field
                          name={id + getFieldName(tableMeta, 'description')}
                        >
                          {({ form, field, meta }) => (
                            <>
                              <TextField
                                value={field.value || ''}
                                disabled={disabled}
                                onBlur={e => {
                                  if (!meta.touched) {
                                    setFieldTouched(
                                      id +
                                        getFieldName(tableMeta, 'description')
                                    )
                                  }
                                }}
                                onChange={e => {
                                  setFieldValue(
                                    id + getFieldName(tableMeta, 'description'),
                                    e.target.value
                                  )
                                }}
                                error={Boolean(meta.error && meta.touched)}
                                inputProps={{
                                  maxLength: 255
                                }}
                                helperText={countHelperText(field.value, 255)}
                                variant='outlined'
                                fullWidth
                              />
                              <FormErrorLabel
                                id={id + getFieldName(tableMeta, 'description')}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    )
                  }
                }
              },
              {
                name: 'totalCost',
                label: myI18n?._(t`Total Requested to ICCE`),
                options: {
                  customHeadLabelRender: props => {
                    return (
                      <div>
                        <Trans>Total Requested to ICCE</Trans>
                      </div>
                    )
                  },
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <div style={{ flex: 1 }}>
                        <Field name={id + getFieldName(tableMeta, 'totalCost')}>
                          {({ form, field, meta }) => (
                            <>
                              <TextField
                                value={field.value || ''}
                                disabled={disabled}
                                InputProps={{
                                  inputComponent: NumberFormatCurrency,
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      CAD
                                    </InputAdornment>
                                  )
                                }}
                                onBlur={e => {
                                  if (!meta.touched) {
                                    setFieldTouched(
                                      id + getFieldName(tableMeta, 'totalCost')
                                    )
                                  }
                                }}
                                onChange={e => {
                                  setFieldValue(
                                    id + getFieldName(tableMeta, 'totalCost'),
                                    e.target.value
                                  )
                                }}
                                error={Boolean(meta.error && meta.touched)}
                                variant='outlined'
                                fullWidth
                              />
                              <div style={{ height: 22 }}>
                                <FormErrorLabel
                                  id={id + getFieldName(tableMeta, 'totalCost')}
                                />
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    )
                  }
                }
              },
              {
                name: 'otherDonors',
                label: myI18n?._(t`Unit cost`),
                options: {
                  customHeadLabelRender: props => {
                    return (
                      <div>
                        <Trans>Other Donors</Trans>
                      </div>
                    )
                  },
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <div style={{ flex: 1 }}>
                        <Field
                          name={id + getFieldName(tableMeta, 'otherDonors')}
                        >
                          {({ form, field, meta }) => (
                            <>
                              <TextField
                                value={field.value || ''}
                                disabled={disabled}
                                InputProps={{
                                  inputComponent: NumberFormatCurrency,
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      CAD
                                    </InputAdornment>
                                  )
                                }}
                                onChange={e => {
                                  setFieldValue(
                                    id + getFieldName(tableMeta, 'otherDonors'),
                                    e.target.value
                                  )
                                }}
                                error={Boolean(meta.error && meta.touched)}
                                variant='outlined'
                                fullWidth
                              />
                              <div style={{ height: 22 }}>
                                <FormErrorLabel
                                  id={
                                    id + getFieldName(tableMeta, 'otherDonors')
                                  }
                                />
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    )
                  }
                }
              },
              {
                name: 'actions',
                label: myI18n?._(t`Actions`),
                options: {
                  sort: false,
                  customHeadRender: props => {
                    return <TableCell style={{ width: 50 }} />
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <Grid container justify='flex-end'>
                        <IconButton
                          style={{ marginBottom: 22 }}
                          disabled={deleting || disabled || saveDisabled}
                          onClick={e => {
                            const newValue = { ...fieldValue }
                            const toDelete = {
                              ...newValue.fiscalYears[currentStep].budgetLines[
                                value
                              ]
                            }
                            setDeleting(true)
                            deleteBudgetLine(toDelete).then(
                              r => {
                                enqueueSnackbar(
                                  <Trans>Deleted budget line!</Trans>,
                                  {
                                    variant: 'success'
                                  }
                                )
                                setDeleting(false)
                                newValue.fiscalYears[
                                  currentStep
                                ].budgetLines.splice(value, 1)
                                setFieldValue(id, newValue)
                              },
                              reject => {
                                enqueueSnackbar(
                                  <Trans>
                                    Error ocurred while deleting budget line!
                                  </Trans>,
                                  {
                                    variant: 'error'
                                  }
                                )
                                setDeleting(false)
                              }
                            )
                          }}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Grid>
                    )
                  }
                }
              }
            ]}
          />
          <div style={{ marginTop: 10 }}>
            <b>
              <FormLabel
                label={myI18n?._(
                  errorFromPath(
                    ['fiscalYears', currentStep, 'budgetLines'],
                    meta.error
                  )
                )}
                error
              />
            </b>
          </div>
        </>
      )}
      <b>
        <FormLabel
          label={myI18n?._(errorFromPath(['fiscalYears'], meta.error))}
          error
        />
      </b>
      <Paper style={{ padding: 15 }}>
        <Typography variant='h5' style={{ marginBottom: 10 }}>
          <b>
            <Trans>Total</Trans>
          </b>
        </Typography>
        <Grid container direction='column'>
          <List>
            {fiscalYears.map((section, index) => (
              <ListItem key={index}>
                <Grid container direction='row' alignItems='center'>
                  <Icon>attach_money</Icon>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    <Trans>SUB TOTAL</Trans>
                    {' ('}
                    <Trans>FISCAL YEAR</Trans> {yearLabel(index)}
                    {'): '}
                  </Typography>
                  <div
                    style={{
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    {'CAD ' + currencyFormat.format(sumFiscalYear(index))}
                  </div>
                </Grid>
              </ListItem>
            ))}
            <ListItem key='administrative'>
              <Grid container direction='row' alignItems='center'>
                <Icon>history_edu</Icon>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginLeft: 10
                  }}
                >
                  <Trans>Administrative costs, to a maximum of 10%</Trans>:
                </Typography>
                <div
                  style={{
                    fontSize: 18,
                    marginLeft: 10
                  }}
                >
                  <FormikTextField
                    disabled={disabled}
                    name={id + '.administrativeCosts'}
                    variant='outlined'
                    InputProps={{
                      inputComponent: NumberFormatCurrency,
                      startAdornment: (
                        <InputAdornment position='start'>CAD</InputAdornment>
                      )
                    }}
                  />
                </div>
              </Grid>
            </ListItem>
            <ListItem key='totalRequested'>
              <Grid container direction='column'>
                <Grid container direction='row' alignItems='center'>
                  <Icon>local_atm</Icon>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 21,
                      marginLeft: 10
                    }}
                  >
                    <Trans>TOTAL REQUESTED FROM ICCE</Trans>
                  </Typography>
                  <div
                    style={{
                      fontSize: 18,
                      marginLeft: 10
                    }}
                  >
                    {'CAD ' + currencyFormat.format(totalSum())}
                  </div>
                </Grid>
              </Grid>
            </ListItem>

            <ListItem key='totalOther'>
              <Grid container direction='row' alignItems='center'>
                <Icon>local_atm</Icon>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 21,
                    marginLeft: 10
                  }}
                >
                  <Trans>TOTAL FROM OTHER DONORS</Trans>
                </Typography>
                <div
                  style={{
                    fontSize: 18,
                    marginLeft: 10
                  }}
                >
                  {'CAD ' + currencyFormat.format(totalOtherDonors())}
                </div>
              </Grid>
            </ListItem>
            <ListItem key='total'>
              <Grid container direction='row' alignItems='center'>
                <Icon>monetization_on</Icon>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 21,
                    marginLeft: 10
                  }}
                >
                  <Trans>SUB TOTAL</Trans>
                </Typography>
                <div
                  style={{
                    fontSize: 18,
                    marginLeft: 10
                  }}
                >
                  {'CAD ' +
                    currencyFormat.format(
                      Number(
                        totalSum() +
                          totalOtherDonors() +
                          totalAdministrativeCosts()
                      )
                    )}
                </div>
              </Grid>
            </ListItem>
          </List>
          <b>
            <FormLabel
              label={myI18n?._(errorFromPath(['sum'], meta.error))}
              error
            />
          </b>
        </Grid>
      </Paper>
    </MuiThemeProvider>
  )
}

export const FormEditorICCEBudget = ({
  editMode,
  id,
  depth,
  typeProps = {},
  langVersion,
  showPrintProps,
  showPrintComponent,
  ...props
}) => {
  const {
    baseCategories = [],
    required,
    unit,
    requestedAmount,
    defaultYear,
    budgetLinesType
  } = typeProps
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const { values } = useFormikContext()
  const { objects } = values

  let avaliableTypes = []
  objects.some(obj => {
    if (obj.name === 'Budget_Line__c') {
      obj.fields.some(field => {
        if (field.name === 'Type__c') {
          avaliableTypes = field.picklistValues.filter(field => field.active)
          return true
        }
        return false
      })
      return true
    }
    return false
  })

  if (showPrintComponent && !editMode) {
    return (
      <FormICCEBudgetPrint
        {...props}
        typeProps={typeProps}
        id={id}
        value={{
          fiscalYears: [{ year: moment.utc().year(), budgetLines: [{}] }]
        }}
      />
    )
  }

  if (!editMode) {
    return <FormICCEBudget editMode {...props} typeProps={typeProps} id={id} />
  }

  const avaliableElements = mapFormEditorElements({
    data: tree,
    type: 'textInputNumeric',
    id,
    langVersion
  })

  return (
    <div>
      <Grid container direction='row'>
        <ConfigureMultilanguageTextField
          value={unit}
          label={<Trans>Unit</Trans>}
          handleChange={value => {
            const toSet = { ...typeProps }
            toSet.unit = value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        />
      </Grid>

      {!showPrintProps && (
        <>
          <CustomDatePicker
            style={{ marginTop: 15 }}
            fullWidth
            openTo='year'
            views={['year']}
            format='YYYY'
            value={defaultYear}
            label={<Trans>Default budget start year</Trans>}
            inputVariant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.defaultYear = e
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />

          <Grid container direction='row' alignItems='center'>
            <Typography>
              <Trans>Base categories</Trans>
            </Typography>
            <IconButton
              onClick={() => {
                const title = languages.reduce((acc, lang) => {
                  acc[lang] = ''
                  return acc
                }, {})
                baseCategories.push({ title })
                typeProps.baseCategories = baseCategories
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
          </Grid>
          <Grid container direction='column'>
            {baseCategories.map((item, index) => (
              <>
                <Grid
                  item
                  container
                  direction='row'
                  key={index}
                  justifyContent='space-between'
                  alignItems='center'
                  wrap='nowrap'
                >
                  {index + 1 + '. '}
                  <Grid item style={{ flex: 1, padding: 10 }}>
                    <Grid container direction='row'>
                      <ConfigureMultilanguageTextField
                        value={item.title}
                        label={<Trans>Option label</Trans>}
                        handleChange={value => {
                          const toSet = { ...typeProps }
                          toSet.baseCategories[index].title = value
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ width: 50 }}>
                    <IconButton
                      onClick={() => {
                        const toSet = { ...typeProps }
                        toSet.baseCategories.splice(index, 1)
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
          {avaliableElements.length === 0 && (
            <p
              style={{
                color: '#f5543b',
                fontSize: '0.75rem',
                marginTop: 10,
                marginLeft: 14,
                textAlign: 'left'
              }}
            >
              <Trans>
                You must add at least one numeric element to the form which can
                be linked to Amount Requested field
              </Trans>
            </p>
          )}
          <TextField
            disabled={avaliableElements.length === 0}
            style={{ marginTop: 10 }}
            value={requestedAmount || ''}
            fullWidth
            variant='outlined'
            select
            label={<Trans>Requested amount element</Trans>}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.requestedAmount = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            {avaliableElements.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.label}
                </MenuItem>
              )
            })}
          </TextField>
        </>
      )}

      <TextField
        disabled={avaliableTypes.length === 0}
        style={{ marginTop: 10 }}
        value={budgetLinesType || ''}
        fullWidth
        variant='outlined'
        select
        label={<Trans>Budget lines type</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.budgetLinesType = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        <MenuItem value={null}>
          <Trans>None</Trans>
        </MenuItem>
        {avaliableTypes.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </TextField>

      <TextField
        disabled={avaliableTypes.length === 0}
        style={{ marginTop: 10 }}
        value={budgetLinesType || ''}
        fullWidth
        variant='outlined'
        select
        label={<Trans>Budget lines type</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.budgetLinesType = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        <MenuItem value={null}>
          <Trans>None</Trans>
        </MenuItem>
        {avaliableTypes.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </TextField>

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(required)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.required = e.target.checked
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Required</Trans>}
      />
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  )
}

export const ICCEBudget = ({ year, budgetLines, isPrintEditor }) => {
  return (
    <>
      <div style={{ padding: 8, textAlign: 'center' }}>
        <Typography className='form-print-subtitle'>
          <Trans>Year</Trans>
        </Typography>
        <Typography style={{ fontSize: 21 }}>{year}</Typography>
      </div>
      <Paper elevation={6} style={{ marginTop: 10, padding: 10 }}>
        <Grid container>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Category</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Description</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Total cost</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Other donors</Trans>
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {budgetLines.map((obj, index) => {
          const { category, description, totalCost, otherDonors } = obj
          return (
            <Grid container key={index}>
              <Grid item xs style={{ padding: 4 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {category}
                </Typography>
              </Grid>

              <Grid item xs style={{ padding: 4 }}>
                <Typography style={{ minHeight: 21 }}>{description}</Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {isPrintEditor ? (
                    totalCost
                  ) : (
                    <CurrencyFormated value={totalCost} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {isPrintEditor ? (
                    otherDonors
                  ) : (
                    <CurrencyFormated value={otherDonors} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          )
        })}
      </Paper>
    </>
  )
}
