import { t } from '@lingui/macro'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { Redirect } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import { EgretLoadable } from '../../../egret'
import { authRoles } from '../../auth/authRoles'
import ResourceInventory from '../resources/ResourceInventory'
import Committees from './committees/Committees'
import EditCommittee from './committees/EditCommittee'
import ExecutiveSummaries from './committees/ExecutiveSummaries'

const OrganisationDetails = EgretLoadable({
  loader: () => import('./organization/OrganisationDetails')
})

const JoinOrganization = EgretLoadable({
  loader: () => import('./organization/JoinOrganization')
})

const ReviewApplications = EgretLoadable({
  loader: () => import('./committees/ReviewApplications')
})

const Homepage = EgretLoadable({
  loader: () => import('./GrantsHome')
})

const ApplicationsList = EgretLoadable({
  loader: () => import('./applications/ApplicationsList')
})

const Meetings = EgretLoadable({
  loader: () => import('./committees/Meetings')
})

const AllocationCommitteeMeetingDetails = EgretLoadable({
  loader: () => import('./committees/AllocationCommitteeMeetingDetails')
})

const ValidatedApplicationsList = EgretLoadable({
  loader: () => import('./committees/ValidatedApplicationsList')
})

const ServiceInventory = EgretLoadable({
  loader: () => import('./ServiceInventory')
})

const EditService = EgretLoadable({
  loader: () => import('./EditService')
})

const ApplicationEdit = EgretLoadable({
  loader: () => import('./applications/old-application/ApplicationEdit')
})

const ActionPlan = EgretLoadable({
  loader: () => import('./action-plan/ActionPlan')
})

const Reports = EgretLoadable({
  loader: () => import('./grantee-reports/Reports')
})

const ExternalReviewSurvey = EgretLoadable({
  loader: () => import('./external-reviewer/ExternalReviewSurvey')
})

const AcceptReviewRequest = EgretLoadable({
  loader: () => import('./external-reviewer/ReviewerAccept')
})

const GranteeReport = EgretLoadable({
  loader: () => import('./grantee-reports/GranteeReport')
})

const Calendar = EgretLoadable({
  loader: () => import('./calendar/UserCalendar')
})

const AboutMe = EgretLoadable({
  loader: () => import('./external-reviewer/AboutMe')
})

const ApplyToExternalReview = EgretLoadable({
  loader: () => import('./external-reviewer/ApplyToExternalReview')
})

const AssignedOpportunities = EgretLoadable({
  loader: () => import('./external-reviewer/AssignedOpportunities')
})

const ApplicantInformation = EgretLoadable({
  loader: () => import('./ApplicantInformation')
})

const ActionTemplates = EgretLoadable({
  loader: () => import('./ActionTemplates')
})

const ActionTemplate = EgretLoadable({
  loader: () => import('./ActionTemplate')
})

const PrintView = EgretLoadable({
  loader: () => import('./applications/old-application/PrintView')
})

const Organizations = EgretLoadable({
  loader: () => import('./organization/UserOrganizations')
})

const ManageOrganizationMembers = EgretLoadable({
  loader: () => import('./organization/ManageOrganizationMembers')
})

const TechnicalAdvisories = EgretLoadable({
  loader: () => import('../grants/TechnicalAdvisories')
})

const grantsRoutes = [
  {
    path: '/grants/organization-details/:id?',
    component: OrganisationDetails
  },
  {
    path: '/grants/Committees/:initialTab?',
    component: Committees
  },
  {
    path: '/grants/Workgroups/:initialTab?',
    component: Committees,
    isTranslatable: true,
    label: myI18n._(t`Workgroups`)
  },
  {
    path: '/grants/EditCommittee/:id',
    component: EditCommittee
  },
  {
    path: '/grants/EditWorkgroup/:id',
    component: EditCommittee,
    isTranslatable: true,
    label: myI18n._(t`Edit workgroup`)
  },
  {
    path: '/grants/grant-application/:id/:viewType?',
    component: EgretLoadable({
      loader: () => import('./applications/Application')
    }),
    auth: authRoles.grantee
  },
  {
    path: '/grants/prequalification/:id/:viewType?',
    component: EgretLoadable({
      loader: () => import('./applications/Prequalification')
    }),
    auth: authRoles.grantee
  },
  {
    path: '/grants/ApplicationsList',
    component: ApplicationsList,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Application List`)
  },
  {
    path: '/grants/ResourceInventory',
    component: ResourceInventory,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Resource Inventory`)
  },
  {
    path: '/grants/TechnicalAdvisories',
    component: TechnicalAdvisories,
    auth: authRoles.grantee
  },
  {
    path: '/grants/ActionTemplates',
    component: ActionTemplates,
    auth: authRoles.editor,
    isTranslatable: true,
    label: myI18n._(t`Action Templates`)
  },
  {
    path: '/grants/ActionTemplate/:id',
    component: ActionTemplate,
    auth: authRoles.editor,
    isTranslatable: true,
    label: myI18n._(t`Action Template Editor`)
  },
  {
    path: '/grants/home',
    component: Homepage,
    isTranslatable: true,
    label: myI18n._(t`Homepage`)
  },
  {
    path: '/grants/ExecutiveSummaries/:committee',
    component: ExecutiveSummaries,
    isTranslatable: true,
    label: myI18n._(t`Selection Committee Executive Summaries`)
  },
  {
    path: '/grants/Meetings/:committee',
    component: Meetings,
    isTranslatable: true,
    label: myI18n._(t`Meetings`)
  },
  {
    path: '/grants/AllocationCommitteeMeetingDetails/:id',
    component: AllocationCommitteeMeetingDetails,
    isTranslatable: true,
    label: myI18n._(t`Selection Committee Executive Summaries`)
  },
  {
    path: '/grants/ValidatedApplicationsList_AC',
    component: ValidatedApplicationsList,
    auth: authRoles.allocationCommittee,
    isTranslatable: true,
    label: myI18n._(t`Selection Committee Executive Summaries`)
  },
  {
    path: '/grants/ValidatedApplicationsList_Board',
    component: ValidatedApplicationsList,
    auth: authRoles.board,
    isTranslatable: true,
    label: myI18n._(t`Selection Committee Executive Summaries`)
  },
  {
    path: '/grants/ValidatedApplicationsList_CMHC',
    component: ValidatedApplicationsList,
    auth: authRoles.cmhc,
    isTranslatable: true,
    label: myI18n._(t`Selection Committee Executive Summaries`)
  },
  {
    path: '/grants/ServiceInventory',
    component: ServiceInventory,
    auth: authRoles.admin
  },
  {
    path: '/grants/EditService/:id',
    component: EditService,
    auth: authRoles.admin
  },
  {
    path: '/grants/ReviewApplications_Board/:id',
    component: ReviewApplications,
    auth: authRoles.board,
    isTranslatable: true,
    label: myI18n._(t`Review Application - Board`)
  },
  {
    path: '/grants/ReviewApplications_AC/:id',
    component: ReviewApplications,
    auth: authRoles.allocationCommittee,
    isTranslatable: true,
    label: myI18n._(t`Review Application - AC`)
  },
  {
    path: '/grants/ReviewApplications_SC/:id',
    component: ReviewApplications,
    isTranslatable: true,
    label: myI18n._(t`Review Application - SC`)
  },
  {
    path: '/grants/ReviewApplications_SC-CHGFB3/:id',
    component: ReviewApplications,
    isTranslatable: true,
    label: myI18n._(t`Review Application - SC-CHGFB3`)
  },
  {
    path: '/grants/ReviewApplications_CMHC/:id',
    component: ReviewApplications,
    auth: authRoles.cmhc,
    isTranslatable: true,
    label: myI18n._(t`Review Application - CMHC`)
  },
  {
    path: '/grants/Application/:id',
    component: ApplicationEdit,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Old Application Form`)
  },
  {
    path: '/grants/Calendar',
    component: Calendar,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Calendar`)
  },
  {
    path: '/grants/ActionPlan',
    component: ActionPlan,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Action Plan`)
  },
  {
    path: '/grants/Reports',
    component: Reports,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Reports`)
  },
  {
    path: '/grants/reviewer-profile',
    component: AboutMe,
    //auth: authRoles.reviewer,
    //isTranslatable: true,
    label: myI18n._(t`Reviewer Profile`)
  },
  {
    path: '/grants/apply-to-external-reviewer',
    component: ApplyToExternalReview,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Become an External Reviewer`)
  },
  {
    path: '/grants/assigned-reviews',
    component: AssignedOpportunities,
    auth: authRoles.reviewer,
    isTranslatable: true,
    label: myI18n._(t`Assigned Reviews`)
  },
  {
    path: '/grants/ExternalReviewSurvey/:id/:mode',
    component: ExternalReviewSurvey,
    auth: authRoles.reviewer,
    isTranslatable: true,
    label: myI18n._(t`External Review`)
  },
  {
    path: '/grants/AcceptReview/:id',
    component: AcceptReviewRequest,
    auth: authRoles.reviewer,
    isTranslatable: true,
    label: myI18n._(t`Accept External Review`)
  },
  {
    path: '/grants/GranteeReport/:id/:mode?',
    component: GranteeReport,
    auth: authRoles.grantee
  },
  {
    path: '/grants/print/:id',
    component: PrintView
  },
  {
    path: '/grants/Organizations',
    component: Organizations,
    isTranslatable: true,
    label: myI18n._(t`Organizations`)
  },
  {
    path: '/grants/ManageOrganizationMembers/:id?',
    component: ManageOrganizationMembers,
    isTranslatable: true,
    label: myI18n._(t`Manage Users`)
  },
  {
    path: '/grants/user-profile',
    component: sfOauthConfig.isInternal
      ? () => <Redirect to='/session/404' />
      : ApplicantInformation,
    auth: authRoles.user
  },
  {
    path: '/grants/JoinOrganization',
    component: JoinOrganization,
    auth: authRoles.grantee,
    isTranslatable: true,
    label: myI18n._(t`Join Organization`)
  }
]

export default grantsRoutes
