import { t, Trans } from '@lingui/macro'
import {
  Divider,
  Grid,
  Icon,
  IconButton,
  TableCell,
  Typography
} from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import { mapContact } from 'app/services/sfAuth/sfData/sfContact'
import {
  addOpportunityContactAffiliationByFlow,
  updateOpportunityContactAffiliationByFlow
} from 'app/services/sfAuth/sfData/sfOpportunityAffiliation'
import { MUEditedByLabel } from 'app/views/forms/multiuser/components/MUEditedByLabel'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import * as crypto from 'crypto'
import { useField, useFormikContext } from 'formik'
import moment from 'moment/moment'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { getMuState, parseFormLabelText } from '../../../common/Common'
import {
  endEditingField
} from '../../../multiuser/grpcMultiuserEdit'

Yup.addMethod(Yup.array, 'checkAssociatedContacts', function (item) {
  return this.test('checkAssociatedContacts', null, function (array) {
    const { path, createError } = this
    const { roles } = item.typeProps
    if (!array) {
      return true
    }
    const countMap = {}
    let missingInfoError = false
    array.forEach(obj => {
      if (!obj.deleted) {
        const { jobTitle, phoneNumber, workPhone, mobilePhone } = obj
        if (!countMap[obj.role]) {
          countMap[obj.role] = 1
        } else {
          countMap[obj.role] = countMap[obj.role] + 1
        }
        const missingInfo =
          !jobTitle || (!phoneNumber && !workPhone && !mobilePhone)
        if (missingInfo) {
          missingInfoError = true
        }
      }
    })
    const notEnoughRolesError =
      roles &&
      roles.some(role => {
        const min = +role.min
        if (!min) {
          return false
        }
        return countMap[role.apiValue] < min || !countMap[role.apiValue]
      })

    if (notEnoughRolesError) {
      return createError({
        path,
        message: (
          <Trans>
            All roles must have assigned the minimum number of contacts
          </Trans>
        )
      })
    } else if (missingInfoError) {
      return createError({
        path,
        message: (
          <Trans>
            Some of affiliated contacts have required information missing
          </Trans>
        )
      })
    }
    return true
  })
})

export const formProjectAffiliaitonsValidation = item =>
  Yup.array().checkAssociatedContacts(item)

export const formProjectAffiliaitonsSavePromise = ({
  value,
  item,
  connectedObject,
  additionalSFInfo
}) => {
  const toCreate = []
  const toUpdate = []
  value.forEach(obj => {
    if (!obj.id.includes('fake')) {
      if (obj.deleted) {
        // toDelete.push({ Id: obj.id })
        toUpdate.push({
          Id: obj.id,
          Status__c: 'Former',
          End_Date__c: moment.utc().format(dateFormat)
        })
      } else {
        toUpdate.push({
          Id: obj.id,
          Status__c: 'Current'
        })
      }
    } else {
      if (!obj.deleted) {
        toCreate.push({
          Contact__c: obj.contactId,
          Relationship__c: obj.relationship,
          Role__c: obj.role,
          Opportunity__c: connectedObject.Id,
          Status__c: 'Current'
        })
      }
    }
  })

  if (toUpdate.length > 0 || toCreate.length > 0) {
    const promises = []
    if (toUpdate.length > 0) {
      promises.push(() => updateOpportunityContactAffiliationByFlow(toUpdate))
    }
    if (toCreate.length > 0) {
      promises.push(() => addOpportunityContactAffiliationByFlow(toCreate))
    }
    return { promise: promises, afterMainPromise: true }
  } else {
    return {
      promise: Promise.resolve({
        success: true
      }),
      afterMainPromise: false
    }
  }
}

export const formProjectAffiliaitonsDefaultValue = (
  obj,
  additionalInfo,
  item
) => {
  const { roles = [] } = item.typeProps
  if (!obj || !additionalInfo.opportunityAffiliatedContacts) {
    return []
  } else {
    const toMap = additionalInfo.opportunityAffiliatedContacts || []
    const avaliableRoles = roles.map(role => role.apiValue)
    return toMap
      .filter(
        obj =>
          avaliableRoles.includes(obj.Role__c) && obj.Status__c !== 'Former'
      )
      .map(item => {
        const contactInfo = mapContact(item.Contact__c)
        return {
          ...contactInfo,
          contactId: contactInfo.id,
          id: item.Id,
          role: item.Role__c,
          relationship: item.Relationship__c,
          actions: {
            id: item.Id,
            contactInfo
          }
        }
      })
  }
}

export const formProjectAffiliaitonsValueToText = (v = [], question) => {
  const { roles } = question.typeProps
  const toRet = langVersion => {
    const roleToTrans = {}
    roles.forEach(role => {
      if (role.apiValue) {
        roleToTrans[role.apiValue] = parseFormLabelText({
          text: role.label,
          langVersion
        })
      }
    })
    return (
      <div>
        <Grid container style={{ width: '100%' }}>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Contact name</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Contact e-mai</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Contact role</Trans>
            </Typography>
          </Grid>
        </Grid>
        {v
          .filter(c => !c.deleted)
          .map((contact, index) => {
            return (
              <>
                <Divider />
                <Grid container key={index} style={{ width: '100%' }}>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography>{contact.name}</Typography>
                  </Grid>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography>{contact.email}</Typography>
                  </Grid>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography>
                      {roleToTrans[contact.role] || contact.role}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )
          })}
      </div>
    )
  }

  const retObj = languages.reduce((acc, lang) => {
    acc[lang] = toRet(lang)
    return acc
  }, {})

  return retObj
}

/**
 * Form element which allows user to select contacts from organizations address book as references in the project. Renders a Material UI table, displaying all currently added affiliations.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection}  connectedObject Connected Salesforce object of type Opportunity.
 * @param  {object[]}  typeProps.roles Roles, configured in editor, for which user must assign contacts from the address book. Each role has a minimum and maximum number of references that can be assigned to it.
 * @param  {boolean}  [typeProps.showRelationshipColumn=false] If table should display additional column containing relationship of the contact
 */
export const FormProjectAffiliations = ({
  typeProps,
  title,
  disabled,
  saveDisabled,
  connectedObject,
  id,
  muBag,
  useMultiuser,
  editMode,
  reloadLastModifiedDates,
  i18n,
  langVersion,
  ...props
}) => {
  const value = props.value || []
  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  const { roles = [], showRelationshipColumn } = typeProps
  const { enqueueSnackbar } = useSnackbar()
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(id)
  const { touched } = meta
  const { isEdited, userColor, userName } = getMuState({ values, id })

  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  const rolesLeft = []
  const valueToLabel = {}
  roles.forEach(role => {
    let usedCount = 0
    const label = parseFormLabelText({ text: role.label, langVersion, i18n })
    valueToLabel[role.apiValue] = label
    value.forEach(obj => {
      if (obj.role === role.apiValue && !obj.deleted) {
        usedCount++
      }
    })
    rolesLeft.push({
      label,
      askForRelationship: role.askForRelationship,
      value: role.apiValue,
      defaultOrganization: role.defaultOrganization,
      error: role.min && usedCount < +role.min,
      min: role.min || 0,
      current: usedCount,
      limit: role.limit || +role.min + 1
    })
  })

  const onContactFound = contact => {
    const toSet = [...value]
    if (
      toSet.some(
        obj =>
          obj.role === contact.role &&
          obj.contactId === contact.id &&
          !obj.deleted
      )
    ) {
      enqueueSnackbar(
        <Trans>This contact is already associated with this role!</Trans>,
        { variant: 'error' }
      )
    } else {
      const fakeId = 'fake' + crypto.randomBytes(8).toString('hex')
      toSet.push({
        ...contact,
        id: fakeId,
        contactId: contact.id,
        actions: {
          id: fakeId,
          contactInfo: contact
        }
      })
      setFieldValue(id, toSet)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId: id,
          fieldValue: toSet,
          lockId: values.muInfo.lockId
        })
      }
    }
  }

  const onContactUpdate = newValues => {
    const toSet = [...value]
    toSet.forEach((aff, index) => {
      const bool = aff.contactId === newValues.id
      if (bool) {
        toSet[index] = {
          ...aff,
          ...newValues,
          id: aff.id,
          role: aff.role,
          relationship: aff.relationship,
          contactId: newValues.id,
          actions: {
            id: aff.id,
            contactInfo: { ...aff.actions.contactInfo, ...newValues }
          }
        }
      }
      return bool
    })
    setFieldValue(id, toSet)
    if (useMultiuser) {
      endEditingField({
        ...muBag,
        fieldId: id,
        fieldValue: toSet,
        lockId: values.muInfo.lockId
      })
    }
  }

  return (
    <>
      <MUIDataTable
        data={value?.filter(v => !v.deleted)}
        title={
          <Grid container wrap='nowrap'>
            {/* <AddressBookContactPicker
              preview={saveDisabled}
              onDialogClose={e => {
                if (useMultiuser) {
                  endEditingField({
                    ...muBag,
                    fieldId: id,
                    fieldValue: field.value,
                    lockId: values.muInfo.lockId
                  })
                }
              }}
              onDialogOpen={e => {
                if (useMultiuser) {
                  startEditingField({
                    ...muBag,
                    fieldId: id
                  })
                }
              }}
              allowCreatingNewContact
              disabled={
                disabled || editMode || rolesLeft.length === 0 || saveDisabled
              }
              onSelect={onContactFound}
              roles={rolesLeft}
            /> */}
          </Grid>
        }
        columns={[
          {
            name: 'name',
            label: myI18n?._(t`Contact name`),
            options: {
              customHeadLabelRender: props => {
                return (
                  <div style={{ marginLeft: 20 }}>
                    <Trans>Contact name</Trans>
                  </div>
                )
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>
              }
            }
          },
          {
            name: 'email',
            label: myI18n?._(t`Contact e-mail`),
            options: {
              customHeadLabelRender: props => <Trans>E-mail</Trans>
            }
          },
          {
            name: 'role',
            label: myI18n?._(t`Contact role`),
            options: {
              customHeadLabelRender: props => <Trans>Contact role</Trans>,
              customBodyRender: value => valueToLabel[value]
            }
          },
          {
            name: 'relationship',
            label: myI18n?._(t`Relationship`),
            options: {
              display: showRelationshipColumn ? 'true' : 'excluded',
              customHeadLabelRender: props => <Trans>Relationship</Trans>
            }
          },
          {
            name: 'actions',
            options: {
              sort: false,
              customHeadRender: props => {
                return <TableCell />
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                const { contactInfo } = value
                return (
                  <Grid container wrap='nowrap' justifyContent='flex-end'>
                    {/* {missingInfo && (
                      <FillMissingInformationForContact
                        contactId={contactInfo.id}
                        phone={phoneNumber}
                        mobilePhone={mobilePhone}
                        workPhone={workPhone}
                        jobTitle={jobTitle}
                        onContactUpdate={onContactUpdate}
                      />
                    )} */}

                    {/* <EditContactPanel
                      disabled={disabled || saveDisabled}
                      onContactUpdate={onContactUpdate}
                      contactInfo={contactInfo}
                      opportunityId={connectedObject.Id}
                      reloadLastModifiedDates={reloadLastModifiedDates}
                      programManager={
                        connectedObject.Assigned_Program_Manager__c
                      }
                    /> */}

                    <IconButton
                      disabled={disabled || saveDisabled}
                      onClick={() => {
                        const toSet = [...field.value]
                        toSet.some((obj, index) => {
                          const bool = obj.id === value.id
                          if (bool) {
                            const item = { ...obj }
                            item.deleted = true
                            toSet[index] = item
                          }
                          return bool
                        })
                        setFieldValue(id, toSet)
                        if (useMultiuser) {
                          endEditingField({
                            ...muBag,
                            fieldId: id,
                            fieldValue: toSet,
                            lockId: values.muInfo.lockId
                          })
                        }
                        if (!touched) {
                          setFieldTouched(id)
                        }
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                )
              }
            }
          }
        ]}
        options={{
          textLabels: muiTextLabels(myI18n),
          search: false,
          filter: false,
          selectableRows: 'none',
          print: false,
          download: false,
          viewColumns: false
        }}
      />
      <FormErrorLabel error={meta.error} id={id} />
      {isEdited && (
        <div style={{ marginTop: 5 }}>
          <MUEditedByLabel color={userColor} userName={userName} />
        </div>
      )}
    </>
  )
}

// export const EditContactPanel = ({
//   canUpdateRole = false,
//   noRelatedUser = false,
//   reloadLastModifiedDates,
//   disabled,
//   contactInfo,
//   onContactUpdate,
//   opportunityId,
//   renderIconOnLeft,
//   programManager
// }) => {
//   const [dialogOpen, setDialogOpen] = useState(false)
//   const [updating, setUpdating] = useState(false)
//   const { enqueueSnackbar } = useSnackbar()
//   const user = useSelector(state => state.user)
//   const organization = useSelector(state => state.organization)
//   const { phoneNumber, role, owner, addressBookAffiliationId } = contactInfo
//   const canUpdateContact = owner === user.userId || noRelatedUser
//   const initialValues = { ...contactInfo }
//   const missingInfo = canUpdateRole
//     ? Boolean(!role || !phoneNumber)
//     : !phoneNumber

//   return (
//     <Formik
//       initialValues={{
//         phoneNumberCountryCode: '1',
//         ...initialValues
//       }}
//       enableReinitialize
//       validateOnBlur
//       validateOnMount
//       validationSchema={Yup.object().shape({
//         firstName: Yup.string().nullable().required(requiredTrans),
//         lastName: Yup.string().nullable().required(requiredTrans),
//         role: Yup.string()
//           .nullable()
//           .test('ifRoleIsRequired', requiredTrans, function (value) {
//             if (!addressBookAffiliationId || !canUpdateContact) {
//               return true
//             } else {
//               return Boolean(value)
//             }
//           }),
//         // jobTitle: Yup.string()
//         //   .nullable()
//         //   .required(requiredTrans),
//         // providedOrganizationName: Yup.string()
//         //   .nullable()
//         //   .required(requiredTrans),
//         email: Yup.string()
//           .nullable()
//           .ensure()
//           .required(requiredTrans)
//           .email(() => emailTrans),
//         phoneNumber: Yup.string()
//           .nullable()
//           .required(requiredTrans)
//           .test(
//             'isValidPhone',
//             () => <Trans>Phone Number must have at least 10 digits</Trans>,
//             value => {
//               if (value) {
//                 const filtered = value.replace(/[^0-9]/g, '')
//                 return filtered.length >= 10
//               } else {
//                 return true
//               }
//             }
//           )
//       })}
//     >
//       {({ values, dirty, isValid, errors }) => {
//         const { role, id } = values
//         const alert = (
//           <Alert severity='warning'>
//             <Trans>
//               Some of required information in this contact are missing
//             </Trans>
//           </Alert>
//         )
//         const iconButton = (
//           <Tooltip title={<Trans>Update affiliated contact</Trans>}>
//             <span>
//               <IconButton
//                 disabled={disabled}
//                 onClick={e => {
//                   setDialogOpen(true)
//                 }}
//               >
//                 <Icon>edit</Icon>
//               </IconButton>
//             </span>
//           </Tooltip>
//         )

//         return (
//           <>
//             <Dialog open={dialogOpen} fullWidth maxWidth='md'>
//               <DialogTitle>
//                 <Grid
//                   container
//                   direction='row'
//                   justify='space-between'
//                   alignItems='center'
//                 >
//                   <Trans>Contact information</Trans>
//                   <IconButton
//                     disabled={updating}
//                     style={{ marginLeft: 10 }}
//                     onClick={e => {
//                       setDialogOpen(false)
//                     }}
//                   >
//                     <Icon>close</Icon>
//                   </IconButton>
//                 </Grid>
//               </DialogTitle>
//               {!canUpdateContact && (
//                 <div style={{ paddingLeft: 24, paddingRight: 24 }}>
//                   <Alert severity='info'>
//                     <Trans>
//                       You are not the owner of this contact, your change request
//                       will be reviewed
//                     </Trans>
//                   </Alert>
//                 </div>
//               )}

//               <DialogContent>
//                 <Grid
//                   container
//                   wrap='nowrap'
//                   style={{ marginBottom: 10, marginTop: 10 }}
//                 >
//                   <Grid xs item style={{ paddingRight: 5 }}>
//                     <FormikTextField
//                       disabled={updating}
//                       variant='outlined'
//                       label={<Trans>First Name</Trans>}
//                       name='firstName'
//                       fullWidth
//                     />
//                   </Grid>
//                   <Grid xs item style={{ paddingLeft: 5 }}>
//                     <FormikTextField
//                       disabled={updating}
//                       variant='outlined'
//                       label={<Trans>Last Name</Trans>}
//                       name='lastName'
//                       fullWidth
//                     />
//                   </Grid>
//                 </Grid>
//                 <FormikTextField
//                   disabled={updating}
//                   variant='outlined'
//                   label={<Trans>E-mail</Trans>}
//                   name='email'
//                   style={{ marginBottom: 10 }}
//                   fullWidth
//                 />
//                 <Grid container style={{ marginBottom: 10 }}>
//                   <Grid item>
//                     <FormikTextField
//                       disabled={updating}
//                       variant='outlined'
//                       name='phoneNumberCountryCode'
//                       InputProps={{
//                         inputComponent: PhoneCountryCodeFormat
//                       }}
//                       inputProps={{
//                         maxLength: 5
//                       }}
//                       style={{ width: 65 }}
//                     />
//                   </Grid>

//                   <Grid item xs>
//                     <FormikTextField
//                       disabled={updating}
//                       variant='outlined'
//                       label={<Trans>Phone number</Trans>}
//                       name='phoneNumber'
//                       InputProps={{
//                         inputComponent: PhoneFormatWithExtension
//                       }}
//                       style={{ marginBottom: 10 }}
//                       fullWidth
//                     />
//                   </Grid>
//                 </Grid>

//                 {/* <FormikTextField
//                   disabled={updating}
//                   variant='outlined'
//                   label={<Trans>Organization</Trans>}
//                   name='providedOrganizationName'
//                   style={{ marginBottom: 10 }}
//                   fullWidth
//                   helperText={<Trans>Required</Trans>}
//                 /> */}

//                 {!canUpdateContact && (
//                   <Grid
//                     container
//                     justifyContent='flex-end'
//                     style={{ paddingBottom: 15 }}
//                   >
//                     <Button
//                       variant='contained'
//                       color='primary'
//                       disabled={!isValid || updating}
//                       onClick={e => {
//                         setUpdating(true)
//                         let body = ''
//                         const textData = [
//                           { key: 'firstName', text: myI18n?._(t`First name`) },
//                           { key: 'lastName', text: myI18n?._(t`Last name`) },
//                           { key: 'email', text: myI18n?._(t`Email`) }
//                           // {
//                           //   key: 'providedOrganizationName',
//                           //   text: myI18n?._(t`Organization`)
//                           // },
//                           //{ key: 'jobTitle', text: myI18n?._(t`Job title`) }
//                         ]
//                         textData.forEach(obj => {
//                           const { key, text } = obj
//                           if (values[key] !== initialValues[key]) {
//                             body += text + ': ' + values[key] + '\n'
//                           }
//                         })
//                         if (
//                           values.phoneNumber !== initialValues.phoneNumber ||
//                           values.phoneNumberCountryCode !==
//                             initialValues.phoneNumberCountryCode
//                         ) {
//                           body +=
//                             myI18n?._(t`Phone number`) +
//                             ': ' +
//                             parseExtensionPhoneToSF(
//                               values.phoneNumber,
//                               values.phoneNumberCountryCode
//                             )
//                         }
//                         const toCreate = {
//                           title: 'Update contact information',
//                           type: 'Portal - help',
//                           contact: values.id,
//                           language: user.language,
//                           opportunityId,
//                           userName: user.displayName,
//                           organization: organization.id,
//                           description: body
//                         }
//                         if (programManager) {
//                           toCreate.OwnerId = programManager
//                           toCreate.Skip_Assignment__c = true
//                         }
//                         createCaseByFlow(toCreate).then(
//                           result => {
//                             reloadLastModifiedDates().then(result => {
//                               enqueueSnackbar(
//                                 <Trans>Information submitted!</Trans>,
//                                 {
//                                   variant: 'info'
//                                 }
//                               )
//                               setUpdating(false)
//                               setDialogOpen(false)
//                             })
//                           },
//                           reject => {
//                             enqueueSnackbar(
//                               <Trans>Error Submitting Case</Trans>,
//                               {
//                                 variant: 'error'
//                               }
//                             )
//                             setUpdating(false)
//                           }
//                         )
//                       }}
//                     >
//                       <Icon style={{ marginRight: 5 }}>mail</Icon>
//                       <Trans>Submit changes</Trans>
//                     </Button>
//                   </Grid>
//                 )}

//                 {addressBookAffiliationId &&
//                   (canUpdateContact || canUpdateRole) && (
//                     <div>
//                       <FormikTextField
//                         disabled={updating}
//                         variant='outlined'
//                         label={<Trans>Job Title/Role in organization</Trans>}
//                         name='role'
//                         style={{ marginBottom: 10 }}
//                         inputProps={{
//                           maxLength: 80
//                         }}
//                         helperText={
//                           <span key='limit'>
//                             {countHelperText(role, 80)}
//                             {'. '}
//                           </span>
//                         }
//                         fullWidth
//                       />
//                       <Grid container justifyContent='flex-end'>
//                         <Button
//                           variant='contained'
//                           color='primary'
//                           disabled={
//                             (!isValid && canUpdateContact) ||
//                             updating ||
//                             (!canUpdateContact && !role)
//                           }
//                           onClick={e => {
//                             setUpdating(true)
//                             const toSave = canUpdateContact
//                               ? { ...values }
//                               : {
//                                   role,
//                                   id
//                                 }
//                             Promise.all([
//                               saveContactByFlowParsed(toSave),
//                               addressBookAffiliationId
//                                 ? updateAddressBookOrganizationAffiliationByFlow({
//                                     Id: addressBookAffiliationId,
//                                     Job_Title__c: role
//                                   })
//                                 : Promise.resolve()
//                             ]).then(
//                               result => {
//                                 const handleSuccess = () => {
//                                   enqueueSnackbar(
//                                     <Trans>Contact updated!</Trans>,
//                                     {
//                                       variant: 'info'
//                                     }
//                                   )
//                                   setUpdating(false)
//                                   const toForward = { ...values }
//                                   toForward.name =
//                                     values.firstName + ' ' + values.lastName
//                                   onContactUpdate(toForward)
//                                   setDialogOpen(false)
//                                 }
//                                 if (reloadLastModifiedDates) {
//                                   reloadLastModifiedDates().then(result => {
//                                     handleSuccess()
//                                   })
//                                 } else {
//                                   handleSuccess()
//                                 }
//                               },
//                               reject => {
//                                 enqueueSnackbar(
//                                   <Trans>
//                                     Error ocurred while updating contact!
//                                   </Trans>,
//                                   {
//                                     variant: 'error'
//                                   }
//                                 )
//                                 setUpdating(false)
//                               }
//                             )
//                           }}
//                         >
//                           <Icon style={{ marginRight: 5 }}>save</Icon>
//                           {canUpdateRole && !canUpdateContact ? (
//                             <Trans>Update role in organization</Trans>
//                           ) : (
//                             <Trans>Update</Trans>
//                           )}
//                         </Button>
//                       </Grid>
//                     </div>
//                   )}
//               </DialogContent>
//             </Dialog>
//             {renderIconOnLeft ? (
//               <>
//                 <Grid item>{iconButton}</Grid>
//                 {missingInfo && <Grid item>{alert}</Grid>}
//               </>
//             ) : (
//               <Grid container wrap='nowrap' justifyContent='flex-end'>
//                 {missingInfo && alert}
//                 {iconButton}
//               </Grid>
//             )}
//           </>
//         )
//       }}
//     </Formik>
//   )
// }
