import { t, Trans } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    minHeight: 150
  },
  tableCol: {
    //width: '18%',
    borderStyle: 'solid',
    minHeight: 35,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    padding: 4,
    textAlign: 'center'
  }
})

export const FormMilestonesFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const phases = typeProps.phases || []
  const phasesLabels = phases.map(phase => phase?.label?.[langVersion])
  const rowHeight = 100 / (phases.length + 1)

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, { height: rowHeight + '%' }]} wrap={false}>
        <View style={[styles.tableCol, { width: '10%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`FORM_MILESTONES_PHASE_NAME`)}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: '10%' }]}>
          <Text style={styles.tableCell}>
            <Trans>Start Date</Trans>
          </Text>
        </View>
        <View style={[styles.tableCol, { width: '10%' }]}>
          <Text style={styles.tableCell}>
            <Trans>End Date</Trans>
          </Text>
        </View>
        <View style={[styles.tableCol, { width: '50%' }]}>
          <Text style={styles.tableCell}>
            <Trans>Primary Activities</Trans>
          </Text>
        </View>
        <View style={[styles.tableCol, { width: '20%' }]}>
          <Text style={styles.tableCell}>
            <Trans>Comments</Trans>
          </Text>
        </View>
      </View>

      {phasesLabels.map((item, index) => (
        <View
          style={[styles.tableRow, { height: rowHeight + '%' }]}
          wrap={false}
          key={index}
        >
          <View style={[styles.tableCol, { width: '10%' }]}>
            {(item !== '' && item !== undefined ) && <Text style={styles.tableCell}>{item}</Text>}
          </View>
          <View style={[styles.tableCol, { width: '10%' }]}></View>
          <View style={[styles.tableCol, { width: '10%' }]}></View>
          <View style={[styles.tableCol, { width: '50%' }]}></View>
          <View style={[styles.tableCol, { width: '20%' }]}></View>
        </View>
      ))}
    </View>
  )
}
