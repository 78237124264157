import { t } from "@lingui/macro";
import { myI18n } from "translation/I18nConnectedProvider";
import { formConditions } from "../editor/ConditionalElementEditor";

export function purgeInvalidPDFSignFromString(text: string) {
  return text.replaceAll(
    /[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000\u0009]/g,
    " "
  );
}

export function parsePDFLabelToSupportWorkBreaking(text: string) {
  return text ? text.split("") : "";
}

type ConditionConfigProps = {
  condition: string;
  conditionTarger: string;
  parameter: string;
  targetIndex: string;
  elementType: string;
  affectedElementType: string;
  state:
    | "show"
    | "hide"
    | "altLabel"
    | "altHelpText"
    | "required"
    | "notRequired";
};

export function formConditionToText(conditionConfig: ConditionConfigProps) {
  let toRet = "";
  const { state, condition, elementType, affectedElementType, parameter } =
    conditionConfig;
  if (!condition) {
    return null;
  }

  if (state === "show") {
    toRet =
      affectedElementType === "uploadFiles"
        ? myI18n._(t`PDF_CONDITION_IF`) + " "
        : myI18n._(t`PDF_CONDITION_ANSWER_IF`) + " ";
  } else if (state === "hide") {
    toRet = myI18n._(t`PDF_CONDITION_DO_NOT_ANSWER_IF`) + " ";
  } else {
    return null;
  }
  const config = formConditions[elementType]?.conditions[condition];
  if (config && config.manualFillText) {
    toRet += myI18n._(config.manualFillText);
    if (parameter) {
      toRet += ` '${parameter}' `;
    }
  }
  toRet +=
    myI18n._(t`PDF_CONDITION_IN_QUESTION`) + ` ${conditionConfig.targetIndex}.`;

  return toRet;
}
