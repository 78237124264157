import { t, Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  contentDocumentDownloadUrl,
  deleteDocumentByFlow,
  uploadFile
} from 'app/services/sfAuth/sfData/sfFiles'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { useField, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import Dropzone from 'react-dropzone'
import { myI18n } from 'translation/I18nConnectedProvider'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { parseFormLabelText } from '../../../common/Common'
import { useFormContext } from '../../../form-page/FormContext'
import { endEditingField } from '../../../multiuser/grpcMultiuserEdit'
import { parseExcelFileImportPdf, preQualificationHousingPortfolioFields, propertyInformationFields, uploadExcelFileImportPdf } from './excelFileImportPdfParser'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
const crypto = require('crypto')

/**
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {string[]} [typeProps.tags] Tags that will be assigned to the uploaded file.
 */
export const FormExcelFileImport = ({
  id,
  langVersion,
  muBag,
  value,
  disabled,
  useMultiuser,
  connectedObject,
  typeProps,
  formikRef,
  describeMap,
  i18n,
  ...props
}) => {
  const preview = false

  const { network } = useFormContext()
  const { required, tags = [], uploadedDocumentType } = typeProps
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(id)
  const classes = formUploadComponentStyles()
  const invalid = Boolean(!connectedObject || !connectedObject.Id) && !preview
  const uploadPrefix = parseFormLabelText({
    text: typeProps.uploadPrefix,
    i18n,
    langVersion
  })

  const [selectedOption, setSelectedOption] = useState()
  const isConnectedToOpp = Boolean(typeProps?.connectedTo?.find(
    item => item?.forceType === 'Opportunity' && item?.connectedObject))
  const isConnectedToPrequal = Boolean(typeProps?.connectedTo?.find(
    item => item?.forceType === "Pre_Qualification__c" && item?.connectedObject))
  const [oppFileDownloadUrl, setOppFileDownloadUrl] = useState()
  const [prequalFileDownloadUrl, setPrequalFileDownloadUrl] = useState()
  const organization = useSelector(state => state.organization)
  const downloadLinkRef = useRef(null)

  useEffect(() => {
    if(organization){
      const oppFilePath = '/assets/docs/NS-CHCF Property information.xlsx';
      const prequalFilePath = '/assets/docs/NS-CHCF Pre-qualification Housing Portfolio.xlsx';

      uploadExcelFileImportPdf({filePath: oppFilePath, organization}).then((url) => {
        setOppFileDownloadUrl(url)
      })
      uploadExcelFileImportPdf({filePath: prequalFilePath, organization}).then((url) => {
        setPrequalFileDownloadUrl(url)
      })
    }
  }, [organization])

  const handleOnDrop = ({ files, id, fieldValue }) => {
    console.log('handleOnDrop', files, network)
    const listCount = files.length
    const uploadId = listCount + 1
    const file = files[0]
    let uploadedName = file.name
    if (uploadPrefix) {
      uploadedName = uploadPrefix + ' ' + uploadedName
    }
    const newValue = [...formikRef.current.values[id]]
    const fakeId = crypto.randomBytes(8).toString('hex')
    newValue.push({
      uploadId,
      name: uploadedName,
      progress: !preview,
      actionId: fakeId
    })
    setFieldValue(id, newValue)
    if (useMultiuser) {
      endEditingField({
        ...muBag,
        fieldId: id,
        fieldValue: newValue
      })
    }
    if (preview) {
      enqueueSnackbar(<Trans>Uploaded File</Trans>, {
        variant: 'success'
      })
      return
    }
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
      const newValue = [...formikRef.current.values[id]]
      newValue.some((file, index) => {
        if (file.actionId === fakeId) {
          newValue.splice(index, 1)
          return true
        }
        return false
      })
      setFieldValue(id, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId: id,
          fieldValue: newValue
        })
      }
    }
    reader.onload = () => {
      const binaryStr = reader.result
      const buffer = Buffer.from(binaryStr)

      if(selectedOption === "opp"){
        parseExcelFileImportPdf({
          file: buffer, 
          noOfHeaderRows: selectedOption === "opp" ? 17 : 10,
          fields: selectedOption === "opp" ? propertyInformationFields : preQualificationHousingPortfolioFields
        })
        .then(result => {
          console.log('parseExcelFileImportPdf', { result })
        })
      }

      const uploadingKey = enqueueSnackbar(<Trans>Uploading File</Trans>, {
        variant: 'info'
      })
      const handleError = () => {
        const newValue = [...formikRef.current.values[id]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        setFieldValue(id, newValue)
        closeSnackbar(uploadingKey)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId: id,
            fieldValue: newValue
          })
        }
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
      let tagsString = id
      if (tags.length > 0) {
        tags.filter(tag => tag).forEach(tag => (tagsString += '&tag&' + tag))
      }
      uploadFile(
        {
          name: uploadedName,
          type: uploadedDocumentType,
          tags: tagsString,
          uploadId: connectedObject.Id
        },
        binaryStr,
        network.Id,
        true
      )
        .then(uploadedFile => {
          enqueueSnackbar(<Trans>Uploaded File</Trans>, {
            variant: 'success'
          })
          const newValue = [...formikRef.current.values[id]]
          newValue.some((file, index) => {
            if (file.actionId === fakeId) {
              newValue[index] = {
                id: uploadedFile.Id,
                url: contentDocumentDownloadUrl(uploadedFile.Id),
                tags: tagsString,
                uploadId,
                name: uploadedName
              }
              return true
            }
            return false
          })
          // files
          //   .map(item => parseDocument(item))
          //   .filter(file => file.tags && file.tags.includes(id))
          setFieldValue(id, newValue)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue
            })
          }
        })
        .catch(error => {
          console.warn('cant upload file', error)
          enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
            variant: 'error'
          })
          handleError()
        })
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = ({ id, files, fieldId, index }) => {
    if (preview) {
      const newValue = [...files.filter(item => item.id !== id)]
      setFieldValue(fieldId, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId,
          fieldValue: newValue
        })
      }
      enqueueSnackbar(<Trans>Deleted File</Trans>, {
        variant: 'success'
      })
      return
    }
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })

    const fakeId = crypto.randomBytes(8).toString('hex')
    const newValue = [...formikRef.current.values[fieldId]]
    newValue[index].deleting = true
    newValue[index].actionId = fakeId
    setFieldValue(fieldId, newValue)

    deleteDocumentByFlow(id).then(
      result => {
        enqueueSnackbar(<Trans>Deleted File</Trans>, {
          variant: 'success'
        })
        const newValue = [...formikRef.current.values[field.name]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        setFieldValue(fieldId, newValue)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId,
            fieldValue: newValue
          })
        }
      },
      reject => {
        enqueueSnackbar(<Trans>Error ocurred while deleting file!</Trans>, {
          variant: 'error'
        })
        const newFiles = [...formikRef.current.values[field.name]]
        newFiles.some((file, index) => {
          if (file.actionId === fakeId) {
            delete newFiles[index].actionId
            delete newFiles[index].deleting
            return true
          }
          return false
        })
        // delete toSet[index].deleting
        setFieldValue('files', newFiles)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId,
            fieldValue: newValue
          })
        }
      }
    )
  }

  const createTable = ({ files = [], fieldId }) => {
    const columns = [t`Name`, t`Remove`]
    return (
      <TableContainer>
        <Table style={{ whiteSpace: 'pre' }}>
          <colgroup>
            <col width='85%' />
            <col width='15%' />
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {columns.map((item, i) => (
                <TableCell key={i}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingRight: i === 1 && 20
                    }}
                  >
                    {myI18n?._(item)}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => {
              const iconDisabled = file.progress || file.deleting || disabled
              return [
                <TableRow key={index}>
                  <TableCell>
                    <div style={{ paddingLeft: 35 }}>
                      <Link color='primary' href={file.url}>
                        {file.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 20
                      }}
                    >
                      <Tooltip title={myI18n?._(t`Remove file`)}>
                        <IconButton
                          variant='filled'
                          disabled={iconDisabled}
                          onClick={() => {
                            onRemoveItem({
                              id: file.id,
                              fieldId,
                              files,
                              index
                            })
                          }}
                        >
                          <Icon
                            className={
                              iconDisabled
                                ? ''
                                : 'material-icons MuiIcon-root MuiIcon-colorError'
                            }
                            variant='filled'
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>,
                file.progress && (
                  <TableRow key={files.length + 1}>
                    <TableCell colSpan={3}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const isError = Boolean(meta.error)
  return (
    <div>
      <Typography variant='caption'>
        <Trans>FORM_EXCEL_FILE_IMPORT_DOWNLOAD_FILE_SELECT_TITLE</Trans>:
      </Typography>
      <TextField
        select
        style={{ width: '100%', marginBottom: 10, marginTop: 15 }}
        variant='outlined'
        id="select-label"
        label={<Trans>FORM_EXCEL_FILE_IMPORT_DOWNLOAD_FILE_SELECT_LABEL</Trans>}
        onChange={async (event) => {
          console.log(event.target.value)
          setSelectedOption(event.target.value)
        }}
      >
        {isConnectedToPrequal && <MenuItem value='prequal'>NS-CHCF Pre-qualification Housing Portfolio</MenuItem>}
        {isConnectedToOpp && <MenuItem value='opp'>NS-CHCF Property information</MenuItem>}
      </TextField>

      {selectedOption && <div style={{marginTop: 0, marginBottom: 20}}>
        <Typography variant='caption'>
          <Trans>FORM_EXCEL_FILE_IMPORT_DOWNLOAD_TITLE</Trans>:
        </Typography>
        {selectedOption === "opp" && oppFileDownloadUrl && <div>
        <a href={oppFileDownloadUrl}  target='_blank' 
          style={{textDecoration: "underline"}}
          download="NS-CHCF Property information.xlsx"
        >
          NS-CHCF Property information.xlsx
        </a></div>}
        {selectedOption === "prequal" && prequalFileDownloadUrl && <div>
        <a href={prequalFileDownloadUrl} target='_blank' 
          style={{textDecoration: "underline"}}
          download="NS-CHCF Pre-qualification Housing Portfolio.xlsx"
        >
          NS-CHCF Pre-qualification Housing Portfolio.xlsx
        </a></div>}
      </div>}

      {createTable({ files: field.value, fieldId: id })}
      <Dropzone
        disabled={disabled || invalid}
        multiple={false}
        maxFiles={1}
        onDrop={files => {
          handleOnDrop({
            files,
            id,
            fieldValue: field.value
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={disabled || invalid ? classes.disabled : classes.dropbox}
          >
            <section>
              <div>
                <input {...getInputProps()} />
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <Icon style={{ marginRight: 10 }}>upload</Icon>
                    <Trans>
                      Drag 'n' drop file here, or click to select file
                    </Trans>
                  </Grid>
                </div>
              </div>
              <div />
            </section>
          </div>
        )}
      </Dropzone>
      {invalid && (
        <div style={{ padding: 10, color: 'red' }}>
          <Trans>There is no object connected in editor!</Trans>
        </div>
      )}
      <FormErrorLabel
        error={isError}
        id={id}
        required={required}
        label={<Trans>Maximum file size is 52 MB</Trans>}
      />
    </div>
  )
}

const styles = theme => ({
  dropbox: {
    margin: '20px 0',
    border: '2px dashed #0085B8',
    padding: 35,
    backgroundColor: 'rgba(0, 133, 184, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 184, 0.15)',
      border: '3px dashed #0085B8',
      textWeight: 'bold'
    }
  },
  disabled: {
    margin: '20px 0',
    border: '2px dashed rgba(158, 158, 158)',
    padding: 35,
    backgroundColor: 'rgba(245, 245, 245, 0.05)'
  }
})
export const formUploadComponentStyles = makeStyles(styles)

export const formUploadFilesConditionsStates = {
  minFiles: {
    label: <Trans>Set minimum number of files to upload</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          InputProps={{ inputComponent: DefaultNumericFormat }}
          label={<Trans>Number of files</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}
