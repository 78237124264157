import { combineReducers } from 'redux'
import ActionPlanReducer from './ActionPlanReducer'
import AvaliableOrganizationsReducer from './AvaliableOrganizationsReducer'
import CaseDataReducer from './CaseDataRecuder'
import ConfigurationReducer from './ConfigurationReducer'
import EcommerceReducer from './EcommerceReducer'
import formEditorReducer from './FormEditorReducer'
import FundingStreamsReducer from './FundingStreamsReducer'
import LayoutReducer from './LayoutReducer'
import LoginReducer from './LoginReducer'
import multiuserReducer from './MultiuserReducer'
import OrganizationReducer from './OrganizationReducer'
import PortalTranslationDataReducer from './PortalTranslationDataReducer'
import PortalTranslationReducer from './PortalTranslationReducer'
import tutorialsReducer from './TutorialsReducer'
import userReducer from './UserReducer'
import i18nReducer from './i18nReducer'

const RootReducer = combineReducers({
  organization: OrganizationReducer,
  caseTypes: CaseDataReducer,
  login: LoginReducer,
  actionPlan: ActionPlanReducer,
  user: userReducer,
  layout: LayoutReducer,
  tutorials: tutorialsReducer,
  ecommerce: EcommerceReducer,
  configuration: ConfigurationReducer,
  avaliableOrganizations: AvaliableOrganizationsReducer,
  i18n: i18nReducer,
  formEditorTree: formEditorReducer,
  multiuser: multiuserReducer,
  portalTranslationConfig: PortalTranslationReducer,
  portalTranslationData: PortalTranslationDataReducer,
  fundingStreams: FundingStreamsReducer
})

export default RootReducer
