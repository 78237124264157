import { Trans, t } from '@lingui/macro'
import { MILESTONE_ORDER } from 'app/services/sfAuth/sfData/sfOpportunity'
import { languages } from 'translation/I18nConnectedProvider'
import moment from 'moment'

export const formMilestoneUpdateValueToText = v => {
  return languages.reduce((acc, lang) => {
    if (lang === 'en') {
      acc[lang] = (
        <div>
          <Trans>Data is too complex to show objectives!</Trans>
          <div style={{ color: '#e0a912' }}>
            <Trans>
              Overriding will ony save changes to already added objectives. It
              will not delete any newly added milestones. Manual cleanup may be
              required.
            </Trans>
          </div>
        </div>
      )
    } else {
      acc[lang] = <Trans>Data is too complex to show differences!</Trans>
    }
    return acc
  }, {})
}

export const FormMilestoneUpdateExtractKey = ({
  saveMap,
  subObjectsMap,
  additionalSFInfo,
  value,
  values,
  item,
  connectedMap,
  connectedProps,
  connectedObjectId,
  sfObject,
  appConfigurations
}) => {
  const milestoneUpdates = []
  if(connectedProps.forceType !== "FGM_Base__Grantee_Report__c"){
    return
  }

  value.forEach(item => {
    milestoneUpdates.push({
      Id: item.milestoneUpdate.id,
      Benchmark__c: item.id,
      Grantee_Report__c: sfObject.Id,
      Phase_start_date__c: item.milestoneUpdate.phaseStartDate,
      Phase_end_date__c: item.milestoneUpdate.phaseEndDate,
      Primary_activities_planned__c: item.milestoneUpdate.primaryActivities,
      Primary_activities_progress__c: item.milestoneUpdate.implementedActivities,
    })
  })
  if (milestoneUpdates.length) {
    saveMap[connectedObjectId].Benchmark_Updates__r = milestoneUpdates
  }
}

export const FormMilestoneUpdateDefaultValue = (
  obj,
  {  contactsMap, accountsMap, initObj, connectedMap, ...additionalSFInfo} = {},
  item
) => {

  if (!obj?.Opportunity?.FGM_Base__Benchmarks__r) {
    return []
  }

  // reports for the connected opportunity without the current report
  const reports = obj.Opportunity.reports || []

  // milestone updates for the connected report
  const granteeReportMilestoneUpdates = obj?.FGM_Base__Grantee_Report__c?.Benchmark_Updates__r?.records || []

  const toRet = obj.Opportunity.FGM_Base__Benchmarks__r.records
    .map(milestone => {

      const milestoneUpdate = granteeReportMilestoneUpdates.find(item => item.Benchmark__c === milestone.Id)

      // milestone update for the milestone in the latest report
      const latestMilestoneUpdate = reports?.[0]?.Benchmark_Updates__r?.records?.find(item => {
        return item.Benchmark__c === milestone.Id
      })

      // reports that have a milestone update for the milestone
      const milestoneUpdateReports = reports?.filter(report => {
        const milestoneReport = report.Benchmark_Updates__r?.records?.find(item => item.Benchmark__c === milestone.Id)
        return milestoneReport ? true : false
      })

      // milestone update for milestone in the reports
      const reportMilestoneUpdates = milestoneUpdateReports?.map(report => report.Benchmark_Updates__r?.records?.find(item => item.Benchmark__c === milestone.Id))

      //dates for the milestone
      const startDate = latestMilestoneUpdate? latestMilestoneUpdate?.Phase_start_date__c : milestone.FGM_Base__Due_Date__c 
      const endDate = latestMilestoneUpdate? latestMilestoneUpdate?.Phase_end_date__c : milestone.FGM_Base__Completion_Date__c

      //dates for the milestone update
      const phaseStartDate = milestoneUpdate?.Phase_start_date__c || startDate
      const phaseEndDate = milestoneUpdate?.Phase_end_date__c || endDate

      const areDatesChanged = (startDate === phaseStartDate && endDate === phaseEndDate) ? "no": "yes"

      return {
        startDate,
        endDate,
        primaryActivities: milestone.Primary_activities__c || '',
        comments: milestone.FGM_Base__Description__c || '',
        stage: milestone.FGM_Base__Status__c,
        id: milestone.Id,
        milestoneUpdate: {
          id: milestoneUpdate?.Id,
          primaryActivities: milestoneUpdate?.Primary_activities_planned__c || '',
          implementedActivities: milestoneUpdate?.Primary_activities_progress__c || '',
          milestoneId: milestone.Id,
          mileStoneName: milestone.Name,
          createdDate: milestoneUpdate?.CreatedDate,
          createdById: milestoneUpdate?.CreatedById,
          lastModifiedById: milestoneUpdate?.LastModifiedById,
          name: milestoneUpdate?.name,
          granteeReportId: milestoneUpdate?.Grantee_Report__c,
          phaseStartDate: phaseStartDate || startDate,
          phaseEndDate: phaseEndDate ||endDate,
          areDatesChanged,
        },
        latestMilestoneUpdate: latestMilestoneUpdate || {},
        reportMilestoneUpdates: reportMilestoneUpdates || [],
        reports
      }
    })
    .sort(
      (a, b) =>
        MILESTONE_ORDER.indexOf(a.stage) - MILESTONE_ORDER.indexOf(b.stage)
    )
    
  return toRet
}
