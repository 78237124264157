import { Text } from '@react-pdf/renderer'
import Html from 'react-pdf-html'
import { parseFormLabelText, pdfDefaultFontSize } from '../../common/Common'

export const FormHtmlPdf = ({
  typeProps,
  langVersion,
  describeMap,
  objectsFieldsMap
}) => {
  const { html, pdfFontSize } = typeProps

  const parsed = parseFormLabelText({
    text: html,
    langVersion,
    describeMap,
    objectsFieldsMap,
    returnString: true
  })

  const style = { fontSize: pdfDefaultFontSize }
  if (pdfFontSize) {
    style.fontSize = Number(pdfFontSize)
  }
  return (
    <Text>
      <Html style={style} collapse={false}>
        {parsed}
      </Html>
    </Text>
  )
}
