import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { currencyFormatedString } from 'app/views/common/Formats'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormBudgetUpdatePDF = ({
  id,
  langVersion,
  useMultiuser,
  muBag,
  title,
  connectedObject,
  editMode,
  reloadLastModifiedDates,
  typeProps,
  i18n,
  disabled,
  helpText,
  value,
  ...props
}) => {
  const fontFamily =
    langVersion === 'iu-cans'
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily

  const pdfStyles = StyleSheet.create({
    headerCell: {
      textAlign: 'center',
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    },
    cell: {
      padding: 10,
      fontSize: 10,
      flex: 1,
      fontFamily,
      border: '1px solid black',
      borderCollapse: 'collapse'
    }
  })

  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps

  const headerStyle = { ...pdfStyles.headerCell }
  if (pdfHeaderTextProps.includes('bold')) {
    headerStyle.fontWeigth = 'bold'
  }
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }

  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      firstCol: {
        width: '40%'
      },
      secondCol: {
        width: '30%'
      },
      thirdCol: {
        width: '30%'
      }
    })
  }

  const { expensesLines, revenuesLines, latestUpdate } = value

  let revenuesSum = 0
  let expensesSum = 0
  let revenuesSumActual = 0
  let expensesSumActual = 0

  Object.entries(revenuesLines).forEach(([key, obj]) => {
    const {
      budgetUpdateChange,
      budgetUpdateActual,
      applicationBudget,
      latestUpdate
    } = obj
    const value =
      budgetUpdateChange?.change ||
      latestUpdate?.change ||
      applicationBudget.value
    const actual = budgetUpdateActual?.actual
    if (value) {
      revenuesSum += +value
    }
    if (actual) {
      revenuesSumActual += +actual
    }
  })
  Object.entries(expensesLines).forEach(([key, obj]) => {
    const {
      budgetUpdateChange,
      budgetUpdateActual,
      applicationBudget,
      latestUpdate
    } = obj
    const value =
      budgetUpdateChange?.change ||
      latestUpdate?.change ||
      applicationBudget.value
    const actual = budgetUpdateActual?.actual
    if (value) {
      expensesSum += +value
    }
    if (actual) {
      expensesSumActual += +actual
    }
  })

  return (
    <>
      <View style={styles.table} wrap={false}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.firstCol]}></View>
          <View
            style={[styles.tableCol, styles.secondCol, styles.centeredCell]}
          >
            <Text style={styles.tableCell}>{myI18n._(t`Amount`)}</Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol, styles.centeredCell]}>
            <Text style={styles.tableCell}>{myI18n._(t`Actual`)}</Text>
          </View>
        </View>

        <View style={{ ...styles.tableRow, ...styles.tableCol, width: '100%' }}>
          <Text style={styles.mainTableCell}>{myI18n._(t`Revenues`)}</Text>
        </View>

        {Object.entries(revenuesLines).map(([key, item]) => {
          const {
            budgetUpdateChange,
            budgetUpdateActual,
            applicationBudget,
            latestUpdate
          } = item
          const change =
            budgetUpdateChange.change ||
            latestUpdate?.change ||
            applicationBudget.value

          return (
            <View style={styles.tableRow} wrap={false} key={key}>
              <View style={[styles.tableCol, styles.firstCol]}>
                <Text style={styles.tableCell}>{myI18n._(key)}</Text>
              </View>
              <View
                style={[styles.tableCol, styles.secondCol, styles.centeredCell]}
              >
                <Text style={[styles.tableCell]}>
                  {currencyFormatedString(change, langVersion)}
                </Text>
              </View>
              <View
                style={[styles.tableCol, styles.thirdCol, styles.centeredCell]}
              >
                <Text style={[styles.tableCell]}>
                  {currencyFormatedString(
                    budgetUpdateActual?.actual,
                    langVersion
                  )}
                </Text>
              </View>
            </View>
          )
        })}

        <View style={{ ...styles.tableRow, ...styles.tableCol, width: '100%' }}>
          <Text style={styles.mainTableCell}>{myI18n._(t`Expenses`)}</Text>
        </View>

        {Object.entries(expensesLines).map(([key, item]) => {
          const {
            budgetUpdateChange,
            budgetUpdateActual,
            applicationBudget,
            latestUpdate
          } = item
          const change =
            budgetUpdateChange.change ||
            latestUpdate?.change ||
            applicationBudget.value

          return (
            <View style={styles.tableRow} wrap={false} key={key}>
              <View style={[styles.tableCol, styles.firstCol]}>
                <Text style={styles.tableCell}>{myI18n._(key)}</Text>
              </View>
              <View
                style={[styles.tableCol, styles.secondCol, styles.centeredCell]}
              >
                <Text style={[styles.tableCell]}>
                  {currencyFormatedString(change, langVersion)}
                </Text>
              </View>
              <View
                style={[styles.tableCol, styles.thirdCol, styles.centeredCell]}
              >
                <Text style={[styles.tableCell]}>
                  {currencyFormatedString(
                    budgetUpdateActual?.actual,
                    langVersion
                  )}
                </Text>
              </View>
            </View>
          )
        })}

        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Total Revenews`)}
            </Text>
          </View>
          <View
            style={[styles.tableCol, styles.secondCol, styles.centeredCell]}
          >
            <Text style={[styles.mainTableCell]}>
              {currencyFormatedString(revenuesSum, langVersion)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol, styles.centeredCell]}>
            <Text style={[styles.mainTableCell]}>
              {currencyFormatedString(revenuesSumActual, langVersion)}
            </Text>
          </View>
        </View>

        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Total Expenses`)}
            </Text>
          </View>
          <View
            style={[styles.tableCol, styles.secondCol, styles.centeredCell]}
          >
            <Text style={[styles.mainTableCell]}>
              {currencyFormatedString(expensesSum, langVersion)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol, styles.centeredCell]}>
            <Text style={[styles.mainTableCell]}>
              {currencyFormatedString(expensesSumActual, langVersion)}
            </Text>
          </View>
        </View>
      </View>
    </>
  )
}
