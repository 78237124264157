import { Trans } from '@lingui/macro'
import { Divider, Icon, List, ListItem, ListItemIcon } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

/** Component to render a list of objective updates
 * @component
 * @param {string} title - The title of the list
 * @param {array} value - values of objective updates
 * @returns {React.Component}
 */
export const FormObjectivesUpdateReadOnly = ({ title, value }) => {
  return (
    <div style={{ width: '100%' }}>
      <div className='form-print-title'>{title}</div>
      {value.map((data, index) => {
        const { objective, needs, outcomes = [], lastObjectivesUpdates = [], objectivesUpdate = {} } = data
        const identifiedNeedChanges = [objectivesUpdate].filter(update => update.identifiedNeedChanges).map(update => update.identifiedNeedChanges)
        const actualOutcomes = [objectivesUpdate].filter(update => update.actualOutcomes).map(update => update.actualOutcomes)
        const desiredOutcomeChanges = [objectivesUpdate].filter(update => update.desiredOutcomeChanges).map(update => update.desiredOutcomeChanges)
        return (
          <Paper
            elevation={6}
            key={index}
            style={{ width: '100%', padding: 10, marginTop: 15 }}
          >
            <div style={{ padding: 8 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Objective</Trans>
              </Typography>
              <Typography>{objective}</Typography>
            </div>
            <Divider />
            <div style={{ padding: 8, display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Needs</Trans>
                </Typography>
                <Typography>{needs}</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>FORM_OBJECTIVES_UPDATE_READONLY_IDENTIFIED_NEED_CHANGES_TITLE</Trans>
                </Typography>
                <FormObjectivesUpdateList array={identifiedNeedChanges} />
              </div>
            </div>
            <Divider />
            <div style={{ padding: 8, display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Outcomes</Trans>
                </Typography>
                <FormObjectivesUpdateList array={outcomes} />
              </div>
              <div style={{ flex: 1 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>FORM_OBJECTIVES_UPDATE_READONLY_ACTUAL_OUTCOMES_TITLE</Trans>
                </Typography>
                <FormObjectivesUpdateList array={actualOutcomes} />
                <Typography className='form-print-subtitle'>
                  <Trans>FORM_OBJECTIVES_UPDATE_READONLY_DESIRED_OUTCOME_CHANGES_TITLE</Trans>
                </Typography>
                <FormObjectivesUpdateList array={desiredOutcomeChanges} />
              </div>
            </div>
          </Paper>
        )
      })}
    </div>
  )
}

/** Helper component to render a list of items 
 * @component
 * @param {array} array - The array of items to render
 * @returns {React.Component} - The list of items or a message if the array is empty
*/
const FormObjectivesUpdateList = ({array}) => {
  return (
    <List>
      {/* {Boolean(array.length === 0) && (
        <ListItem style={{ display: 'flex', fontWeight: 'bold' }}>
          <Trans>This list is empty!</Trans>
        </ListItem>
      )} */}
      {array.map((item, index) => {
        return (
          <ListItem key={index} style={{ display: 'flex' }}>
            {/* <ListItemIcon>
              <Icon>fiber_manual_record</Icon>
            </ListItemIcon> */}

            <Typography>
              <Trans id={item} />
            </Typography>
          </ListItem>
        )
      })}
    </List>
  )
}
