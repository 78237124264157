import { Trans, t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Typography
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { dateFormat } from 'app/appSettings'
import {
  entityTypes,
  saveConflictOfInterest
} from 'app/services/sfAuth/sfData/sfCase'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import { muiDateCompare } from 'utils'
import Loading from '../../../../egret/components/EgretLoadable/Loading'
import {
  CBTI_RECORD_TYPE,
  CHGF_CB_RECORD_TYPE,
  CHGF_PP_RECORD_TYPE,
  CHGF_RI_RECORD_TYPE,
  NLCHGF_RECORD_TYPE,
  NPF_CB_RECORD_TYPE,
  NPF_PPD_RECORD_TYPE,
  NPF_RI_RECORD_TYPE,
  STF_LP_RECORD_TYPE,
  STF_SIP_RECORD_TYPE,
  afterValidatedStages,
  getOpportunitiesParsed,
  isDetailedValidatedStages,
  isFake,
  mapFields,
  opportunitiesStagesInOrder
} from '../../../services/sfAuth/sfData/sfOpportunity'
import { mapToFields } from '../../../services/sfAuth/sfDataService'
import { CurrencyFormated } from '../../common/Formats'
import { muiTextLabels } from '../../utilities/muiDataTablesTranslations'

const stageHeaders = {
  validated: <Trans>Validated applications</Trans>,
  toValidate_SC: <Trans>Selection committee applications</Trans>,
  'toValidate_SC-NPF': <Trans>Selection committee applications</Trans>,
  'toValidate_SC-CHGFB3': <Trans>Selection committee applications</Trans>,
  toValidate: <Trans>Applications awaiting valiation</Trans>,
  denied: <Trans>Declined applications</Trans>,
  recentlyAwarded: <Trans>Recently awarded applications</Trans>
}

export const stageSorting = {
  validated: {
    name: 'stageName',
    direction: 'asc'
  },
  toValidate: {
    name: 'date',
    direction: 'asc'
  },
  denied: {
    name: 'date',
    direction: 'asc'
  }
}

const validatedStages = [
  'Validated',
  'Awarded',
  'Contracted',
  'Operational',
  'Special scrutiny',
  'Finished',
  'Terminated'
]

export const committeeInfo = {
  AC: {
    toValidateStages: ['AC'],
    reviewStages: ['AC'],
    recordTypes: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE, CBTI_RECORD_TYPE],
    showTables: ['toValidate', 'denied', 'validated']
  },
  SC: {
    toValidateStages: ['SC'],
    reviewStages: ['SC'],
    recordTypes: [
      CHGF_CB_RECORD_TYPE,
      CHGF_PP_RECORD_TYPE,
      CHGF_RI_RECORD_TYPE,
      NPF_CB_RECORD_TYPE,
      NPF_PPD_RECORD_TYPE,
      NPF_RI_RECORD_TYPE,
      NLCHGF_RECORD_TYPE
    ],
    showTables: ['toValidate']
  },
  Board: {
    toValidateStages: ['AC'],
    reviewStages: ['Board'],
    recordTypes: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE, CBTI_RECORD_TYPE],
    showTables: ['toValidate', 'denied', 'validated']
  },
  CMHC: {
    toValidateStages: ['AC'],
    reviewStages: ['CMHC'],
    recordTypes: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE, CBTI_RECORD_TYPE],
    showTables: ['toValidate', 'denied', 'validated']
  }
}

export default function ExecutiveSummaries () {
  const { committee } = useParams()
  const { user } = useSelector(state => state)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [list, setList] = useState([])
  const language = useSelector(state => state?.user?.language || 'en')
  const fundingStreams = useSelector(state => state.fundingStreams.streams)

  const fetchData = () => {
    const { recordTypes, fundingStreams } = committeeInfo[committee]
    const searchParams = {
      'RecordType.Name': {
        $in: recordTypes
      },
      StageName: { $in: afterValidatedStages }
    }
    if (fundingStreams) {
      searchParams.Funding_Stream__c = { $in: fundingStreams }
    }
    return getOpportunitiesParsed({
      searchParams,
      fields: [
        ...mapToFields(mapFields),
        'Funding_Stream__r.Id',
        'Funding_Stream__r.Name',
        'Contract_Signed_Date__c',
        'isvalidated__c'
      ],
      mapFields: {
        External_Approvals__r: 'votes',
        Contract_Signed_Date__c: 'contractSingedDate',
        isvalidated__c: 'validationDate',
        Funding_Stream__r: 'fundingStream'
      },
      permissions: {
        VOTE: true,
        HISTORIES: true,
        TEAM_MEMBERS: true
      }
    })
      .then(result => {
        console.log('validated applications for ' + committee, result)
        setData(result)
        setLoading(false)
      })
      .catch(reject => console.error(reject))
  }

  const parseData = list => {
    const renderInfo = committeeInfo[committee]
    const toReturn = {
      validated: [],
      recentlyAwarded: [],
      toValidate: [],
      denied: []
    }
    list
      .filter(item => {
        let voteObj
        if (item.votes) {
          item.votes.records.some(item => {
            if (item.OwnerId === user.userId) {
              voteObj = item
              return item.Result__c
            }
            return false
          })
        }
        return (
          !isFake(item.account?.name) &&
          !isFake(item.name) &&
          (!voteObj ||
            voteObj.Conflict_of_interest__c !== 'declared_conflict') &&
          item.teamMembers.some(
            member =>
              member.UserId === user.userId &&
              member.TeamMemberRole === 'Selection Committee Member'
          )
        )
      })
      .forEach((item, index) => {
        const { stageName, name, id, lastModifiedDate, votes, recordType } =
          item
        let voteObj
        if (votes) {
          votes.records.some(item => {
            if (item.OwnerId === user.userId) {
              voteObj = item
              return item.Result__c
            }
            return false
          })
        }
        const field = {}
        let declinedDate = null
        item.history.reverse().some(obj => {
          if (obj.stage === 'Declined') {
            declinedDate = moment.utc(obj.date)
            return true
          }
          return false
        })
        field.recommendedAmount = item.recommendedAmount
        field.reviewable =
          isDetailedValidatedStages(stageName) &&
          renderInfo.reviewStages.includes(stageName)
        field.stageName = stageName
        field.substage = item.externalValidationStatus
        field.name = name
        const fundingStream = fundingStreams.find(
          fs => fs.Id === item.fundingStream?.Id
        )
        field.fund =
          fundingStream?.translate?.[language] ||
          item.fundingStream?.Name ||
          recordType.name
        field.date = {
          lastModifiedDate,
          declinedDate
        }
        field.reviewed = false
        field.id = id
        field.vote = voteObj && voteObj.Result__c ? voteObj.Result__c : t`None`
        field.iconReviewed = field.reviewed ? 'check' : 'close'
        field.actions = {
          reviewable: field.reviewable,
          conflictOfInterest: voteObj?.Conflict_of_interest__c,
          conflictOfInterestData: {
            externalApprovalId: voteObj?.Id,
            applicationName: name,
            fund: recordType.name,
            partners: item.partners,
            account: item.account.name
          },
          stageName,
          name,
          substage: field.substage,
          id
        }
        if (validatedStages.includes(stageName)) {
          toReturn.validated.push(field)
        } else if (renderInfo.toValidateStages.includes(stageName)) {
          toReturn.toValidate.push(field)
        } else if (['Declined', 'Withdrawn'].includes(stageName)) {
          toReturn.denied.push(field)
        }
      })
    return toReturn
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  /** when data is loaded, parse it.
   * when language is changed, parse data again */
  useEffect(() => {
    if (data) {
      const list = parseData(data)
      setList(list)
    }
  }, [language, data])

  if (loading) {
    return <Loading />
  }

  const info = committeeInfo[committee]

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <>
        {['toValidate', 'denied', 'validated']
          .filter(key => info.showTables.includes(key))
          .map(key => {
            return (
              <>
                <div style={{ margin: 20 }} />
                <ApplicationTableForStage
                  key={key}
                  stage={key}
                  data={list[key]}
                  committee={committee}
                  reloadData={fetchData}
                />
              </>
            )
          })}
      </>
    </div>
  )
}

const ApplicationTableForStage = ({
  stage,
  data,
  committee,
  reloadData,
  ...props
}) => {
  const [conflictOfInterestDialogOpen, setConflictOfInterestDialogOpen] =
    useState(false)
  const [voting, setVoting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const history = useHistory()

  const declareConflictOfInterest = ({ externalApprovalId, id }) => {
    setVoting(true)
    saveConflictOfInterest({
      Id: externalApprovalId,
      Conflict_of_interest__c: 'declared_conflict',
      Result__c: 'conflict_of_interest',
      Opportunity__c: id,
      Approving_User__c: user.userId,
      Entity_Type__c: entityTypes[committee]
    }).then(
      result => {
        reloadData().then(result => {
          setConflictOfInterestDialogOpen(false)
          enqueueSnackbar(<Trans>Conflic of interest declared</Trans>, {
            variant: 'success'
          })
        })
      },
      reject => {
        console.log(reject)
        setVoting(false)
        enqueueSnackbar(
          <Trans>Error ocurred while declaring conflict of interest</Trans>,
          {
            variant: 'error'
          }
        )
      }
    )
  }

  const declareNoConflictOfInterest = ({ id, externalApprovalId }) => {
    setVoting(true)
    saveConflictOfInterest({
      Id: externalApprovalId,
      Conflict_of_interest__c: 'declared_no_conflict',
      Opportunity__c: id,
      Approving_User__c: user.userId,
      Entity_Type__c: entityTypes[committee]
    }).then(
      result => {
        history.push('/grants/ReviewApplications_' + committee + `/${id}`)
      },
      reject => {
        setVoting(false)
        enqueueSnackbar(
          <Trans>Error ocurred while declaring conflict of interest</Trans>,
          {
            variant: 'error'
          }
        )
      }
    )
  }

  const goToReview = ({ conflictOfInterest, id }) => {
    if (!conflictOfInterest && committee.includes('SC')) {
      setConflictOfInterestDialogOpen(id)
    } else {
      history.push('/grants/ReviewApplications_' + committee + `/${id}`)
    }
  }

  const columnsProps = [
    {
      name: 'name',
      label: myI18n._(t`Name`),
      options: {
        customHeadLabelRender: props => {
          return (
            <div style={{ paddingLeft: 20 }}>
              <Trans>Name</Trans>
            </div>
          )
        },
        customBodyRender: value => (
          <div style={{ paddingLeft: 20 }}>{value}</div>
        )
      }
    },
    {
      name: 'stageName',
      label: myI18n._(t`Stage`),
      options: {
        customHeadLabelRender: props => {
          return <Trans>Stage</Trans>
        },
        sortCompare:
          order =>
          ({ data: stage1 }, { data: stage2 }) => {
            const ret =
              opportunitiesStagesInOrder.indexOf(stage1) -
              opportunitiesStagesInOrder.indexOf(stage2)
            return order === 'asc' ? ret : ret * -1
          },
        customBodyRender: (
          value,
          { columnIndex, rowData, currentTableData, rowIndex }
        ) => {
          return (
            <div>
              <Trans id={value} />
              {value === 'Validated' && (
                <span>
                  -
                  <Trans id={rowData[rowData.length - 1].substage} />
                </span>
              )}
            </div>
          )
        }
      }
    },
    {
      name: 'fund',
      label: myI18n._(t`Fund`),
      options: {
        customHeadLabelRender: props => {
          return <Trans>Fund</Trans>
        },
        customBodyRender: value => {
          return <Trans id={value} />
        }
      }
    },
    {
      name: 'recommendedAmount',
      label: myI18n._(t`Recommended Amount`),
      options: {
        customHeadLabelRender: props => {
          return <Trans>Recommended Amount</Trans>
        },
        customBodyRender: value => {
          return <CurrencyFormated value={value} />
        }
      }
    },
    {
      name: 'actions',
      label: myI18n._(t`Review`),
      options: {
        sortCompare: order => {
          return (dataA, dataB) => {
            const a = dataA.data
            const b = dataB.data
            let ret
            if (a.reviewable && b.reviewable) {
              ret = a.name.localeCompare(b.name)
            } else if (a.reviewable) {
              ret = 1
            } else if (b.reviewable) {
              ret = -1
            } else {
              ret = a.name.localeCompare(b.name)
            }
            return order === 'desc' ? ret : ret * -1
          }
        },
        customHeadLabelRender: props => {
          return <Trans>Review</Trans>
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const { conflictOfInterest, conflictOfInterestData, id } = value
          const {
            externalApprovalId,
            applicationName,
            fund,
            partners,
            account
          } = conflictOfInterestData
          return (
            <Grid container justify='flex-end'>
              {!conflictOfInterest && (
                <Dialog
                  open={conflictOfInterestDialogOpen === id}
                  maxWidth='md'
                  fullWidth
                >
                  <DialogTitle>
                    <Grid
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Trans>Conflict of interest</Trans>
                      <IconButton
                        disabled={voting}
                        onClick={e => {
                          setConflictOfInterestDialogOpen(false)
                        }}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <div>
                      <div>
                        <span style={{ fontWeight: 500 }}>
                          <Trans>Project to vote on</Trans>:{' '}
                        </span>

                        {applicationName}
                      </div>
                      <div>
                        <span style={{ fontWeight: 500 }}>
                          <Trans>Fund</Trans>:{' '}
                        </span>
                        {fund}
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <div>
                        <span style={{ fontWeight: 500 }}>
                          <Trans>Organization's Legal Name</Trans>:{' '}
                        </span>
                        {account}
                      </div>
                      <div>
                        <span style={{ fontWeight: 500 }}>
                          <Trans>Project partners</Trans>:{' '}
                        </span>
                        {partners}
                      </div>
                    </div>

                    <Alert
                      severity='info'
                      variant='outlined'
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <AlertTitle>
                        <Trans>Conflict of interest</Trans>:
                      </AlertTitle>
                      <Trans>
                        A conflict of interest is normally associated with
                        improper financial gain, whether deliberately sought or
                        innocently arrived at. No person shall accept to vote on
                        a grant application if there is a material conflict of
                        interest between their role as selection committee
                        member and their role in any other capacity. If such a
                        situation arises, the person must eliminate the conflict
                        of interest or step down from their role as selection
                        committee member for the grant application in question.
                      </Trans>
                    </Alert>

                    <Alert severity='info' variant='outlined'>
                      <AlertTitle>
                        <Trans>Conflict of Loyalties</Trans>:
                      </AlertTitle>
                      <Trans>
                        A conflict of loyalties is present when a person owes a
                        duty or loyalty to two or more parties and cannot
                        reconcile those loyalties by identifying and serving the
                        common interests of the separate parties.
                      </Trans>
                    </Alert>

                    <Grid
                      container
                      wrap='nowrap'
                      justifyContent='space-evenly'
                      style={{ marginTop: 20 }}
                    >
                      <Grid item>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={voting}
                          onClick={e => {
                            declareNoConflictOfInterest({
                              id,
                              externalApprovalId
                            })
                          }}
                        >
                          <Trans>I declare no conflict of interest</Trans>
                        </Button>
                      </Grid>
                      <Grid item style={{ paddingLeft: 20 }}>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={voting}
                          onClick={e => {
                            declareConflictOfInterest({
                              externalApprovalId,
                              id
                            })
                          }}
                        >
                          <Trans>I declare conflict of interest</Trans>
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Dialog>
              )}
              {!value.reviewable && (
                <IconButton
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    goToReview({ conflictOfInterest, id })
                  }}
                >
                  <Icon>remove_red_eye</Icon>
                </IconButton>
              )}
              {value.reviewable && (
                <IconButton
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    goToReview({ conflictOfInterest, id })
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}
            </Grid>
          )
        }
      }
    }
  ]
  if (stage === 'denied') {
    columnsProps.splice(-1, 0, {
      name: 'date',
      label: myI18n._(t`Declined date`),
      options: {
        sortCompare: muiDateCompare('declinedDate'),
        customHeadLabelRender: props => {
          return <Trans>Declined date</Trans>
        },
        customBodyRender: value => {
          return moment(value.declinedDate).format(dateFormat)
        }
      }
    })
  }
  if (stage === 'toValidate') {
    columnsProps.splice(
      -1,
      0,
      {
        name: 'date',
        label: myI18n._(t`Last modified date`),
        options: {
          sortCompare: muiDateCompare('lastModifiedDate'),
          customHeadLabelRender: props => {
            return <Trans>Last modified date</Trans>
          },
          customBodyRender: value => {
            return moment(value.lastModifiedDate).format(dateFormat)
          }
        }
      },
      {
        name: 'vote',
        label: myI18n._(t`Registered vote`),
        options: {
          customHeadLabelRender: props => {
            return <Trans>Registered vote</Trans>
          },
          customBodyRender: value => {
            return <Trans id={value} />
          }
        }
      }
    )
  }

  let header = stageHeaders[stage]
  if (stageHeaders[stage + '_' + committee]) {
    header = stageHeaders[stage + '_' + committee]
  }

  return (
    <MUIDataTable
      title={
        <Typography variant='h5'>
          <b>{header}</b>
        </Typography>
      }
      data={data}
      options={{
        textLabels: muiTextLabels(myI18n),
        filter: false,
        selectableRows: 'none',
        print: false,
        download: false,
        viewColumns: false,
        sortOrder: stageSorting[stage]
      }}
      columns={columnsProps}
    />
  )
}
