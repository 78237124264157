import { Trans } from '@lingui/macro'
import { dateFormat } from 'app/appSettings'
import { Field } from 'formik'
import moment from 'moment'
import { languages } from 'translation/I18nConnectedProvider'
import { getMainConnected, getSFObjectFieldValue } from '../../Form'
import MUDatePicker from '../../multiuser/components/MUDatePicker'

/**
 * @typedef FormElementReference
 * @type {string}
 */

/**
 * Form element which renders a Material UI date picker.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.clearable=false] If date picker should render a clear button inside.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {Date} [typeProps.minDate] Minimum date that can be set in the input.
 * @param  {Date}  [typeProps.maxDate] Maximum date that can be set in the input.
 * @param  {FormElementReference} [typeProps.minDateElement] Another form element which value will be used as minimum date that can be set in the input.
 * @param  {FormElementReference}  [typeProps.maxDateElement] Another form element which value will be used as maximum date that can be set in the input.
 */
export const FormDatePicker = ({
  typeProps,
  title,
  id,
  useMultiuser,
  muBag,
  disabled,
  formikRef
}) => {
  return (
    <Field name={id}>
      {({ form: { setFieldValue, setFieldTouched, values }, field, meta }) => {
        const valueIsValid = moment.utc(field.value).isValid()
        const fieldValue = moment.utc(field.value)
        let {
          showFieldLabel,
          clearable,
          minDate,
          maxDate,
          minDateElement,
          maxDateElement,
          required
        } = typeProps
        const momentMaxDate = moment.utc(values[maxDateElement])
        const momentMinDate = moment.utc(values[minDateElement])

        if (minDateElement && values[minDateElement]) {
          minDate = momentMinDate
          if (valueIsValid && momentMinDate.isAfter(fieldValue)) {
            if (
              !(
                momentMinDate.isValid() && momentMaxDate.isBefore(momentMinDate)
              )
            ) {
              setFieldValue(id, momentMinDate)
            }
          }
        }
        if (maxDateElement && values[maxDateElement]) {
          maxDate = momentMaxDate
          if (valueIsValid && momentMaxDate.isBefore(fieldValue)) {
            if (
              !(momentMinDate.isValid() && momentMinDate.isAfter(momentMaxDate))
            ) {
              setFieldValue(id, momentMaxDate)
            }
          }
        }
        return (
          <MUDatePicker
            id={id}
            muBag={muBag}
            useMultiuser={useMultiuser}
            label={showFieldLabel && title}
            cancelLabel={<Trans>Cancel</Trans>}
            okLabel={<Trans>Ok</Trans>}
            format={dateFormat}
            disabled={disabled}
            className='text-muted'
            inputVariant='outlined'
            fullWidth
            clearable={Boolean(clearable)}
            minDate={minDate}
            maxDate={maxDate}
            formikRef={formikRef}
            helperText={required && <Trans>Required</Trans>}
          />
        )
      }}
    </Field>
  )
}

export const formDatePickerDefaultValue = (obj, info, item) => {
  if (!obj) {
    return null
  }
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField) {
    const sfValue = getSFObjectFieldValue(obj, connectedField)
    return sfValue ? sfValue : null
  } else {
    return null
  }
}

export const formDatePickerValueToText = value => {
  const toDate = moment(value)
  if (toDate.isValid()) {
    const dateObj = languages.reduce((acc, lang) => {
      acc[lang] = toDate.format(dateFormat)
      return acc
    }, {})
    return dateObj
  } else {
    const dateObj = languages.reduce((acc, lang) => {
      acc[lang] = value || ''
      return acc
    }, {})
    return dateObj
  }
}
