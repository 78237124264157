import { Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer"
import { Html } from "react-pdf-html"

const styles = StyleSheet.create({
	icon: {
		width: 15,
		height: 15,
	},
	descriptionText: {
		fontSize: 10,
		color: 'black',
	},
	description: {
		marginLeft: 5,
	},
	imgContainer: {
		width: 15,
		height: 15,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
})

export const FormBooleanPdf = ({
    value = [],
    title,
		...props
  }) => {

	return (
		<View style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
			{value && <View style={styles.imgContainer}>
				<Image
					src='/assets/images/pdf/selected-checkbox.jpg'
					style={styles.icon}
				/>
			</View>}
			{!value && <View style={styles.imgContainer}>
				<Image
					src='/assets/images/pdf/empty-checkbox.jpg'
					style={styles.icon}
				/>
			</View>}
			<View style={styles.description}>
				<Html style={styles.descriptionText}>{title || ''}</Html>
			</View>
		</View>
	)
}