
import { formComponentTypes } from '../../formComponentTypes'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { FormExcelFileImport } from './FormExcelFileImport'

export const FormEditorExcelFileImport = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps,
  injectable,
  injectableId,
  ...props
}) => {
  const _typeProps = typeProps || {}
  console.log({ _typeProps, id, showPrintProps, editMode, depth, injectable, injectableId, props })
  if (!editMode) {
    return (
      <FormExcelFileImport editMode id={id} typeProps={_typeProps} {...props} />
    )
  }

  return (
    <div>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={_typeProps}
        depth={depth}
        noField
        objectsConnections={
          formComponentTypes.excelFileImport.connectsToMultipleObjects
        }
      />
    </div>
  )
}