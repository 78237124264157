import { Grid } from "@material-ui/core"
import { getLabelFromTranslationData } from "app/views/common/TranslationsCommon"
import { isArray } from "lodash"
import { CensusDivisionPrint, geoData, scopeOptions } from "./FormCensusDivision"

export const getValuesForCensusDivisionPrint = (value, langVersion) => {
  value = value || {}
  /** convert provinces in selectedScope to readable format */
  const provinces = isArray(value.selectedScope)
    ? value.selectedScope
        .filter(item => item.includes('_province'))
        .map((scope, index, array) => (<>
          {getLabelFromTranslationData({
            data: geoData[scope]?.label,
            langVersion
          })}
          {index < array.length - 1 ? ', ' : ''}
        </>))
    : ''

  /** convert other geo objects in selectedScope to readable format */
  const selected = isArray(value.selectedScope)
    ? value.selectedScope
        .filter(item => !item.includes('_province'))
        .map((scope, index, array) => (<>
          {getLabelFromTranslationData({
            data: geoData[scope]?.label,
            langVersion
          })}
          {index < array.length - 1 ? ', ' : ''}
        </>))
    : ''

  /** convert value to object to pass to CensusDivisionPrint component */
  return {
    scopeType: scopeOptions.find(item => item.value === value.scopeType)?.label,
    muncipality: ['Neighbourhood', 'Municipal'].includes(value.scopeType)
      ? getLabelFromTranslationData({
          data: geoData[value.selectedScope]?.label,
          langVersion
        })
      : '',
    provinces: value.pilotPhase ? '' : provinces.length > 0 ? provinces : '',
    selected: value.pilotPhase ? '' : selected.length > 0 ? selected : '',
    pilotPhase: value.pilotPhase ? value.pilotPhase: '',
    pilotPhaseScopeType: value.pilotPhaseScopeType
      ? scopeOptions.find(item => item.value === value.pilotPhaseScopeType)
          ?.label
      : '',
    pilotPhaseMuncipality: ['Neighbourhood', 'Municipal'].includes(value.pilotPhaseScopeType)
      ? getLabelFromTranslationData({
          data: geoData[value.pilotPhaseScopeType]?.label,
          langVersion
        })
      : '',
    pilotPhaseProvinces: value.pilotPhase
      ? provinces.length > 0 ? provinces: ''
      : '',
    pilotPhaseSelected: value.pilotPhase
      ? selected.length > 0 ? selected: ''
      : '',
    impactsProvincialNorth: value.impactsProvincialNorth || ''
  }
}

/**
 * Component to render FormCenzusDivision element in print preview
 * @param {object} value - value of FormCenzusDivision element
 * @param {string} title - title of FormCenzusDivision element
 * @param {object} props - props of FormCenzusDivision element
 */
export const FormCensusDivisionPrint = ({
  value,
  title,
  langVersion,
  ...props
}) => {
  value = value || {}

  const census = getValuesForCensusDivisionPrint(value, langVersion)

  /** helper function to transform array element to element to display */
  const getTransformedElements = array => isArray(array)
  ? array.map((item, index) => (
      <Grid item key={index}>{item}</Grid>
    ))
  : ''

  census.provinces = getTransformedElements(census.provinces)
  census.selected = getTransformedElements(census.selected)
  census.pilotPhaseProvinces = getTransformedElements(census.pilotPhaseProvinces)
  census.pilotPhaseSelected = getTransformedElements(census.pilotPhaseSelected)

  return (
    <div style={{ flex: 1 }}>
      {title && <div className='form-print-title'>{title}</div>}
      <CensusDivisionPrint census={census} />
    </div>
  )
}