import { Trans, t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core'
import {
  createSubsurvey,
  createSurveyTemplate,
  duplicateSurveyTemplate,
  findTemplates
} from 'app/services/sfAuth/sfData/sfSurvey'
import MUIDataTable, { ExpandButton } from 'mui-datatables'
import { withSnackbar } from 'notistack'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getEmptyLangValues, hasEmptyValues } from 'utils'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import { getTitleTranslation } from '../common/TranslationsCommon'
import ConfigureMultilanguageTextField from '../internal/ConfigureMultilanguageTextField'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import ChangeSubtemplatesOrderDialogue from './ChangeSubtemplatesOrderDialogue'
import DeleteSurveyTemplateDialog from './DeleteSurveyTemplateDialog'
import { ExportImportSurveysTranslationsPanel } from './ExportImportSurveysTranslations'
import PrintableReadyToFillSurvey from './PrintableReadyToFillSurvey'

export const muiTableOverrides = {
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        justifyContent: 'flex-end'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 0
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        zIndex: 0
      },
      fixedLeft: {
        zIndex: 0
      }
    }
  }
}

class SurveyTemplates extends React.Component {
  constructor (props) {
    super(props)
    this.panelsOpened = []
    this.fetchData = this.fetchData.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.openWarningDialog = this.openWarningDialog.bind(this)
    Promise.resolve(this.fetchData())
  }

  fetchData () {
    return findTemplates().then(result => {
      let toSet = {}
      console.log('found templates', result)
      result.forEach(result => {
        const template = result.template
        if (template.Survey_Template_Joins_Survey_Template1__r === null) {
          toSet[template.Id] = {
            id: template.Id,
            translations: result.titleTranslation,
            name: getTitleTranslation(
              this.props.user.language,
              result.titleTranslation
            ),
            children: []
          }
        }
      })
      result.forEach(result => {
        const template = result.template
        if (template.Survey_Template_Joins_Survey_Template1__r !== null) {
          const join =
            template.Survey_Template_Joins_Survey_Template1__r.records[0]
          const order = join.Order__c
          toSet[join.Parent_Template__c].children.push({
            id: template.Id,
            order,
            join: join,
            translations: result.titleTranslation,
            name: getTitleTranslation(
              this.props.user.language,
              result.titleTranslation
            )
          })
        }
      })
      console.log('template list orig', toSet)
      this.setState({ loaded: true, list: this.parseDataObj(toSet) })
    })
  }

  state = {
    loaded: false,
    creatingDialogOpen: false,
    warningDialogOpen: false,
    creatingSurveyOfType: '',
    dialogSurvey: null,
    dialogChildrenIds: null,
    list: null
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.user.language !== nextProps.user.language) {
      const lang = nextProps.user.language
      const newList = [...this.state.list]
      newList.forEach(obj => {
        obj.name = getTitleTranslation(lang, obj.translations) || ''
        if (obj.children) {
          obj.children.forEach(child => {
            child.name = getTitleTranslation(lang, child.translations) || ''
          })
        }
        obj.name = getTitleTranslation(lang, obj.translations) || ''
      })
      this.setState({
        ...nextState,
        list: newList
      })
      return false
    }
    return true
  }

  parseDataObj (loadedObj) {
    const toReturn = []
    for (const parentKey in loadedObj) {
      const field = {}
      field.id = loadedObj[parentKey].id
      field.translations = loadedObj[parentKey].translations
      field.name = loadedObj[parentKey].name
      field.children = loadedObj[parentKey].children
      field.actions = loadedObj[parentKey].id
      field.index = toReturn.length
      field.children.sort((a, b) => a.order - b.order)
      toReturn.push(field)
    }
    console.log('template list', toReturn)
    return toReturn
  }

  editTemplate (id, history) {
    history.push(`/surveys/CreateSurvey/${id}`, { id: id })
  }

  openWarningDialog () {
    this.setState({
      warningDialogOpen: true
    })
  }

  updateDialogsOpen (rowIndex, added = false) {
    const indexOf = this.panelsOpened.indexOf(rowIndex)
    if (indexOf != -1 && !added) {
      this.panelsOpened.splice(indexOf, 1)
    }
    this.panelsOpened.forEach(num => {
      let newValue = num
      if (!added && num > rowIndex) {
        newValue -= 1
      } else if (added && num >= rowIndex) {
        newValue += 1
      }
      this.panelsOpened[this.panelsOpened.indexOf(num)] = newValue
    })
    console.log('NOW OPENED', this.panelsOpened)
    this.forceUpdate()
  }

  closeDialog () {
    this.setState({
      creatingDialogOpen: false,
      warningDialogOpen: false,
      creatingSurveyOfType: '',
      dialogChildrenIds: null,
      dialogSurvey: null,
      titleValue: getEmptyLangValues()
    })
  }

  render () {
    const data = this.state.list
    const { titleValue = getEmptyLangValues(), loaded } = this.state

    if (!loaded) {
      return <Loading />
    }

    return (
      <>
        <Dialog
          open={this.state.creatingDialogOpen}
          onClose={this.closeDialog}
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle>
            <Trans>Create survey template</Trans>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Trans>
                Enter the name of a survey template you want to create
              </Trans>
            </DialogContentText>
            <ConfigureMultilanguageTextField
              value={titleValue}
              handleChange={e => {
                this.setState({
                  titleValue: e
                })
              }}
              maxCharacters={80}
              autoFocus
              label={<Trans>Template Name</Trans>}
            />
          </DialogContent>

          <DialogActions>
            <Grid container direction='row' justify='space-evenly'>
              <Button
                variant='contained'
                color='primary'
                disabled={hasEmptyValues(titleValue, ['en', 'fr'])}
                onClick={() => {
                  const creatingSnackbar = this.props.enqueueSnackbar(null, {
                    variant: 'info',
                    persist: true,
                    content: key =>
                      ProgressSnackbar(
                        <Trans>Creating new survey template</Trans>
                      )
                  })
                  if (this.state.creatingSurveyOfType === 'child') {
                    createSubsurvey(
                      this.state.dialogSurvey.id,
                      titleValue,
                      this.state.dialogSurvey.children.length
                    ).then(result => {
                      this.fetchData().then(r => {
                        this.props.closeSnackbar(creatingSnackbar)
                        this.props.enqueueSnackbar(
                          <Trans>Survey template created!</Trans>,
                          {
                            variant: 'info'
                          }
                        )
                      })
                    })
                  } else {
                    createSurveyTemplate(titleValue).then(result => {
                      this.fetchData().then(r => {
                        const newData = this.parseDataObj(this.state.list)
                        let addedindex = 0
                        newData.forEach((item, index) => {
                          if (item.actions === result.id) {
                            addedindex = index
                            this.updateDialogsOpen(addedindex, true)
                          }
                        })
                        this.props.closeSnackbar(creatingSnackbar)
                        this.props.enqueueSnackbar(
                          <Trans>Survey template created!</Trans>,
                          {
                            variant: 'info'
                          }
                        )
                      })
                    })
                  }
                  this.closeDialog()
                }}
              >
                <Trans>Create</Trans>
              </Button>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginBottom: 10
                }}
                onClick={this.closeDialog}
              >
                <Trans>Cancel</Trans>
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>

        <ChangeSubtemplatesOrderDialogue
          template={this.state.dialogSurvey}
          open={this.state.changeOrderDialogueOpen}
          saveRearrangment={(index, toSave) => {
            const modified = [...this.state.list]
            modified[index].children = toSave
            this.setState({
              list: modified
            })
          }}
          closeDialog={() => {
            this.setState({
              changeOrderDialogueOpen: false,
              dialogSurvey: null
            })
          }}
        />

        <DeleteSurveyTemplateDialog
          closeDialog={this.closeDialog}
          template={this.state.dialogSurvey}
          dialogChildrenIds={this.state.dialogChildrenIds}
          updateDialogsOpen={index => {
            this.updateDialogsOpen(index)
          }}
          fetchData={this.fetchData}
          warningDialogOpen={this.state.warningDialogOpen}
        />

        <div
          style={{
            paddingLeft: 15,
            marginTop: 20,
            paddingRight: 15,
            marginBottom: 20
          }}
        >
          <Grid
            container
            style={{ paddingBottom: 10 }}
            justifyContent='flex-end'
          >
            <ExportImportSurveysTranslationsPanel templates={data} />
          </Grid>

          <MUIDataTable
            title={
              <Grid container direction='row' alignItems='center'>
                <h1 style={{ margin: 10 }}>
                  <Trans>Survey Templates</Trans>
                </h1>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={e => {
                    this.setState({
                      creatingDialogOpen: true,
                      creatingSurveyOfType: 'parent'
                    })
                  }}
                >
                  <Grid container direction='row' alignItems='center'>
                    <Trans>Add new template</Trans>
                    <Icon style={{ marginLeft: 5 }}>add</Icon>
                  </Grid>
                </Button>
              </Grid>
            }
            columns={[
              {
                name: 'name',
                label: myI18n._(t`Template Name`),
                options: {
                  customHeadLabelRender: props => myI18n._(t`Template Name`),
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{ flex: 1 }}>{value}</div>
                  }
                }
              },
              {
                name: 'id',
                label: myI18n._(t`Id`),
                options: {
                  customHeadLabelRender: props => myI18n._(t`Id`),
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{ flex: 1 }}>{value}</div>
                  }
                }
              },
              {
                name: 'actions',
                options: {
                  sort: false,
                  customHeadLabelRender: props => {
                    return null
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    const id = value
                    const rowId = tableMeta.rowData[1]
                    const surveyData = data.find(obj => obj.id === rowId)
                    return (
                      <Grid
                        key={id}
                        container
                        direction='row'
                        justify='flex-end'
                      >
                        <Tooltip title={<Trans>Edit survey template</Trans>}>
                          <IconButton
                            onClick={e =>
                              this.props.history.push(
                                `/surveys/CreateSurvey/${id}/${'parent'}`,
                                { id: id }
                              )
                            }
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        {surveyData && surveyData.children?.length > 0 && (
                          <Tooltip
                            title={<Trans>Edit subtemplates order</Trans>}
                          >
                            <IconButton
                              onClick={e => {
                                this.setState({
                                  dialogSurvey: surveyData,
                                  changeOrderDialogueOpen: true
                                })
                              }}
                            >
                              <Icon>
                                <span className='material-icons'>
                                  swap_vertical_circle
                                </span>
                              </Icon>
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip
                          title={<Trans>Duplicate survey template</Trans>}
                        >
                          <IconButton
                            disabled={this.state.duplicating}
                            onClick={e => {
                              this.setState({
                                duplicating: true
                              })
                              const duplicatingSnackbar =
                                this.props.enqueueSnackbar(null, {
                                  variant: 'info',
                                  persist: true,
                                  content: key =>
                                    ProgressSnackbar(
                                      <Trans>Duplicating template</Trans>
                                    )
                                })
                              duplicateSurveyTemplate(surveyData).then(
                                result => {
                                  this.fetchData().then(
                                    result => {
                                      this.props.closeSnackbar(
                                        duplicatingSnackbar
                                      )
                                      this.props.enqueueSnackbar(
                                        <Trans>Template duplicated</Trans>,
                                        {
                                          variant: 'success'
                                        }
                                      )
                                      this.setState({
                                        duplicating: false
                                      })
                                    },
                                    reject => {
                                      this.props.enqueueSnackbar(
                                        <Trans>
                                          Error ocurred while duplicating
                                          template
                                        </Trans>,
                                        {
                                          variant: 'error'
                                        }
                                      )
                                      this.props.closeSnackbar(
                                        duplicatingSnackbar
                                      )
                                      this.setState({
                                        duplicating: false
                                      })
                                    }
                                  )
                                }
                              )
                            }}
                          >
                            <Icon>content_copy</Icon>
                          </IconButton>
                        </Tooltip>
                        <PrintableReadyToFillSurvey data={surveyData} />
                        <Tooltip title={<Trans>Create subsurvey</Trans>}>
                          <IconButton
                            onClick={e => {
                              this.setState({
                                creatingDialogOpen: true,
                                creatingSurveyOfType: 'child',
                                dialogSurvey: surveyData
                              })
                            }}
                          >
                            <Icon>add</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={<Trans>Delete survey template</Trans>}>
                          <IconButton
                            onClick={e => {
                              let childrenIds = []
                              surveyData.children.forEach(child =>
                                childrenIds.push(child.id)
                              )
                              if (childrenIds.length === 0) {
                                childrenIds = null
                              }
                              this.setState({
                                warningDialogOpen: true,
                                dialogSurvey: surveyData,
                                dialogChildrenIds: childrenIds
                              })
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )
                  }
                }
              }
            ]}
            data={data}
            components={{
              ExpandButton: props => {
                if (
                  props.dataIndex === undefined ||
                  props.dataIndex >= data.length
                ) {
                  return <div style={{ width: '24px' }} />
                }
                if (data[props.dataIndex].children.length > 0) {
                  return (
                    <ExpandButton
                      {...props}
                      style={{ margin: 20 }}
                      onExpand={() => {
                        if (!this.panelsOpened.includes(props.dataIndex)) {
                          this.panelsOpened.push(props.dataIndex)
                          console.log('NOW OPENED', this.panelsOpened)
                        } else {
                          this.panelsOpened.splice(
                            this.panelsOpened.indexOf(props.dataIndex),
                            1
                          )
                          console.log('NOW OPENED', this.panelsOpened)
                        }
                        props.onExpand()
                      }}
                    />
                  )
                }
                return <div style={{ width: '24px' }} />
              }
            }}
            options={{
              expandableRows: true,
              filter: false,
              sortOrder: { name: 'name', direction: 'asc' },
              selectableRows: 'none',
              print: false,
              download: false,
              viewColumns: false,
              rowsExpanded: this.panelsOpened,
              renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1
                if (!this.state.list[rowMeta.dataIndex]) {
                  return null
                }
                return this.state.list[rowMeta.dataIndex].children.map(
                  (sub, index) => {
                    const id = sub.id
                    return (
                      <TableRow
                        key={index}
                        style={{ backgroundColor: '#dddddd' }}
                      >
                        <TableCell colSpan={colSpan}>
                          <div
                            style={{
                              marginLeft: 20
                            }}
                          >
                            <Grid container direction='row' alignItems='center'>
                              <div style={{ flex: 1, textAlign: 'center' }}>
                                {sub.name} {sub.id}
                              </div>
                              <Tooltip
                                title={<Trans>Edit survey template</Trans>}
                              >
                                <IconButton
                                  onClick={e => {
                                    this.props.history.push(
                                      `/surveys/CreateSurvey/${id}/${'sub'}`
                                    )
                                  }}
                                >
                                  <Icon>edit</Icon>
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={<Trans>Delete survey template</Trans>}
                              >
                                <IconButton
                                  onClick={e => {
                                    this.setState({
                                      warningDialogOpen: true,
                                      dialogSurvey: sub,
                                      dialogChildrenIds: null
                                    })
                                  }}
                                >
                                  <Icon>delete</Icon>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )
              }
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default withRouter(
  connect(mapStateToProps, null, null, { forwardRef: true })(
    withSnackbar(SurveyTemplates)
  )
)
