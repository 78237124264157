import { t, Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  checkContactByFlow,
  createContactByFlow,
  mapContact,
  saveContactByFlow
} from 'app/services/sfAuth/sfData/sfContact'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik, useFormikContext } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import {
  parseExtensionPhoneToSF,
  PhoneCountryCodeFormat,
  PhoneFormatWithExtension
} from '../common/Formats'
import { requiredTrans } from '../forms/formTranslations'
import { countHelperText } from '../page-layouts/FormElement'

const FindContactButton = ({
  onSelect,
  onDialogClose,
  onDialogOpen,
  disabled,
  isContactFound,
  useWorkEmailInNewContact = false,
  allowCreatingNewContact,
  preview,
  roles,
  allowContactUpdate = true,
  currentOrganizationAsDefault
}) => {
  const [dialogOpen, openDialog] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searchResult, setSearchResult] = useState(null)
  const [creating, setCreating] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  const [role, selectRole] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const organization = useSelector(state => state.organization)
  const [defaultOrganization, setDefaultOrganization] = useState('')

  useEffect(() => {
    setSearching(false)
    setSearchResult(null)
    if (!dialogOpen) {
      selectRole(null)
      setDefaultOrganization('')
    } else if (currentOrganizationAsDefault) {
      setDefaultOrganization(organization.organisationsName)
    }
  }, [dialogOpen])

  const askForRelationship = Boolean(role && role.askForRelationship)

  return (
    <>
      <Dialog open={dialogOpen} maxWidth='md' fullWidth>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Trans>Find contact</Trans>
            <IconButton
              onClick={e => {
                openDialog(false)
                if (onDialogClose) {
                  onDialogClose(e)
                }
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          {role && (
            <div style={{ fontWeight: 350, fontSize: 17 }}>{role.label}</div>
          )}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              relationship: '',
              firstName: '',
              newPhoneNumber: '',
              newPhoneNumberCountryCode: '1',
              newJobTitle: '',
              lastName: '',
              email: '',
              newFirstName: '',
              newLastName: '',
              newEmail: '',
              newOrganization: defaultOrganization || ''
            }}
            initialTouched={{
              relationship: true
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              newFirstName: Yup.string().required(requiredTrans),
              newLastName: Yup.string().required(requiredTrans),
              newEmail: Yup.string()
                .required(requiredTrans)
                .email(<Trans>This must be a valid email</Trans>),
              newOrganization: Yup.string().required(requiredTrans),
              relationship: askForRelationship
                ? Yup.string().required(requiredTrans)
                : Yup.string(),
              newPhoneNumber: Yup.string()
                .required(requiredTrans)
                .test(
                  'isValidPhone',
                  () => (
                    <Trans>Phone Number must have at least 10 digits</Trans>
                  ),
                  value => {
                    if (value) {
                      const filtered = value.replace(/[^0-9]/g, '')
                      return filtered.length >= 10
                    } else {
                      return true
                    }
                  }
                ),
              newJobTitle: Yup.string().required(requiredTrans)
            })}
          >
            {({ values, isValid, setValues }) => {
              const {
                firstName,
                relationship,
                lastName,
                email,
                newFirstName,
                newLastName,
                newEmail,
                newPhoneNumber,
                newPhoneNumberCountryCode,
                newJobTitle,
                newOrganization
              } = values
              const nameProvided = Boolean(firstName && lastName)
              return (
                <Grid container direction='column'>
                  <div
                    style={{
                      padding: 8,
                      marginBottom: 5,
                      fontWeight: 'bold',
                      fontSize: 15
                    }}
                  >
                    <Trans>Search for contact</Trans>
                  </div>
                  <FormikTextField
                    variant='outlined'
                    label={<Trans>First Name</Trans>}
                    name='firstName'
                    style={{ marginBottom: 10 }}
                  />
                  <FormikTextField
                    variant='outlined'
                    label={<Trans>Last name</Trans>}
                    name='lastName'
                    style={{ marginBottom: 10 }}
                  />
                  <Button
                    disabled={!nameProvided || searching || creating}
                    fullWidth
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setSearching(true)
                      setSearchResult(null)
                      checkContactByFlow({
                        firstName: firstName.trim(),
                        lastName: lastName.trim()
                      }).then(flow => {
                        setSearching(false)
                        const result = flow[0].outputValues
                        if (result.error) {
                          setSearchResult(result.error)
                        } else {
                          setSearchResult(result.contactsFound)
                        }
                      })
                    }}
                  >
                    <Trans>Search</Trans>
                  </Button>
                  <Typography style={{ marginBottom: 10, textAlign: 'center' }}>
                    <Trans>Or</Trans>
                  </Typography>
                  <FormikTextField
                    variant='outlined'
                    label={<Trans>E-mail</Trans>}
                    name='email'
                    style={{ marginBottom: 10 }}
                  />
                  <Button
                    disabled={!email || searching || creating}
                    fullWidth
                    style={{ marginBottom: 10 }}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setSearching(true)
                      setSearchResult(null)
                      checkContactByFlow({ email: email.trim() }).then(flow => {
                        setSearching(false)
                        const result = flow[0].outputValues
                        if (result.error) {
                          setSearchResult(result.error)
                        } else {
                          setSearchResult(result.contactsFound)
                        }
                      })
                    }}
                  >
                    <Trans>Search</Trans>
                  </Button>
                  {searching && (
                    <div style={{ marginTop: 15, height: 100 }}>
                      <Loading isNotFixed />
                    </div>
                  )}
                  {searchResult && (
                    <div>
                      {typeof searchResult === 'string' && (
                        <div>
                          {searchResult === 'MULTIPLE_MATCHES' && (
                            <Typography
                              style={{
                                color: 'red',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                marginTop: 10
                              }}
                            >
                              <Trans>
                                Too many results found! Try to be more specific
                                in your search
                              </Trans>
                            </Typography>
                          )}
                          {searchResult === 'NOT_FOUND' && (
                            <div>
                              <Typography
                                style={{
                                  color: 'red',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                  marginTop: 10
                                }}
                              >
                                <Trans>
                                  No contact was found! Try to search by
                                  different parameters
                                </Trans>
                              </Typography>
                              {allowCreatingNewContact && (
                                <div style={{ marginTop: 20 }}>
                                  <Trans>
                                    Or maybe you want to create a new contact?{' '}
                                  </Trans>
                                  <Grid
                                    container
                                    wrap='nowrap'
                                    style={{ marginBottom: 10, marginTop: 10 }}
                                  >
                                    <Grid xs item style={{ paddingRight: 5 }}>
                                      <FormikTextField
                                        disabled={creating}
                                        variant='outlined'
                                        label={<Trans>First Name</Trans>}
                                        name='newFirstName'
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid xs item style={{ paddingLeft: 5 }}>
                                      <FormikTextField
                                        disabled={creating}
                                        variant='outlined'
                                        label={<Trans>Last Name</Trans>}
                                        name='newLastName'
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <FormikTextField
                                    disabled={creating}
                                    variant='outlined'
                                    label={<Trans>E-mail</Trans>}
                                    name='newEmail'
                                    style={{ marginBottom: 10 }}
                                    fullWidth
                                  />
                                  <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item>
                                      <FormikTextField
                                        disabled={creating}
                                        variant='outlined'
                                        name='newPhoneNumberCountryCode'
                                        InputProps={{
                                          inputComponent: PhoneCountryCodeFormat
                                        }}
                                        inputProps={{
                                          maxLength: 5
                                        }}
                                        style={{ width: 65 }}
                                      />
                                    </Grid>

                                    <Grid item xs>
                                      <FormikTextField
                                        disabled={creating}
                                        variant='outlined'
                                        label={<Trans>Phone number</Trans>}
                                        name='newPhoneNumber'
                                        InputProps={{
                                          inputComponent:
                                            PhoneFormatWithExtension
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>

                                  <FormikTextField
                                    disabled={creating}
                                    variant='outlined'
                                    label={<Trans>Organization</Trans>}
                                    name='newOrganization'
                                    style={{ marginBottom: 10 }}
                                    fullWidth
                                  />
                                  <FormikTextField
                                    disabled={creating}
                                    variant='outlined'
                                    label={<Trans>Job title</Trans>}
                                    name='newJobTitle'
                                    style={{ marginBottom: 10 }}
                                    inputProps={{
                                      maxLength: 80
                                    }}
                                    helperText={
                                      <span key='limit'>
                                        {countHelperText(newJobTitle, 80)}
                                        {'. '}
                                      </span>
                                    }
                                    fullWidth
                                  />
                                  {askForRelationship && (
                                    <FormikTextField
                                      fullWidth
                                      variant='outlined'
                                      label={
                                        <Trans>Relationship to provider</Trans>
                                      }
                                      name='relationship'
                                      style={{
                                        marginBottom: 10
                                      }}
                                      inputProps={{ maxLength: 255 }}
                                      helperText={countHelperText(
                                        relationship,
                                        255
                                      )}
                                    />
                                  )}
                                  <Button
                                    fullWidth
                                    disabled={!isValid || creating || preview}
                                    variant='contained'
                                    color='primary'
                                    onClick={e => {
                                      setCreating(true)
                                      const creatingSnackbar = enqueueSnackbar(
                                        <Trans>Creating new contact</Trans>,
                                        { variant: 'info', persist: true }
                                      )
                                      const toCreate = {
                                        title: newJobTitle,
                                        firstName: newFirstName,
                                        lastName: newLastName,
                                        phone: parseExtensionPhoneToSF(
                                          newPhoneNumber,
                                          newPhoneNumberCountryCode
                                        ),
                                        providedOrganizationName:
                                          newOrganization
                                      }
                                      if (useWorkEmailInNewContact) {
                                        toCreate.workEmail = newEmail
                                      } else {
                                        toCreate.email = newEmail
                                      }
                                      createContactByFlow(toCreate).then(
                                        result => {
                                          closeSnackbar(creatingSnackbar)
                                          setCreating(false)
                                          if (result.error) {
                                            let errorTrans = (
                                              <Trans>
                                                Contact with this email already
                                                exists!
                                              </Trans>
                                            )
                                            if (
                                              result.error ===
                                              'NAME_ALREADY_IN_USE'
                                            ) {
                                              errorTrans = (
                                                <Trans>
                                                  Contact with this name already
                                                  exists!
                                                </Trans>
                                              )
                                            }
                                            enqueueSnackbar(errorTrans, {
                                              variant: 'error'
                                            })
                                          } else {
                                            enqueueSnackbar(
                                              <Trans>Contact created!</Trans>,
                                              {
                                                variant: 'success'
                                              }
                                            )
                                            if (onSelect) {
                                              onSelect({
                                                Email: newEmail,
                                                FirstName: newFirstName,
                                                LastName: newLastName,
                                                Name:
                                                  newFirstName +
                                                  ' ' +
                                                  newLastName,
                                                Id: result.newContactId,
                                                Relationship__c: relationship,
                                                Role__c: role && role.value,
                                                Title: newJobTitle,
                                                Phone: parseExtensionPhoneToSF(
                                                  newPhoneNumber,
                                                  newPhoneNumberCountryCode
                                                )
                                              })
                                            }
                                            openDialog(false)
                                          }
                                        },
                                        reject => {
                                          console.log(reject)
                                          closeSnackbar(creatingSnackbar)
                                          setCreating(false)
                                          enqueueSnackbar(
                                            <Trans>
                                              Error occurred while creating new
                                              contact!
                                            </Trans>,
                                            {
                                              variant: 'error'
                                            }
                                          )
                                        }
                                      )
                                    }}
                                  >
                                    <Trans>Create new contact</Trans>
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {Boolean(typeof searchResult === 'object') && (
                        <>
                          {searchResult.map((contact, index) => {
                            const {
                              Title,
                              Phone,
                              npe01__WorkPhone__c,
                              MobilePhone
                            } = contact
                            const missingInfo =
                              !Title ||
                              (!Phone && !npe01__WorkPhone__c && !MobilePhone)
                            return (
                              <Card
                                key={index}
                                elevation={6}
                                style={{
                                  marginTop: 15,
                                  marginBottom: 15,
                                  padding: 15
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  wrap='nowrap'
                                  alignItems='center'
                                >
                                  <Grid
                                    item
                                    container
                                    direction='column'
                                    style={{ flex: 1 }}
                                  >
                                    <div style={{ marginBottom: 5 }}>
                                      <b>
                                        <Trans>First Name</Trans>
                                      </b>
                                      {': ' + contact.FirstName}
                                    </div>
                                    <div style={{ marginBottom: 5 }}>
                                      <b>
                                        <Trans>Last name</Trans>
                                      </b>
                                      {': ' + contact.LastName}
                                    </div>
                                    <div style={{ marginBottom: 5 }}>
                                      <b>
                                        <Trans>E-mail</Trans>
                                      </b>
                                      {': ' + contact.Email}
                                    </div>
                                  </Grid>
                                  <div>
                                    <Button
                                      color='primary'
                                      variant='contained'
                                      disabled={
                                        (askForRelationship && !relationship) ||
                                        missingInfo
                                      }
                                      onClick={() => {
                                        if (onSelect) {
                                          onSelect({
                                            ...contact,
                                            Relationship__c: relationship,
                                            Role__c: role && role.value
                                          })
                                        }
                                        openDialog(false)
                                      }}
                                    >
                                      <Trans>Select</Trans>
                                    </Button>
                                  </div>
                                </Grid>
                                {Boolean(missingInfo && allowContactUpdate) && (
                                  <FillMissingInformationForContact
                                    contactId={contact.Id}
                                    phone={Phone}
                                    workPhone={npe01__WorkPhone__c}
                                    mobilePhone={MobilePhone}
                                    jobTitle={Title}
                                    handleChange={value => {
                                      const newResult = [...searchResult]
                                      newResult[index] = {
                                        ...newResult[index],
                                        ...value
                                      }
                                      setSearchResult(newResult)
                                    }}
                                  />
                                )}
                              </Card>
                            )
                          })}
                          {askForRelationship && (
                            <FormikTextField
                              fullWidth
                              variant='outlined'
                              label={<Trans>Relationship to provider</Trans>}
                              name='relationship'
                              style={{ marginBottom: 10, marginTop: 10 }}
                              inputProps={{ maxLength: 255 }}
                              helperText={countHelperText(relationship, 255)}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Grid>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Button
        disabled={disabled}
        color='primary'
        variant='contained'
        onClick={e => {
          if (!roles) {
            openDialog(true)
            if (onDialogOpen) {
              onDialogOpen(e)
            }
          } else {
            setAnchorEl(e.currentTarget)
          }
        }}
      >
        {isContactFound ? (
          <Trans>Edit contact</Trans>
        ) : (
          <Trans>Find contact</Trans>
        )}
      </Button>
      {Array.isArray(roles) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {roles
            .filter(item => item.label)
            .map((item, index) => {
              const numLabel = item.current + '/' + item.limit
              return (
                <MenuItem
                  disabled={+item.limit === 0 || +item.current >= +item.limit}
                  key={index}
                  onClick={e => {
                    setAnchorEl(null)
                    openDialog(true)
                    if (onDialogOpen) {
                      onDialogOpen(e)
                    }
                    selectRole({
                      value: item.value,
                      label: item.label,
                      askForRelationship: item.askForRelationship
                    })
                    if (item.defaultOrganization === 'current') {
                      setDefaultOrganization(organization.organisationsName)
                    }
                  }}
                >
                  {item.label + ' ' + numLabel}
                  <span
                    style={{ color: item.error && '#f5543b', marginLeft: 4 }}
                  >
                    {'(' + myI18n._(t`Min`) + ': ' + item.min + ')'}
                  </span>
                </MenuItem>
              )
            })}
        </Menu>
      )}
    </>
  )
}

export const FillMissingInformationForContact = ({
  phone,
  jobTitle,
  contactId,
  handleChange,
  workPhone,
  mobilePhone,
  onContactUpdate
}) => {
  const { values, setValues } = useFormikContext()
  const {
    updatePhone,
    updateWorkPhone,
    updateMobilePhone,
    updateTitle,
    updatePhoneCountryCode,
    updateWorkPhoneCountryCode,
    updateMobilePhoneCountryCode
  } = values
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setValues({
      ...values,
      updatePhone: '',
      updateWorkPhone: '',
      updateMobilePhone: '',
      updatePhoneCountryCode: '1',
      updateWorkPhoneCountryCode: '1',
      updateMobilePhoneCountryCode: '1',
      updateTitle: ''
    })
  }, [dialogOpen])

  const invalidPhone = !phone && (!updatePhone || updatePhone.length < 10)
  const invalidWorkPhone =
    !workPhone & (!updateWorkPhone || updateWorkPhone.length < 10)
  const invalidMobilePhone =
    !mobilePhone && (!updateMobilePhone || updateMobilePhone.length < 10)
  const noPhone = !phone && !workPhone && !mobilePhone
  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Trans>Fill out missing information</Trans>
            <IconButton
              disabled={saving}
              style={{ marginLeft: 10 }}
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!jobTitle && (
            <div style={{ padding: 5 }}>
              <FormikTextField
                label={<Trans>Job title</Trans>}
                name='updateTitle'
                helperText={countHelperText(updateTitle, 128, true)}
                inputProps={{
                  maxLength: 128
                }}
                variant='outlined'
                fullWidth
              />
            </div>
          )}

          {noPhone &&
            [
              {
                label: <Trans>Phone</Trans>,
                id: 'updatePhone',
                key: phone
              },
              {
                label: <Trans>Work Phone</Trans>,
                id: 'updateWorkPhone',
                key: workPhone
              },
              {
                label: <Trans>Mobile Phone</Trans>,
                id: 'updateMobilePhone',
                key: mobilePhone
              }
            ].map((obj, index) => {
              return (
                !obj.key && (
                  <Grid container style={{ padding: 5 }} key={index}>
                    <Grid item>
                      <FormikTextField
                        name={obj.id + 'CountryCode'}
                        inputProps={{
                          maxLength: 5
                        }}
                        style={{ width: 65 }}
                        InputProps={{
                          inputComponent: PhoneCountryCodeFormat
                        }}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs>
                      <FormikTextField
                        label={obj.label}
                        name={obj.id}
                        InputProps={{
                          inputComponent: PhoneFormatWithExtension
                        }}
                        helperText={
                          <span>
                            <Trans>
                              Phone Number must have at least 10 digits
                            </Trans>
                            {'. '}
                            <Trans>At least one phone number is required</Trans>
                          </span>
                        }
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )
              )
            })}

          <Grid container justifyContent='center' style={{ marginTop: 10 }}>
            <Button
              variant='contained'
              color='primary'
              disabled={
                (invalidMobilePhone && invalidWorkPhone && invalidPhone) ||
                saving ||
                (!jobTitle && !updateTitle)
              }
              onClick={e => {
                setSaving(true)
                const toSave = {
                  Id: contactId
                }
                if (updateTitle) {
                  toSave.Title = updateTitle
                }
                if (updatePhone) {
                  toSave.Phone = parseExtensionPhoneToSF(
                    updatePhone,
                    updatePhoneCountryCode
                  )
                }
                if (updateWorkPhone) {
                  toSave.npe01__WorkPhone__c = parseExtensionPhoneToSF(
                    updateWorkPhone,
                    updateWorkPhoneCountryCode
                  )
                }
                if (updateMobilePhone) {
                  toSave.MobilePhone = parseExtensionPhoneToSF(
                    updateMobilePhone,
                    updateMobilePhoneCountryCode
                  )
                }
                saveContactByFlow(toSave).then(result => {
                  setSaving(false)
                  if (onContactUpdate) {
                    onContactUpdate({
                      ...mapContact(toSave, true),
                      id: contactId
                    })
                  }
                  setDialogOpen(false)
                  if (result.isSuccess) {
                    enqueueSnackbar(<Trans>Contact saved</Trans>, {
                      variant: 'success'
                    })
                    handleChange(toSave)
                  } else {
                    enqueueSnackbar(<Trans>Error ocurred while saving</Trans>, {
                      variant: 'error'
                    })
                  }
                })
              }}
            >
              <Trans>Update contact information</Trans>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <Alert severity='warning'>
        <Trans>
          Some of required information in this contact are missing. Would you
          like to fill it?
        </Trans>
        <Button
          style={{ marginLeft: 10 }}
          variant='contained'
          color='primary'
          onClick={e => {
            setDialogOpen(true)
          }}
        >
          <Trans>Fill missing info</Trans>
        </Button>
      </Alert>
    </>
  )
}

// const AssignJobTitleField = ({ contactId, handleChange }) => {
//   const [newTitle, setNewTitle] = useState('')
//   const [saving, setSaving] = useState(false)
//   const { enqueueSnackbar } = useSnackbar()

//   useEffect(() => {
//     setNewTitle('')
//   }, [])

//   return (
//     <div>
//       <Typography style={{ marginTop: 5, marginBottom: 5 }}>
//         <Trans>
//           This contact has no job title filled. Would you like to fill it?
//         </Trans>
//       </Typography>
//       <Grid container justifyContent='center' alignItems='center'>
//         <Grid item style={{ paddingRight: 10 }}>
//           <Button
//             variant='contained'
//             color='primary'
//             disabled={!newTitle || saving}
//             onClick={e => {
//               setSaving(true)
//               saveContactByFlow({
//                 Id: contactId,
//                 Title: newTitle
//               }).then(result => {
//                 setSaving(false)
//                 if (result.isSuccess) {
//                   enqueueSnackbar(<Trans>Contact saved</Trans>, {
//                     variant: 'success'
//                   })
//                   handleChange(newTitle)
//                 } else {
//                   enqueueSnackbar(<Trans>Error ocurred while saving</Trans>, {
//                     variant: 'error'
//                   })
//                 }
//               })
//             }}
//           >
//             <Trans>Save new job title</Trans>
//           </Button>
//         </Grid>
//         <Grid item xs style={{ paddingRight: 10 }}>
//           <TextField
//             label={<Trans>New job title</Trans>}
//             value={newTitle}
//             onChange={e => {
//               setNewTitle(e.target.value)
//             }}
//             variant='outlined'
//             fullWidth
//           />
//         </Grid>
//       </Grid>
//     </div>
//   )
// }

export default FindContactButton
