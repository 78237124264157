import { Trans } from "@lingui/macro";
import { Text, View } from "@react-pdf/renderer";
import { formPDFStyles } from "app/views/forms/pdf-components/FormPdf";

export const FormProjectAffiliationsFillablePDF = ({
  value,
  elementType,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  typeProps,
  ...props
}) => {
  const { roles = [] } = typeProps;

  const columnStyles = [
    formPDFStyles.tableColumn,
    { width: "16.6%", minHeight: 36 },
  ];
  const referenceRoles = roles.some((obj) => obj.askForRelationship);

  return (
    <View style={formPDFStyles.table}>
      <View style={formPDFStyles.tableRow}>
        {!referenceRoles && (
          <View style={columnStyles}>
            <Text style={formPDFStyles.headerTableCell}>
              <Trans>Contact role</Trans>
            </Text>
          </View>
        )}
        <View style={columnStyles}>
          <Text style={formPDFStyles.headerTableCell}>
            <Trans>First Name</Trans>
          </Text>
        </View>
        <View style={columnStyles}>
          <Text style={formPDFStyles.headerTableCell}>
            <Trans>Last Name</Trans>
          </Text>
        </View>

        {!referenceRoles ? (
          <View style={columnStyles}>
            <Text style={formPDFStyles.headerTableCell}>
              <Trans>Job title</Trans>
            </Text>
          </View>
        ) : (
          <>
            <View style={columnStyles}>
              <Text style={formPDFStyles.headerTableCell}>
                <Trans>Relationship to provider</Trans>
              </Text>
            </View>
            <View style={columnStyles}>
              <Text style={formPDFStyles.headerTableCell}>
                <Trans>Organization</Trans>
              </Text>
            </View>
          </>
        )}
        <View style={columnStyles}>
          <Text style={formPDFStyles.headerTableCell}>
            <Trans>E-mail</Trans>
          </Text>
        </View>
        <View style={columnStyles}>
          <Text style={formPDFStyles.headerTableCell}>
            <Trans>Phone</Trans>
          </Text>
        </View>
      </View>

      {roles.map((role, index) => {
        return (
          <View style={formPDFStyles.tableRow} key={index}>
            {!referenceRoles ? (
              <View style={columnStyles}>
                <Text style={formPDFStyles.headerTableCell}>
                  {role.label[langVersion]}
                </Text>
              </View>
            ) : (
              <View style={columnStyles} />
            )}
            <View style={columnStyles} />
            <View style={columnStyles} />
            <View style={columnStyles} />
            <View style={columnStyles} />
            <View style={columnStyles} />
          </View>
        );
      })}
    </View>
  );
};
