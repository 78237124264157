import { Trans, t } from '@lingui/macro'
import {
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  MuiThemeProvider,
  Paper,
  TableCell,
  TextField,
  Typography
} from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import sfAuthService, { NO_USER } from 'app/services/sfAuth/SFAuthService'
import CustomDatePicker from 'app/views/common-components/CustomDatePicker'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import { Field } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { FormConnectToObject } from '../../../common/FormConnectToObject'
import { mapFormEditorElements } from '../../../editor/FormWizard'
import { requiredTrans } from '../../../formTranslations'
import { ICCETheme } from '../form-icce-budget/FormICCEBudget'

export const formICCEWorkplanDefaultValue = obj => {
  if (!obj || !obj.Work_Plan_Lines__r) {
    return []
  } else {
    return obj.Work_Plan_Lines__r.records.map(record => {
      return {
        description: record.Description__c,
        budgetLineRef: record.Budget_Line__c,
        endDate: record.End_Date__c,
        startDate: record.Start_Date__c,
        id: record.Id,
        staff: record.Staff_Involved__c
      }
    })
  }
}

export const formICCEWorkplanValueToText = (value, question, info) => {
  if (info.saveFailed) {
    const toRet = languages.reduce((acc, lang) => {
      acc[lang] = myI18n?._(t`Data is too complex to show differences!`)
      return acc
    }, {})
    return toRet
  }
  const toRet = languages.reduce((acc, lang) => {
    acc[lang] = (
      <div>
        <Trans>Data is too complex to show differences!</Trans>
        <div style={{ color: '#e0a912' }}>
          <Trans>
            Overriding will ony save changes to already existing budet lines. It
            will not delete any newly added lines. Manual cleanup may be
            required.
          </Trans>
        </div>
      </div>
    )
    return toRet
  })
}

export const formICCEWorkplanExtractKey = ({
  value,
  values,
  item,
  saveMap,
  connectedObjectId,
  ...props
}) => {
  const inner = []
  const { budget } = item.typeProps
  const budgetValue = values[budget]
  value.forEach(line => {
    const toRet = {
      Id: line.id,
      Budget_Line__c: line.budgetLineRef,
      Start_Date__c: line.startDate,
      End_Date__c: line.endDate,
      Description__c: line.description,
      Staff_Involved__c: line.staff
    }
    if (toRet.Id.indexOf('fake') !== -1) {
      delete toRet.Id
    }
    let exists = false
    if (budget && budgetValue) {
      const toCheck = getBudgetLines(budgetValue)
      exists = toCheck.some(item => item.id === line.budgetLineRef)
    }
    // if (line.budgetLineRef && line.budgetLineRef.indexOf('fake') !== -1) {
    //   delete toRet.Budget_Line__c
    // }
    if (!exists) {
      delete toRet.Budget_Line__c
    }
    inner.push(toRet)
  })
  saveMap[connectedObjectId].Work_Plan_Lines__r = inner
}

Yup.addMethod(Yup.object, 'checkWorkplanDates', function (message) {
  return this.test('checkWorkplanDates', message, function (workplace) {
    const { path, createError } = this
    const { startDate, endDate } = workplace
    if (!startDate || !endDate) {
      return true
    }
    const bool = moment.utc(startDate).isAfter(moment.utc(endDate))
    if (bool) {
      return createError({ path: path + '.chronology', message })
    }
    return true
  })
})

const keyToLabel = {
  staff: <Trans>Staff Involved</Trans>,
  budgetLineRef: <Trans>Budget Line Items</Trans>,
  description: <Trans>Description of activities</Trans>,
  chronology: <Trans>Start and end date</Trans>
}

export const formICCEWorkplanError = error => {
  if (error && !error.map) {
    return <span> {error} </span>
  }
  return (
    <div>
      {error &&
        error.map &&
        error.map((obj, index) => {
          return (
            <div key={index}>
              <Trans>Work Plan Line</Trans>
              {' ' + Number(index + 1)}
              <ul>
                {obj &&
                  Object.keys(obj).map(key => {
                    return (
                      <li key={key}>
                        <b>
                          {keyToLabel[key]}
                          {': '}
                        </b>
                        {obj[key]}
                      </li>
                    )
                  })}
              </ul>
            </div>
          )
        })}
    </div>
  )
}

export const formICCEWorkplanValidation = () => {
  return Yup.array()
    .ensure()
    .label(<Trans>Workplan</Trans>)
    .min(1, n => (
      <Trans>The work plan needs to contain at least one item</Trans>
    ))
    .of(
      Yup.object({
        startDate: Yup.date(),
        endDate: Yup.date(),
        chronology: Yup.mixed(),
        description: Yup.string().required(requiredTrans),
        staff: Yup.string().nullable(true).required(requiredTrans),
        budgetLineRef: Yup.string().nullable().required(requiredTrans)
      }).checkWorkplanDates(
        <Trans>
          Start date and end date have to be in chronological order!
        </Trans>
      )
    )
}

const deleteWorkplanLine = item => {
  if (item.id.indexOf('fake') === -1) {
    const conn = sfAuthService.getConnection()
    if (!conn) {
      return Promise.reject(NO_USER)
    }
    return conn.sobject('Work_Plan_Line__c').delete(item.id)
  } else {
    return Promise.resolve()
  }
}

export const getBudgetLines = budgetValue => {
  const toRet = []
  if (!budgetValue || !budgetValue.fiscalYears) {
    return toRet
  }
  budgetValue.fiscalYears.forEach(year => {
    year.budgetLines.forEach(line => {
      toRet.push({ id: line.id, label: line.description })
    })
  })
  return toRet
}

export const FormICCEWorkplan = ({
  id,
  title,
  editMode,
  saveDisabled,
  disabled,
  langVersion,
  connectedObject,
  typeProps = {},
  ...props
}) => {
  const { budget } = typeProps
  const { enqueueSnackbar } = useSnackbar()
  const [deleting, setDeleting] = React.useState(false)

  return (
    <Field name={id}>
      {({
        form: { setFieldValue, setFieldTouched, values, errors },
        field,
        meta
      }) => {
        const invalid = Boolean(
          !connectedObject ||
            !connectedObject.Id ||
            connectedObject.attributes.type !== 'Opportunity'
        )
        if (invalid && !editMode) {
          return (
            <div style={{ padding: 10, color: 'red' }}>
              <Trans>
                There is no object connected in editor or connected object is
                not of "Opportunity" type!
              </Trans>
            </div>
          )
        }
        if (!budget || !values[budget]) {
          return (
            <div style={{ color: 'red' }}>
              <b>
                <Trans>
                  Either ICCE budget field were not connected in form editor or
                  the field referenced was deleted!
                </Trans>
              </b>
            </div>
          )
        }

        const getFieldName = (tableMeta, name) => {
          const index = tableMeta.rowData[tableMeta.rowData.length - 1]
          return id + `[${index}]` + name
        }

        const getIndex = tableMeta =>
          tableMeta.rowData[tableMeta.rowData.length - 1]

        const budgetValue = values[budget]
        const budgetLinesToChoose = getBudgetLines(budgetValue)
        const value = Array.isArray(field.value) ? field.value : []
        const fieldErrors = errors[id] || []

        return (
          <MuiThemeProvider theme={ICCETheme}>
            <MUIDataTable
              options={{
                textLabels: muiTextLabels(myI18n),
                pagination: false,
                filter: false,
                selectableRows: 'none',
                print: false,
                download: false,
                viewColumns: false
              }}
              data={value.map((item, index) => ({
                ...item,
                actions: index
              }))}
              title={
                <Grid container direction='row'>
                  <Typography variant='h5'>
                    <b>{title}</b>
                  </Typography>
                  <Button
                    disabled={disabled}
                    color='primary'
                    variant='contained'
                    style={{ marginLeft: 10 }}
                    onClick={e => {
                      const newValue = [...value]
                      newValue.push({
                        startDate: moment.utc(),
                        endDate: moment.utc(),
                        id: 'fake'
                      })
                      setFieldValue(id, newValue)
                    }}
                  >
                    <Trans>Add new work plan line</Trans>
                    <Icon style={{ marginLeft: 5 }}>add</Icon>
                  </Button>
                </Grid>
              }
              columns={[
                {
                  name: 'description',
                  label: myI18n?._(t`Description of Activities`),
                  options: {
                    customHeadLabelRender: props => {
                      return (
                        <div style={{ marginLeft: 20 }}>
                          <Trans>Description of Activities</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      return (
                        <FormikTextField
                          name={getFieldName(tableMeta, 'description')}
                          inputProps={{
                            maxLength: 255
                          }}
                          helperText={countHelperText(value, 255)}
                          disabled={disabled}
                          style={{ marginLeft: 20, minWidth: 200 }}
                          variant='outlined'
                          fullWidth
                        />
                      )
                    }
                  }
                },
                {
                  name: 'budgetLineRef',
                  label: myI18n?._(t`Budget Line Items`),
                  options: {
                    customHeadLabelRender: props => (
                      <Trans>Budget Line Items</Trans>
                    ),
                    customBodyRender: (value, tableMeta) => {
                      const options = budgetLinesToChoose.filter(
                        item => item.label
                      )
                      const disable = options.length === 0 || disabled
                      return (
                        <>
                          <Field
                            name={getFieldName(tableMeta, 'budgetLineRef')}
                          >
                            {({ field, meta, error }) => {
                              return (
                                <TextField
                                  {...field}
                                  {...meta}
                                  disabled={disable}
                                  error={meta.error && meta.touched && !disable}
                                  value={value || ''}
                                  fullWidth
                                  variant='outlined'
                                  select
                                  helperText={requiredTrans}
                                  onChange={e => {
                                    setFieldValue(
                                      getFieldName(tableMeta, 'budgetLineRef'),
                                      e.target.value
                                    )
                                  }}
                                >
                                  {options.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item.id}>
                                        {item.label}
                                      </MenuItem>
                                    )
                                  })}
                                </TextField>
                              )
                            }}
                          </Field>
                        </>
                      )
                    }
                  }
                },
                {
                  name: 'startDate',
                  label: myI18n?._(t`Start Date`),
                  options: {
                    customHeadRender: props => {
                      return (
                        <TableCell style={{ width: 100 }}>
                          <Trans>Start Date</Trans>
                        </TableCell>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      const error = fieldErrors[getIndex(tableMeta)]
                      const isError = Boolean(error && error.chronology)
                      return (
                        <div>
                          <CustomDatePicker
                            disabled={disabled}
                            error={isError}
                            fullWidth
                            format={dateFormat}
                            value={value}
                            label={<Trans>Start date</Trans>}
                            inputVariant='outlined'
                            onChange={e => {
                              const date = moment.utc(e)
                              setFieldValue(
                                getFieldName(tableMeta, 'startDate'),
                                date
                              )
                            }}
                          />
                          <div style={{ height: 22 }}>
                            {isError && (
                              <p
                                style={{
                                  color: '#f5543b',
                                  fontSize: '0.75rem',
                                  marginTop: 3,
                                  marginLeft: 14,
                                  textAlign: 'left'
                                }}
                              >
                                <Trans>
                                  Dates have to be in chronological order!
                                </Trans>
                              </p>
                            )}
                          </div>
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'endDate',
                  label: myI18n?._(t`End Date`),
                  options: {
                    customHeadRender: props => {
                      return (
                        <TableCell style={{ width: 100 }}>
                          <Trans>End Date</Trans>
                        </TableCell>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      const error = fieldErrors[getIndex(tableMeta)]
                      const isError = Boolean(error && error.chronology)
                      return (
                        <div>
                          <CustomDatePicker
                            disabled={disabled}
                            error={isError}
                            fullWidth
                            format={dateFormat}
                            value={value}
                            label={<Trans>End date</Trans>}
                            inputVariant='outlined'
                            onChange={e => {
                              const date = moment.utc(e)
                              setFieldValue(
                                getFieldName(tableMeta, 'endDate'),
                                date
                              )
                            }}
                          />
                          <div style={{ height: 22 }}>
                            {isError && (
                              <p
                                style={{
                                  color: '#f5543b',
                                  fontSize: '0.75rem',
                                  marginTop: 3,
                                  marginLeft: 14,
                                  textAlign: 'left'
                                }}
                              >
                                <Trans>
                                  Dates have to be in chronological order!
                                </Trans>
                              </p>
                            )}
                          </div>
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'staff',
                  label: myI18n?._(t`Staff Involved`),
                  options: {
                    customHeadLabelRender: props => {
                      return (
                        <div>
                          <Trans>Staff Involved</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta) => {
                      return (
                        <FormikTextField
                          name={getFieldName(tableMeta, 'staff')}
                          inputProps={{
                            maxLength: 255
                          }}
                          style={{ minWidth: 200 }}
                          helperText={countHelperText(value, 255)}
                          disabled={disabled}
                          variant='outlined'
                          fullWidth
                        />
                      )
                    }
                  }
                },
                {
                  name: 'actions',
                  label: myI18n?._(t`Actions`),
                  options: {
                    sort: false,
                    customHeadRender: props => {
                      return <TableCell style={{ width: 50 }} />
                    },
                    customBodyRender: (valueCell, tableMeta, updateValue) => {
                      return (
                        <Grid
                          container
                          justify='flex-end'
                          style={{ marginBottom: 22 }}
                        >
                          <IconButton
                            disabled={deleting || disabled || saveDisabled}
                            onClick={e => {
                              const newValue = [...value]
                              const deleted = newValue.splice(valueCell, 1)[0]
                              setDeleting(true)
                              deleteWorkplanLine(deleted).then(
                                r => {
                                  enqueueSnackbar(
                                    <Trans>Deleted workplan line!</Trans>,
                                    {
                                      variant: 'success'
                                    }
                                  )
                                  setDeleting(false)
                                  setFieldValue(id, newValue)
                                },
                                reject => {
                                  enqueueSnackbar(
                                    <Trans>
                                      Error ocurred while deleting workplan
                                      line!
                                    </Trans>,
                                    {
                                      variant: 'error'
                                    }
                                  )
                                  setDeleting(false)
                                }
                              )
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Grid>
                      )
                    }
                  }
                }
              ]}
            />
          </MuiThemeProvider>
        )
      }}
    </Field>
  )
}

export const FormEditorICCEWorkplan = ({
  id,
  editMode,
  depth,
  typeProps = {},
  langVersion,
  ...props
}) => {
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const { budget } = typeProps

  const avaliableElements = mapFormEditorElements({
    data: tree,
    type: 'icce_budget',
    id,
    langVersion
  })

  if (!editMode) {
    return (
      <FormICCEWorkplan editMode {...props} id={id} typeProps={typeProps} />
    )
  }
  return (
    <div>
      <TextField
        value={budget || ''}
        fullWidth
        variant='outlined'
        select
        disabled={avaliableElements.length === 0}
        label={<Trans>ICCE budget element</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.budget = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        {avaliableElements.map((item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.label}
            </MenuItem>
          )
        })}
      </TextField>
      {avaliableElements.length === 0 && (
        <p
          style={{
            color: '#f5543b',
            fontSize: '0.75rem',
            marginTop: 10,
            marginLeft: 14,
            textAlign: 'left'
          }}
        >
          <Trans>
            You must add at least one ICCE budget type element to this form
          </Trans>
        </p>
      )}
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  )
}

export const ICCEWorkplan = ({ workplanLines, isPrintEditor }) => {
  return (
    <Paper elevation={6} style={{ marginTop: 10, padding: 10 }}>
      <Grid container>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Description of Activities</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Budget Line Items</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Start Date</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>End Date</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Staff Involved</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {workplanLines.map((obj, index) => {
        return (
          <Grid container key={index}>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {obj.description}
              </Typography>
            </Grid>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ minHeight: 21 }}>
                {obj.budgetLine}
              </Typography>
            </Grid>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {obj.startDate}
              </Typography>
            </Grid>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {obj.endDate}
              </Typography>
            </Grid>
            <Grid item xs style={{ padding: 4 }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {obj.staff}
              </Typography>
            </Grid>
          </Grid>
        )
      })}
    </Paper>
  )
}
