import ExcelJS from 'exceljs';

interface ExcelFileImportPdfField {
  columnIdx: number;
  field: string;
  label: string;
}

const preQualificationHousingPortfolioFields: ExcelFileImportPdfField[] = [
  {
    columnIdx: 1,
    label: 'Building identifier',
    field: 'buildingIdentifier',
  },
  {
    columnIdx: 2,
    label: 'Building address: Street + number',
    field: 'buildingAddress',
  },
  {
    columnIdx: 3,
    label: 'City/Town',
    field: 'city',
  },
  {
    columnIdx: 4,
    label: 'Postal code',
    field: 'postalCode',
  },
  {
    columnIdx: 5,
    label: 'Province/Territory',
    field: 'province',
  },
  {
    columnIdx: 6,
    label: 'No. of stories',
    field: 'noOfStories',
  },
  {
    columnIdx: 7,
    label: 'No. of accessible/barrier free units',
    field: 'noOfAccessibleUnits',
  },
  {
    columnIdx: 8,
    label: 'Property management',
    field: 'propertyManagement',
  },
  {
    columnIdx: 9,
    label: 'Current building condition',
    field: 'currentBuildingCondition',
  },
  {
    columnIdx: 10,
    label: 'No. of vacant Bachelor units',
    field: 'noOfVacantBachelorUnits',
  },
  {
    columnIdx: 11,
    label: 'No. of occupied Bachelor units',
    field: 'noOfOccupiedBachelorUnits',
  },
  {
    columnIdx: 12,
    label: 'No. of vacant 1 Bedroom units',
    field: 'noOfVacant1BedroomUnits',
  },
  {
    columnIdx: 13,
    label: 'No. of occupied 1 bedroom units',
    field: 'noOfOccupied1BedroomUnits',
  },
  {
    columnIdx: 14,
    label: 'No. of vacant 2 bedroom units',
    field: 'noOfVacant2BedroomUnits',
  },
  {
    columnIdx: 15,
    label: 'No. of occupied 2 bedroom units',
    field: 'noOfOccupied2BedroomUnits',
  },
  {
    columnIdx: 16,
    label: 'No. of vacant 3 bedroom units',
    field: 'noOfVacant3BedroomUnits',
  },
  {
    columnIdx: 17,
    label: 'No. of occupied 3 bedrooms units',
    field: 'noOfOccupied3BedroomUnits',
  },
  {
    columnIdx: 18,
    label: 'No. of vacant 4+ bedroom units',
    field: 'noOfVacant4BedroomUnits',
  },
  {
    columnIdx: 19,
    label: 'No. of occupied 4+ bedrooms units',
    field: 'noOfOccupied4BedroomUnits',
  },
  {
    columnIdx: 20,
    label: 'Total No. of units',
    field: 'totalNoOfUnits',
  },
];


const propertyInformationFields: ExcelFileImportPdfField[] = [
  {
    columnIdx: 1,
    label: 'Building identifier',
    field: 'buildingIdentifier',
  },
  {
    columnIdx: 2,
    label: 'Building address: Street + number',
    field: 'buildingAddress',
  },
  {
    columnIdx: 3,
    label: 'City/Town',
    field: 'city',
  },
  {
    columnIdx: 4,
    label: 'Postal code',
    field: 'postalCode',
  },
  {
    columnIdx: 5,
    label: 'Province/Territory',
    field: 'province',
  },
  {
    columnIdx: 6,
    label: 'No. of stories',
    field: 'noOfStories',
  },
  {
    columnIdx: 7,
    label: 'No. of elevators',
    field: 'noOfElevators',
  },
  {
    columnIdx: 8,
    label: 'No. of parking spaces',
    field: 'noOfParkingSpaces',
  },
  {
    columnIdx: 9,
    label: 'Property management',
    field: 'propertyManagement',
  },
  {
    columnIdx: 10,
    label: 'Current building condition',
    field: 'currentBuildingCondition',
  },
  {
    columnIdx: 11,
    label: '% of barrier free common areas',
    field: 'percentBarrierFreeCommonAreas',
  },
  {
    columnIdx: 12,
    label: 'Total No. of units',
    field: 'totalNoOfUnits',
  },
  {
    columnIdx: 13,
    label: 'No. of accessible/barrier free units',
    field: 'noOfAccessibleUnits',
  },
  {
    columnIdx: 14,
    label: 'No. of vacant Bachelor units',
    field: 'noOfVacantBachelorUnits',
  },
  {
    columnIdx: 15,
    label: 'No. of occupied Bachelor units',
    field: 'noOfOccupiedBachelorUnits',
  },
  {
    columnIdx: 16,
    label: 'No. of vacant 1 Bedroom units',
    field: 'noOfVacant1BedroomUnits',
  },
  {
    columnIdx: 17,
    label: 'No. of occupied 1 bedroom units',
    field: 'noOfOccupied1BedroomUnits',
  },
  {
    columnIdx: 18,
    label: 'No. of vacant 2 bedroom units',
    field: 'noOfVacant2BedroomUnits',
  },
  {
    columnIdx: 19,
    label: 'No. of occupied 2 bedroom units',
    field: 'noOfOccupied2BedroomUnits',
  },
  {
    columnIdx: 20,
    label: 'No. of vacant 3 bedroom units',
    field: 'noOfVacant3BedroomUnits',
  },
  {
    columnIdx: 21,
    label: 'No. of occupied 3 bedrooms units',
    field: 'noOfOccupied3BedroomUnits',
  },
  {
    columnIdx: 22,
    label: 'No. of vacant 4+ bedroom units',
    field: 'noOfVacant4BedroomUnits',
  },
  {
    columnIdx: 23,
    label: 'No. of occupied 4+ bedrooms units',
    field: 'noOfOccupied4BedroomUnits',
  },
];

const parseExcelFileImportPdf = async function({file, noOfHeaderRows, fields}:{file: Buffer, noOfHeaderRows: number, fields: ExcelFileImportPdfField[]}) {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);  

  const worksheet = workbook.getWorksheet(1);
  const parsedRows: typeof fields = [];

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber <= noOfHeaderRows) {
      return;
    }
    const parsedRow: ExcelFileImportPdfField = {} as ExcelFileImportPdfField;
    for (const field of fields) {
      parsedRow[field.field] = row.getCell(field.columnIdx).value;
    }
    parsedRows.push(parsedRow);
  })
  return parsedRows;
}

interface UploadExcelFileImportPdfProps {
  filePath: string;
  organization: {
    organisationsName: string;
    phone: string;
    email: string;
    street: string;
    province: string;
    postalCode: string;
  }
}

function uploadExcelFileImportPdf({filePath, organization}: UploadExcelFileImportPdfProps): Promise<string> {
  return fetch(filePath)
  .then(response => response.arrayBuffer())
  .then(async (buffer) => {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);

    const worksheet = workbook.getWorksheet(1); 
    const cellM1 = worksheet.getCell('M1');
    const cellM2 = worksheet.getCell('M2');
    const cellN1 = worksheet.getCell('N1');
    const cellN2 = worksheet.getCell('N2');

    cellM1.value = organization?.organisationsName || cellM1.value;
    cellM2.value = organization?.phone || cellM2.value;
    cellN1.value = organization?.email || cellN1.value;
    cellN2.value = `${organization?.street}, ${organization?.province}, ${organization?.postalCode}` || cellN2.value;
    
    const modifiedBuffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([modifiedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    return url;
  })
}

export { parseExcelFileImportPdf, uploadExcelFileImportPdf, preQualificationHousingPortfolioFields, propertyInformationFields };

