import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    minHeight: 16
  },
  firstCol: {
    width: '33%'
  },
  secondCol: {
    width: '33%'
  },
  thirdCol: {
    width: '34%'
  },
  tableCol: {
    borderStyle: 'solid',
    minHeight: 36,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 10,
    margin: '2px'
  }
})

export const FormOtherGrantsFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.firstCol]}>
          <Text style={styles.tableCell}>{myI18n._(t`Name`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.secondCol]}>
          <Text style={styles.tableCell}>{myI18n._(t`Value`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.thirdCol]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`Status`)} ({myI18n._(t`Approved`)},{' '}
            {myI18n._(t`Pending`)}, {myI18n._(t`Not submitted yet`)},{' '}
            {myI18n._(t`Declined`)})
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.firstCol]}></View>
        <View style={[styles.tableCol, styles.secondCol]}></View>
        <View style={[styles.tableCol, styles.thirdCol]}></View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.firstCol]}></View>
        <View style={[styles.tableCol, styles.secondCol]}></View>
        <View style={[styles.tableCol, styles.thirdCol]}></View>
      </View>
    </View>
  )
}
