import { Text, View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from '../../../common/Common'

/**
 * Form element which displays a list of contract signers related to the project.
 * @category Form
 * @subcategory Form PDF elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection}  typeProps.connectedObject Connected Salesforce object of Opportunity type.
 * @param  {string}  typeProps.placeholder OneSpan placeholder.
 * @param  {object[]}  typeProps.signatories Affiliated contacts that should be displayed on the list.
 */
export const FormSignaturePdf = ({
  title,
  connectedMap,
  typeProps,
  connectedObject,
  ...props
}) => {
  const { connectedTo = [], signatories = [], placeholder } = typeProps
  const opportunityId = connectedTo[0]?.connectedObject
  let affiliatedContacts = []
  if (opportunityId) {
    affiliatedContacts =
      connectedMap[opportunityId]?.additionalInfo?.opportunityAffiliatedContacts
  }
  const affiliationsMap = {}
  affiliatedContacts.forEach(affiliation => {
    affiliationsMap[affiliation.Role__c] = affiliation.Contact__c.Name
  })

  return (
    <View>
      <Text
        style={{
          fontSize: pdfDefaultFontSize,
          fontWeight: 'bold',
          color: 'white'
        }}
      >
        {placeholder}
      </Text>
      <View
        style={{
          height: 1,
          width: 250,
          marginBottom: 8,
          backgroundColor: 'black'
        }}
      />
      {signatories
        .filter(signatory => signatory.role)
        .map((signatory, index) => {
          return (
            <View key={index}>
              <Text style={{ fontSize: pdfDefaultFontSize }}>
                {affiliationsMap[signatory.role]}
              </Text>
            </View>
          )
        })}
    </View>
  )
}
