import { Text, View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from '../../common/Common'
import {
  defaultFormPdfFontFamily,
  formPDFStyles,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'
import { getFormPicklistOptions } from './formPicklistUtils'

const styles = {
  picklist: {},
  main: {
    flexDirection: 'row'
  },
  left: {
    width: '25%',
    paddingRight: 8
  },
  right: {
    width: '75%'
  },
  title: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 8
  },
  helpText: {
    fontSize: pdfDefaultFontSize - 2,
    marginBottom: 8
  },
  maxOptions: {
    fontSize: 8,
    marginTop: 4
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 4,
    width: '48%',
    flexWrap: 'nowrap'
  },
  odd: {
    marginLeft: '4%'
  }
}

export const FormPicklistFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  i18n,
  connectedFieldDetails,
  ...props
}) => {
  const {
    pdfBorderDisplay,
    maxOptions = 0,
    requiredAll,
    picklistType
  } = typeProps

  let options = getFormPicklistOptions({
    typeProps,
    langVersion,
    i18n,
    connectedFieldDetails
  })

  styles.picklist = {
    padding: 6,
    fontFamily:
      langVersion === 'iu-cans'
        ? inuktitutFormPdfFontFamily
        : defaultFormPdfFontFamily,
    fontSize: pdfDefaultFontSize
  }
  if (pdfBorderDisplay) {
    styles.picklist = {
      padding: 6,
      border: '1px solid rgba(0, 0, 0, 0.02)',
      borderRadius: '5px'
    }
  }

  return (
    <View style={styles.picklist} wrap={false}>
      {/* {maxOptions > 1 && (
        <Text
          style={{
            fontSize: 8,
            fontStyle: 'italic',
            marginTop: 4
          }}
        >
          <Trans>You must select {maxOptions} checkboxes</Trans>
        </Text>
      )} */}
      <View style={styles.main}>
        <View
          style={
            options.length > 9
              ? {
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }
              : {}
          }
        >
          {options.map((option, index) => {
            const { title, tooltip } = option
            let style

            if (options.length > 9) {
              style =
                index % 2 === 1
                  ? [styles.listItem, styles.odd]
                  : styles.listItem
            } else {
              style = {
                flexDirection: 'row',
                marginBottom: 4,
                flexWrap: 'nowrap'
              }
            }
            return (
              <View key={index} style={style}>
                <View
                  style={
                    picklistType === 'multiselect' || requiredAll
                      ? formPDFStyles.checkbox
                      : formPDFStyles.radioCheck
                  }
                />
                <Text
                  style={{
                    fontSize: pdfDefaultFontSize,
                    width: '92%',
                    lineHeight: 1.4
                  }}
                >
                  {title} {tooltip && `(${tooltip})`}
                </Text>
              </View>
            )
          })}
        </View>
      </View>
    </View>
  )
}
