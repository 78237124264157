import { checkFormValidity } from "app/views/forms/FormHelpersValidation";
import { constructFormAddressString } from "app/views/forms/common/Common";
import SFAuthService, { NO_USER } from "../SFAuthService";
import sfOauthConfig from "../sfAuthConfig";
import { mapSFToForm, reverseMap } from "../sfDataService";
import { getAccount } from "./sfAccount";
import { getConfiguration, getFormPage } from "./sfForms";

const mapFields = {
  Prefer_Emails_and_Phone_Calls__c: "preferPhone",
  Prefer_Only_Emails__c: "preferEmail",
  direction: "in",
  Id: "id",
  FirstName: "firstName",
  LastName: "lastName",
  Phone: "contactPhone",
  UserName: "username",
  Language__c: "language",
  Email: "email",
  Title: "jobTitle",
  Associated_Organizations__c: "associatedOrganizations",
  ContactId: "contactId",
  AccountId: "accountId",
  Redirected_To_Profile__c: "redirectedToProfile",
  Redirected_To_Join_Organization__c: "redirectedToJoinOrganization",
  PM_filters__c: {
    key: "pmFilters",
    in: (user) => (user.PM_filters__c ? JSON.parse(user.PM_filters__c) : {}),
  },
  FGM_Portal__UserProfile__c: {
    key: "role",
    in: (user) =>
      user.FGM_Portal__UserProfile__c
        ? user.FGM_Portal__UserProfile__c.split(";")
        : [],
  },
};
const reverseMapFields = reverseMap(mapFields);

/**
 * Returns User record for provided id
 * @function
 * @category Salesforce - User
 * @param {string} id Id of the user.
 * @returns {JSForceResult}
 */
export const getUser = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("User").retrieve(id);
};

/**
 * Returns parsed User record for provided id
 * @function
 * @category Salesforce - User
 * @param {string} id Id of the user.
 * @param {boolean} [getOrganization=false] If true, query will also return current associateted organization name.
 * @returns {JSForceResult}
 */
export const getUserParsed = (id: string, getOrganization: boolean = false) => {
  return getUser(id).then((user) => {
    console.log("getUser", user);
    if (getOrganization) {
      return getAccount(user.Associated_Organizations__c).then((org) => {
        return {
          ...mapSFToForm(mapFields, user),
          associatedOrganizationName: org.Name,
        };
      });
    }
    return mapSFToForm(mapFields, user);
  });
};

interface PermissionsObject {
  MANAGE_ORGANIZATION?: boolean;
}

/**
 * Returns users for provided ids
 * @function
 * @category Salesforce - User
 * @param {string[]} ids Ids of the users.
 * @param {PermissionsObject} [permissions] Additional subqueriers that should be fetched - identified by keys provided to the object.
 * @param {boolean} [permissions.MANAGE_ORGANIZATION] If true, only fields related to managing organization will be fetched
 * @returns {JSForceResult}
 */
export const getUsers = ({
  ids,
  permissions = {},
}: {
  ids: string[];
  permissions: PermissionsObject;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (ids.length === 0) {
    return Promise.resolve().then((r) => []);
  }
  let retPromise = conn.sobject("User").find({
    Id: { $in: ids },
  });
  if (permissions.MANAGE_ORGANIZATION) {
    retPromise = retPromise.select(
      "Id, Name, Email, FGM_Portal__UserEmail__c, ContactId"
    );
  }
  return retPromise;
};

/**
 * Returns parsed users for provided ids
 * @function
 * @category Salesforce - User
 * @param {string[]} ids Ids of the users.
 * @returns {User[]}
 */
export const getUsersParsed = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("User")
    .find({
      Id: { $in: ids },
    })
    .then((result) => {
      return result.map((acc) => mapSFToForm(mapFields, acc));
    });
};

/**
 * Returns users for provided ids
 * @function
 * @category Salesforce - User
 * @param {string[]} ids Ids of the either the User or Contact.
 * @param {string} [type='id'] Type of search query should perform. If type quals to 'id' then ids will be treated as User Id. If type equals to 'contactId' then ids will be treated as Contact Id.
 * @returns {User[]}
 */
export const getUsersByFlow = (ids: string[], type: string = "id") => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Find_User", {
      inputs: ids
        .map((id) => {
          if (type === "id") {
            return { id };
          } else if (type === "contactId") {
            return { contactId: id };
          } else {
            return null;
          }
        })
        .filter((a) => a),
    })
    .then((result) => {
      return result.map((obj) => obj.outputValues.user).filter((user) => user);
    });
};

/**
 * Returns users for provided ids
 * @function
 * @category Salesforce - User
 * @param {object} searchObj Search params.
 * @returns {User}
 */
export const getUserByFlow = (searchObj: object) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Find_User", {
      inputs: [searchObj],
    })
    .then((result) => result[0].outputValues.user);
};

export const USER_SEARCH_ERROR_TOO_MANY =
  "Too many records found! Try to be more specific in your search!";
export const USER_SEARCH_ERROR_NOT_FOUND = "No users found!";
export const USER_VALIDITY_NO_FORM = "USER_VALIDITY_NO_FORM";

/**
 * Checks if all fields marked as required are filled on the user profile.
 * @function
 * @category Salesforce - User
 * @param {string} id Id of the user.
 * @returns {boolean} If all required fields are filled on the user profile.
 */
export const checkUserProfileValidity = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return getConfiguration({
    Name: "FORM_USER_PROFILE",
  }).then((config) => {
    if (config.Value__c) {
      return getFormPage(config.Value__c).then(
        (form) => {
          if (!form) {
            return USER_VALIDITY_NO_FORM;
          }
          return checkFormValidity({
            formId: form.id,
            id: constructFormAddressString({
              ids: {
                User: id,
              },
              objectsConnected: form.objectsConnected,
            }),
          });
        },
        (reject) => {
          console.log("error while fetching form page: ", reject);
        }
      );
    } else {
      return USER_VALIDITY_NO_FORM;
    }
  });
};

/**
 * Checks if all fields marked as required are filled on the user profile.
 * @function
 * @category Salesforce - User
 * @param {string[]} ids Id of the user.
 * @returns {boolean} If all required fields are filled on the user profile.
 */
export const getMembershipsForUsersByFlow = (ids: string[]) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Get_Group_Memberships_For_User", {
      inputs: ids.map((id) => ({ id })),
    })
    .then((membershipResult) => {
      const toRet = {};
      membershipResult.forEach((result, index) => {
        const { memberships } = result.outputValues;
        if (memberships.length > 0) {
          toRet[ids[index]] = memberships;
        }
      });
      return toRet;
    });
};

/**
 * Returns are avaliable Public Groups.
 * @function
 * @category Salesforce - User
 * @returns {Group[]}
 */
export const getPublicGroups = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Group").find({
    Type: "Regular",
  });
};

/**
 * Checks if all fields marked as required are filled on the user profile.
 * @function
 * @category Salesforce - User
 * @param {string} id Id of the user.
 * @returns {Users[]}
 */
export const getAllUsersWithInfoThroughFlow = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Get_All_Users_With_Info", {
      inputs: [{}],
    })
    .then((result) => result[0].outputValues.usersFound);
};

/**
 * Return names of the group the user belongs to.
 * @function
 * @category Salesforce - User
 * @param {string} id Id of the user.
 * @returns {string[]} Array with group names users belong to.
 */
export const getUserGroupMembership = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("GroupMember")
    .find({
      UserOrGroupId: id,
    })
    .then((result) => {
      const toFetch = result.map((obj) => obj.GroupId);
      if (toFetch.length === 0) {
        return [];
      }
      return conn
        .sobject("Group")
        .find({
          Id: { $in: toFetch },
        })
        .then((result) => {
          return result.map((group) => group.DeveloperName);
        });
    });
};

interface SearchObject {
  name?: string;
  email?: string;
}

/**
 * Searches for user by provided input.
 * @function
 * @category Salesforce - User
 * @param {object} searchObj Id of the user.
 * @param {string} [searchObj.name] User name to search by.
 * @param {string} [searchObj.email] User email to search by.
 * @returns {object} Object containing found records and error prompt if search input needs correcting.
 */
export const searchForUserByFlow = (searchObj: SearchObject) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Find_Users", {
      inputs: [
        {
          name: searchObj.name !== "" ? searchObj.name : null,
          email: searchObj.email !== "" ? searchObj.email : null,
        },
      ],
    })
    .then(
      (result) => {
        let records = [];
        if (result[0].outputValues.resultCount > 0) {
          records = result[0].outputValues.usersList;
        }
        if (records.length === 0) {
          return { error: USER_SEARCH_ERROR_NOT_FOUND, records: [] };
        } else if (records.length > 0 && records.length <= 3) {
          return { records: records };
        } else {
          return {
            error: USER_SEARCH_ERROR_TOO_MANY,
            records: [],
          };
        }
      },
      (reject) => {
        console.log(reject);
      }
    );
};

/**
 * Searches for Network Member record by member id.
 * @function
 * @category Salesforce - User
 * @param {string} id MemberId of the network member.
 * @returns {NetworkMember}
 */
export const getNetworkUser = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("NetworkMember")
    .find({
      MemberId: id,
    })
    .select("NetworkId, MemberId, Network.Name")
    .autoFetch(true)
    .then((result) => {
      console.log("getNetworkUser", result);
      if (result && result.length === 1) {
        return result[0];
      } else {
        return Promise.reject();
      }
    });
};

/**
 * Returns Network for current domain.
 * @function
 * @category Salesforce - User
 * @param {string} id MemberId of the network member.
 * @returns {Network}
 */
export const getNetwork = () => {
  const conn = SFAuthService.getConnection();
  let name;
  if (sfOauthConfig.isIcce) {
    name = "ICCE-CAEC Community";
  } else {
    name = "APIConnectionPortal";
  }
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Network")
    .find({
      Name: name,
    })
    .select("Id, Name")
    .autoFetch(true)
    .then((result) => {
      console.log("getNetworkUser", result);
      if (result && result.length === 1) {
        return result[0];
      } else {
        return Promise.reject();
      }
    });
};

/**
 * Saves the user through flow and returns the current record.
 * @function
 * @category Salesforce - User
 * @param {object} toUpdate User update object.
 * @returns {User}
 */
export const saveUserThroughFlow = (toUpdate: object) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .requestPost("/actions/custom/flow/App_Update_User", {
      inputs: [
        {
          toUpdate,
        },
      ],
    })
    .then((result) => result[0].outputValues.user);
};

interface SaveUserData {
  AccountId?: string;
}

/**
 * Saves the user.
 * @function
 * @category Salesforce - User
 * @param {SaveUserData} toUpdate User update object.
 * @returns {User}
 */
export const saveUser = (toUpdate: SaveUserData) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  // delete field that can't be edited
  delete toUpdate.AccountId;
  return conn.sobject("User").update(toUpdate);
};

/**
 * Saves the parsed user.
 * @function
 * @category Salesforce - User
 * @param {object} toUpdate User update object.
 * @returns {JSForceResult}
 */
export const saveUserParsed = (toUpdate: object) => {
  return saveUser(mapSFToForm(reverseMapFields, toUpdate));
};

/**
 * Used if associated organization on user is empty. Sets a default associated organization from organiztions user belongs to.
 * @function
 * @category Salesforce - User
 * @param {string} userId User update object.
 * @returns {JSForceResult}
 */
export const appSetDefaultAccountTeamThroughFlow = (userId: string) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost(
      "/actions/custom/flow/App_Set_Default_Account_Team",
      {
        inputs: [
          {
            userId,
          },
        ],
      }
    );
  } else {
    return Promise.reject(NO_USER);
  }
};

/**
 * Creates an email invitation to an organization.
 * @function
 * @category Salesforce - Account
 * @param {object} props Props for the flow.
 * @param {string} props.accountId Id of an organization.
 * @param {string} props.email Email on which email should be sent into.
 * @param {string} props.jobTitle Proposed job title of the account member.
 * @param {string} props.teamRole Proposed team role of the account member.
 * @returns {FlowResult}
 */
export const createInviteToOrganizationByEmailThroughFlow = ({
  accountId,
  email,
  jobTitle,
  teamRole,
}: {
  accountId: string;
  email: string;
  jobTitle: string;
  teamRole: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (conn) {
    return conn.requestPost("/actions/custom/flow/App_Team_Invite_by_email", {
      inputs: [
        {
          accountId,
          email,
          jobTitle,
          teamRole,
        },
      ],
    });
  } else {
    return Promise.reject(NO_USER);
  }
};
