import { Trans } from "@lingui/macro";
import { ErrorMessage, useField } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";

/**
 * Label used in form fields. Can be render in either normal or error state.
 * @category Common components
 * @subcategory Form
 * @component
 * @returns {JSX.Element}
 * @property {React.ReactNode} label Label content
 * @property {boolean} error If true, label will be render in the error state
 * @property {boolean} required If true, label will render a message informing that prociding input to this field is required
 * @property {string} id Id of the field the error is related to
 */
export const FormErrorLabel: React.FC<{
  /**
  If true, label will be render in the error state
   */
  error: boolean;
  /**
  Non-error label content
   */
  label: React.ReactNode;
  /**
  If true, label will render a message informing that prociding input to this field is required
   */
  required: boolean;
  /**
  Id of the field the error is related to
   */
  id: string;
}> = ({ error, required, id, label }) => {
  const [field, meta, helpers] = useField(id);
  const isError =
    error || (id && meta.error && meta.touched && !Array.isArray(meta.error));
  const message = label ? (
    label
  ) : _.get(meta.error, id) ? (
    <Trans id={_.get(meta.error, id)} />
  ) : null;
  const nonErrorLabel = Boolean(required || label);

  useEffect(() => {
    if (isError && !meta.touched) {
      helpers.setTouched(true);
    }
  }, [isError, meta.touched]);

  return (
    <>
      {Boolean(!isError && nonErrorLabel) && (
        <p
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "0.75rem",
            marginTop: 3,
            marginBottom: 3,
            marginLeft: 14,
            textAlign: "left",
          }}
        >
          {message ? (
            required ? (
              <span>
                {message}. <Trans>Required</Trans>
              </span>
            ) : (
              <span>{message}</span>
            )
          ) : (
            <Trans>Required</Trans>
          )}
        </p>
      )}
      {id && (
        <ErrorMessage name={id}>
          {(msg: React.ReactNode) => {
            if (Array.isArray(msg)) {
              return null;
            }
            return (
              <p
                style={{
                  color: "#f5543b",
                  fontSize: "0.75rem",
                  marginTop: 3,
                  marginBottom: 3,
                  marginLeft: 14,
                  textAlign: "left",
                }}
              >
                {msg}
              </p>
            );
          }}
        </ErrorMessage>
      )}
    </>
  );
};
