import { Trans } from '@lingui/macro'
import { Grid, TextField, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import reactImageSize from 'react-image-size'
import { isConditionMet } from '../../FormHelpersConditions'
import { parseFormLabelText } from '../../common/Common'

/**
 * Form element which renders an image.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {string}  typeProps.imgLink URL of image that element renders.
 * @param  {number} [typeProps.width] Overrides native image width. In pixels.
 * @param  {number} [typeProps.height] Overrides native image height. In pixels.
 * @param  {number}  [typeProps.percent] Renders image in a percentage of its native size.
 * @param  {boolean} [typeProps.centerImage=false] If image should be middle aligned if it's smaller than it's container.
 */
export const FormImage = ({
  id,
  langVersion,
  title,
  typeProps,
  preview,
  describeMap,
  objectsFieldsMap,
  connectedMap,
  elementsMap,
  ...props
}) => {
  const { conditions = [] } = props
  const [errorOcurred, setError] = React.useState(false)
  const { values } = useFormikContext()
  const { imgLink, width, height, percent, centerImage } = typeProps
  const [calcHeight, setCalcHeight] = useState()
  const [calcWidth, setCalcWidth] = useState()
  let url = imgLink
  conditions
    .filter(condition => condition.state === 'altLink')
    .some(condition => {
      const { conditionMet } = isConditionMet({
        condition,
        connectedMap,
        values,
        elementsMap
      })
      if (conditionMet && condition.altLink) {
        url = condition.altLink
        return true
      }
      return false
    })
  // let url
  // if (file && file.url) {
  //   url = file.url
  // } else {
  //   url = imgLink
  // }
  const source = preview
    ? url
    : parseFormLabelText({
        text: url,
        langVersion,
        describeMap,
        objectsFieldsMap,
        returnString: true
      })

  useEffect(() => {
    if (percent) {
      reactImageSize(source).then(
        ({ width, height }) => {
          setCalcHeight((height * percent) / 100)
          setCalcWidth((width * percent) / 100)
        },
        reject => {
          console.error(
            'Could not read image width and height for url: ' + source
          )
          setError(true)
        }
      )
    }
  }, [percent, source])

  if (errorOcurred) {
    return (
      <Typography
        style={{
          fontWeight: 'bold',
          color: 'red',
          marginLeft: 10
        }}
      >
        <Trans>Invalid image link!</Trans>
      </Typography>
    )
  }

  const imgStyle = {}
  if (width) {
    imgStyle.width = +width
  }
  if (height) {
    imgStyle.height = +height
  }
  if (calcHeight) {
    imgStyle.height = calcHeight
  }
  if (calcWidth) {
    imgStyle.width = calcWidth
  }

  return (
    <Grid
      direction='column'
      alignItems={centerImage ? 'center' : 'auto'}
      container
      justify='center'
    >
      <img
        alt=''
        src={source}
        style={imgStyle}
        onError={e => {
          console.log('error', e)
          setError(true)
        }}
      />
      <Typography
        style={{
          fontWeight: 'bold',
          marginTop: 10
        }}
      >
        {title || ''}
      </Typography>
    </Grid>
  )
}

export const formImageConditionsStates = {
  altLink: {
    label: <Trans>Show alternative image</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Link to image</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}
