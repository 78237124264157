import { Trans, t } from '@lingui/macro'
import { commonPdfStyles, pdfDefaultFontSize } from 'app/views/forms/common/Common'
import { myI18n } from 'translation/I18nConnectedProvider'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { defaultFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'

const styles = StyleSheet.create({
  text: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily,
  },
  section: {
    marginBottom: 8
  },
  objectiveTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4
  },
})

/**
 * Form element which allows to display objectives updates in PDF.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 */
export const FormObjectivesUpdatePdf = ({
  id,
  title,
  helpText,
  connectedObject,
  value,
  ...props
}) => {

  const objectives = value || []

  const lastReportTitle = myI18n?._(t`FORM_OBJECTIVES_UPDATE_LAST_REPORT_SUBTITLE`)
  const previousReportTitle = myI18n?._(t`FORM_OBJECTIVES_UPDATE_PREVIOUS_REPORT_SUBTITLE`)

  const language = myI18n.language

  const getFormatedDate = date => {
    if (!date) {
      return ''
    }
    const _date = new Date(date)
    const month = _date.toLocaleString(language, { month: 'long' })
    const day = _date.getDate()
    const year = _date.getFullYear()
    return `${month} ${day}, ${year}`
  }

  return (
    <View wrap={true}>
      {title ? <Text style={commonPdfStyles.title}>{title}</Text>: <View/>}

      {objectives.map((item, index) => {
        const latestObjectiveUpdates = (connectedObject?.latestObjectiveUpdates || []).filter(
          ou => ou.objectiveId === item.id
        )
        const objUdateIdentifiedNeedChanges = item?.objectivesUpdate?.identifiedNeedChanges
        const objUdateDesiredOutcomeChanges = item?.objectivesUpdate?.desiredOutcomeChanges
        const objUdateActualOutcomes = item?.objectivesUpdate?.actualOutcomes
        return (
          <View key={index} style={styles.section}>
            <View>
              <Text style={styles.objectiveTitle}>{item.objective}</Text>
            </View>
            <View>
              <Text style={styles.subtitle}>{myI18n?._(t`Needs`)}</Text>
            </View>
            {(!objUdateIdentifiedNeedChanges && latestObjectiveUpdates.length === 0) ? <View>
              <Text style={styles.text}>{item.needs}</Text>
            </View> : null}
            {(!objUdateIdentifiedNeedChanges && latestObjectiveUpdates.length > 0) ? latestObjectiveUpdates
            .filter(answer => answer.identifiedNeedChanges)
            .map((answer, idx) => {
              const title =
                idx === 0
                  ? lastReportTitle
                  : previousReportTitle
              const formattedDate = getFormatedDate(
                answer.reportSubmissionDate
              )
              return (
                <View key={idx}>
                  <Text style={styles.text}>{title}: {formattedDate}</Text>
                  <Text style={styles.text}>{answer.identifiedNeedChanges}</Text>
                </View>
              )
            }): null}

            {objUdateIdentifiedNeedChanges ? <View>
              <View>
                <Text style={styles.text}>{myI18n?._(t`OBJECTIVES_UPDATE_IDENTIFIED_NEEDS_CHANGES`)}</Text>
              </View>
              <View>
                <Text style={styles.text}>{item?.objectivesUpdate?.identifiedNeedChanges}</Text>
              </View>
            </View>: null}

            <View>
              <Text style={styles.subtitle}>{myI18n?._(t`Outcomes`)}</Text>
            </View>
            {(!objUdateDesiredOutcomeChanges && !objUdateActualOutcomes && latestObjectiveUpdates.length === 0) ? <View>
              {item.outcomes.map((outcome, idx) => {
                return (
                  <View key={idx}>
                    <Text style={styles.text}>{idx + 1}. {outcome}</Text>
                  </View>
                )
              })}
            </View>: null}
            {(!objUdateDesiredOutcomeChanges && latestObjectiveUpdates.length > 0) ? <View>
              {latestObjectiveUpdates.filter(answer => answer.desiredOutcomeChanges)
              .map((answer, idx) => {
                const title = idx === 0
                ? lastReportTitle
                : previousReportTitle
                const formattedDate = getFormatedDate(
                  answer.reportSubmissionDate
                )
                return (
                  <View key={idx}>
                    <Text style={styles.text}>{title}: {formattedDate}</Text>
                    <Text style={styles.text}>{answer.desiredOutcomeChanges}</Text>
                  </View>
                )
              })}
            </View>: null}
            {objUdateDesiredOutcomeChanges ? <View>
              <View>
                <Text style={styles.text}>{myI18n?._(t`OBJECTIVES_UPDATE_DESIRED_OUTCOME_CHANGES`)}</Text>
              </View>
              <View>
                <Text style={styles.text}>{item?.objectivesUpdate?.desiredOutcomeChanges}</Text>
              </View>
            </View>: null}

            {(!objUdateActualOutcomes && latestObjectiveUpdates.length > 0) ? latestObjectiveUpdates
            .filter(answer => answer.actualOutcomes)
            .map((answer, idx) => {
              const title = idx === 0
              ? lastReportTitle
              : previousReportTitle
              const formattedDate = getFormatedDate(
                answer.reportSubmissionDate
              )
              return (
                <View key={idx}>
                  <Text style={styles.text}>{title}: {formattedDate}</Text>
                  <Text style={styles.text}>{answer.actualOutcomes}</Text>
                </View>
              )
            }): null}

            {objUdateActualOutcomes ? <View>
              <View>
                <Text style={styles.text}>{myI18n?._(t`OBJECTIVES_UPDATE_ACTUAL_OUTCOMES`)}</Text>
              </View>
              <View>
                <Text style={styles.text}>{item?.objectivesUpdate?.actualOutcomes}</Text>
              </View>
            </View>: null}

          </View>
        )
      })}

    </View>
  )
}
