import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { useDispatch } from 'react-redux'
import { getMainConnected } from '../../Form'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { validFieldTypesForTextField } from '../../editor/FormWizard'
import { FormTextField } from './FormTextField'
import { FormTextInputPrint } from './FormTextInputReadOnly'
import DebouncedTextField from '../../common/DebouncedTextField'

export function FormEditorTextField ({
  typeProps = {},
  injectable,
  injectableId,
  disabled,
  langVersion,
  depth,
  editMode,
  title,
  showPdfProps,
  showPrintProps,
  showPdfComponent,
  showPrintComponent,
  showEditableComponent,
  showEditableProps,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    required,
    forceRequired,
    expandable,
    rows,
    isAdvancedTextEditor,
    allowAddingTables,
    isEmail,
    isUrl,
    regexValidation,
    regexValidationMessage,
    lengthLimit,
    maxChar,
    isConnected,
    wordCount,
    showFieldLabel,
    requiresRequest,
    pdfBorderDisplay,
    allowPDFWrap
  } = typeProps

  if (!editMode) {
    if (showPrintComponent || showPdfComponent) {
      return (
        <FormTextInputPrint
          typeProps={typeProps}
          title={title}
          {...props}
          editorPreview
        />
      )
    }

    return <FormTextField typeProps={typeProps} title={title} {...props} />
  }

  const { connectedField } = getMainConnected({ typeProps, ...props })
  const forceReadOnly =
    (connectedField && connectedField.readOnly) || showPrintProps
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(readOnly && connectedField) || forceReadOnly}
            disabled={!connectedField || forceReadOnly || disabled}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.readOnly = e.target.checked
              delete toSet.wordCount
              delete toSet.required
              delete toSet.rows
              delete toSet.lengthLimit
              delete toSet.maxChar
              delete toSet.showFieldLabel
              delete toSet.expandable
              delete toSet.requiresRequest
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Read only</Trans>}
      />

      {forceReadOnly && showEditableProps ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !readOnly && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(required || forceRequired) || showPrintProps}
                  disabled={
                    readOnly || forceRequired || showPrintProps || disabled
                  }
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isEmail)}
                  disabled={readOnly || isAdvancedTextEditor || disabled}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isEmail = e.target.checked
                    if (e.target.checked) {
                      delete toSet.isUrl
                      // delete toSet.isPostalCode
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is email?</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isUrl)}
                  disabled={readOnly || isAdvancedTextEditor || disabled}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isUrl = e.target.checked
                    if (e.target.checked) {
                      delete toSet.isEmail
                      // delete toSet.isPostalCode
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is url?</Trans>}
            />

            <DebouncedTextField
              value={regexValidation || ''}
              disabled={readOnly || disabled}
              style={{ marginTop: 5, marginBottom: 5 }}
              fullWidth
              label={<Trans>Regex validation</Trans>}
              variant='outlined'
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.regexValidation = e.target.value
                if (!e.target.value) {
                  delete toSet.regexValidationMessage
                }
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />

            {regexValidation && (
              <ConfigureMultilanguageTextField
                value={regexValidationMessage}
                label={<Trans>Regex validation message</Trans>}
                handleChange={value => {
                  const toSet = { ...typeProps }
                  toSet.regexValidationMessage = value
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                useDebounce
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isAdvancedTextEditor)}
                  disabled={readOnly || disabled}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isAdvancedTextEditor = e.target.checked
                    delete toSet.showFieldLabel
                    delete toSet.expandable
                    delete toSet.isEmail
                    delete toSet.isUrl
                    delete toSet.allowAddingTables
                    toSet.rows = 1
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is an andvanced editor?</Trans>}
            />

            {isAdvancedTextEditor && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(allowAddingTables)}
                    disabled={readOnly || disabled}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.allowAddingTables = e.target.checked
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={
                  <Trans>FORM_EDITOR_TEXT_FIELD_SHOW_TABLE_QUESTION</Trans>
                }
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(showFieldLabel)}
                  disabled={readOnly || isAdvancedTextEditor || disabled}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.showFieldLabel = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show label in field?</Trans>}
            />

            <Grid container alignItems='center'>
              <Grid item xs style={{ padding: 5 }}>
                <DebouncedTextField
                  value={rows || '1'}
                  disabled={readOnly || isAdvancedTextEditor || disabled}
                  style={{ marginTop: 5, marginBottom: 5 }}
                  fullWidth
                  label={<Trans>Rows</Trans>}
                  InputProps={{ inputComponent: DefaultNumericFormat }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.rows = e.target.value
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>

              <Grid item style={{ paddingLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isAdvancedTextEditor || readOnly || disabled}
                      checked={Boolean(expandable)}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.expandable = e.target.checked
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={<Trans>Allow expanding rows?</Trans>}
                />
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly || disabled}
                  checked={Boolean(wordCount)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.wordCount = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show word count?</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly || disabled}
                  checked={Boolean(lengthLimit)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.lengthLimit = e.target.checked
                    delete toSet.maxChar
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Custom characters limit?</Trans>}
            />
            {lengthLimit && (
              <div style={{ width: 400 }}>
                <DebouncedTextField
                  disabled={disabled}
                  style={{ marginTop: 5, marginBottom: 5 }}
                  label={<Trans>Length limit</Trans>}
                  value={maxChar}
                  InputProps={{ inputComponent: DefaultNumericFormat }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.maxChar = e.target.value
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </div>
            )}
          </>
        )
      )}

      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextField}
        allowReadOnlyFields
        clearOnDisconnect={['isUrl', 'isEmail', 'requiresRequest']}
        clearOnReadOnlyField={[
          'wordCount',
          'required',
          'rows',
          'lengthLimit',
          'maxChar',
          'showFieldLabel',
          'expandable',
          'requiresRequest'
        ]}
      />
      {Boolean(isConnected && connectedField && !forceReadOnly) && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(requiresRequest)}
              disabled={disabled}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.requiresRequest = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Requires request to change value</Trans>}
        />
      )}

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showWrapOption
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
          wrapDisable={disabled}
        />
      )}
    </Grid>
  )
}
