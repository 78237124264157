import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { NumericFormat } from 'app/views/page-layouts/FormElement'
import { useDispatch } from 'react-redux'
import { getMainConnected } from '../../Form'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { validFieldTypesForTextFieldNumeric } from '../../editor/FormWizard'
import { FormTextInputPrint } from '../text-input/FormTextInputReadOnly'
import { FormNumericInput } from './FormNumericInput'
import DebouncedTextField from '../../common/DebouncedTextField'

export function FormEditorTextFieldNumeric ({
  typeProps = {},
  injectable,
  langVersion,
  showEditableProps,
  showPrintProps,
  showPdfProps,
  showPdfComponent,
  showPrintComponent,
  showEditableComponent,
  depth,
  editMode,
  title,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    required,
    allowPhoneExtension,
    isPhone,
    forceRequired,
    currency,
    showFieldLabel,
    max,
    min
  } = typeProps

  if (!editMode) {
    if (showPrintComponent) {
      return (
        <FormTextInputPrint
          typeProps={typeProps}
          title={title}
          {...props}
          editorPreview
        />
      )
    }
    return <FormNumericInput typeProps={typeProps} title={title} {...props} />
  }

  const { connectedField } = getMainConnected({ typeProps, ...props })
  const forceReadOnly = connectedField && connectedField.readOnly
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      {showEditableProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField)}
              disabled={!connectedField || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.min
                delete toSet.max
                delete toSet.allowPhoneExtension
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {forceReadOnly ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !readOnly &&
        showEditableProps && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(showFieldLabel)}
                  disabled={readOnly}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.showFieldLabel = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show label in field?</Trans>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly || forceRequired}
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />
          </>
        )
      )}

      {!readOnly && showEditableProps && (
        <div>
          <h6 style={{ marginBottom: 5, marginTop: 10 }}>Input restrictions</h6>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currency)}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.currency = e.target.checked
                  if (e.target.checked) {
                    delete toSet.isPhone
                    delete toSet.allowPhoneExtension
                  }
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Is currency?</Trans>}
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isPhone)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isPhone = e.target.checked
                    delete toSet.allowPhoneExtension
                    if (e.target.checked) {
                      delete toSet.currency
                      delete toSet.min
                      delete toSet.max
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is phone number?</Trans>}
            />

            {isPhone && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(allowPhoneExtension)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.allowPhoneExtension = e.target.checked
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Allow phone number extension?</Trans>}
              />
            )}

            <Grid container>
              <Grid item xs style={{ padding: 5 }}>
                <DebouncedTextField
                  label={<Trans>Max input</Trans>}
                  disabled={Boolean(isPhone)}
                  value={max || ''}
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  fullWidth
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.max = e.target.value
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <DebouncedTextField
                  label={<Trans>Min input</Trans>}
                  disabled={Boolean(isPhone)}
                  value={min || ''}
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  fullWidth
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.min = e.target.value
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextFieldNumeric}
        allowReadOnlyFields
        clearOnReadOnlyField={['required', 'min', 'max', 'allowPhoneExtension']}
      />
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          showCardOption
          showBorderOption
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
    </Grid>
  )
}
