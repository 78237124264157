import { Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer"
import { commonPdfStyles } from "../../common/Common"

const styles = StyleSheet.create({
	icon: {
		width: 25,
		height: 25,
	},
	link: {
		marginRight: 5,
		fontSize: 10,
		color: 'black',
	},
	imgContainer: {
		width:30,
		height:30,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	helpText: {
		fontSize: 10,
	},
	title: {
		...commonPdfStyles.title,
		fontSize: 12,
		fontWeight: 'bold',
	},
})

export const FormUploadFilesPdf = ({
    value = [],
    title,
		...props
  }) => {

		const helpText = props?.helpText
  
    return (
		<View>
      {title? <View><Text style={styles.title}>{title}</Text></View>: null}
			{value.map((file, index) => {
				return (
					<View key={file.id}>
						<View style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
							<View style={styles.imgContainer}>
								<Image
									src='/assets/images/pdf/download.jpg'
									style={styles.icon}
								/>
							</View>
							<Link style={styles.link} src={file.url}>{file.name}</Link>
						</View>
					</View>
				)
			})}
			{helpText? <View><Text style={styles.helpText}>{helpText}</Text></View>: null}
		</View>
	)
}