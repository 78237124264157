import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
import { Trans } from '@lingui/macro'
import {
  AccessibilityHelp,
  Alignment,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Bold,
  ClassicEditor,
  Essentials,
  GeneralHtmlSupport,
  Heading,
  Italic,
  Link,
  List,
  Paragraph,
  SelectAll,
  Table,
  TableToolbar,
  Undo,
  WordCount
} from 'ckeditor5'
import { useField, useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormErrorLabel } from '../../common/labels/FormErrorLabel'
import { MUEditedByLabel } from '../../forms/multiuser/components/MUEditedByLabel'
import {
  startEditingField,
  updateLockedFieldValue
} from '../../forms/multiuser/grpcMultiuserEdit'
import '../CKEditor/ckeditor5.css'

export const baseCKEeditorConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'selectAll',
      '|',
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'alignment',
      '|',
      'accessibilityHelp'
    ],
    shouldNotGroupWhenFull: false
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    AutoLink,
    Autosave,
    BalloonToolbar,
    Bold,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    Italic,
    Link,
    Paragraph,
    SelectAll,
    List,
    Undo,
    WordCount,
    Table,
    TableToolbar
  ],
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  balloonToolbar: ['bold', 'italic', '|', 'link'],
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6'
      }
    ]
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true
      }
    ]
  },
  initialData: '',
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  menuBar: {
    isVisible: true
  },
  placeholder: ''
}

// this items are used in the toolbar when the allowAddingTables is true
const tableToolbarItems = [
  'undo',
  'redo',
  '|',
  'selectAll',
  '|',
  'heading',
  '|',
  'bold',
  'italic',
  '|',
  'link',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'alignment',
  '|',
  'insertTable',
  '|',
  'accessibilityHelp'
]

export const FormikCKEditor = ({
  disabled,
  name,
  label,
  config,
  onBlur,
  wordLimit,
  useMultiuser,
  muBag,
  required,
  wordCount,
  maxCharacters,
  allowAddingTables,
  ...props
}) => {
  const { values } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const { value = '' } = field
  const [initialValueSet, setInitialValueSet] = useState(false)
  const [timer, setTimer] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [stats, setStats] = useState({ words: 0, characters: 0 })
  const ref = useRef()

  const user = useSelector(state => state.user)

  const { setValue } = helpers
  const { muInfo = {}, muUsers = {} } = values
  const muState = muInfo[name]
  let userColor, userName
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  const editedByOther = Boolean(
    muState && muState.locked && muState.user !== user.userId
  )
  let helperArray = []
  if (wordCount) {
    helperArray.push(
      <span key='wordsCount'>
        {stats.words} <Trans>words</Trans>
        {'. '}
      </span>
    )
  }
  if (Number(maxCharacters)) {
    helperArray.push(
      <span key='limit'>
        <span>
          {stats.characters + ' '}
          <Trans>chars</Trans>
          {' ('}
          <Trans>max</Trans>: {maxCharacters} <Trans>chars</Trans>
          {')'}
        </span>
        {'. '}
      </span>
    )
  }
  if (required) {
    helperArray.push(
      <span key='required'>
        <Trans>Required</Trans>
        {'. '}
      </span>
    )
  }

  // useEffect(() => {
  //   if (editedByOther) {
  //     setIsEditedByOther(true)
  //   }
  // }, [editedByOther])

  useEffect(() => {
    setInitialValueSet(false)
  }, [name])

  useEffect(() => {
    if (value && !initialValueSet) {
      // This handles initial value
      setInputValue(value)
      setInitialValueSet(true)
    }
  }, [value, initialValueSet])

  const inputChanged = () => {
    const newValue = ref.current.editor.getData()
    const wordCount = ref.current.editor.plugins.get('WordCount').words
    const charCount = ref.current.editor.plugins.get('WordCount').characters
    let error
    if (maxCharacters && maxCharacters < charCount) {
      error = true
    }
    if (wordLimit && wordCount > wordLimit) {
      error = true
    }
    setInputValue(newValue)
    clearTimeout(timer)
    if (error) {
      if (newValue !== inputValue) {
        ref.current.editor.setData(inputValue)
      }
    } else {
      const newTimer = setTimeout(() => {
        setValue(newValue)
        if (useMultiuser) {
          updateLockedFieldValue({
            ...muBag,
            fieldId: name,
            fieldValue: newValue,
            lockId: values.muInfo.lockId
          })
        }
      }, 700)
      setTimer(newTimer)
    }
  }

  const _baseCKEeditorConfig = allowAddingTables
    ? {
        ...baseCKEeditorConfig,
        toolbar: { items: tableToolbarItems }
      }
    : baseCKEeditorConfig

  return (
    <div>
      <CKEditor5
        ref={ref}
        editor={ClassicEditor}
        config={{
          ..._baseCKEeditorConfig,
          wordCount: {
            onUpdate: stats => {
              setStats(stats)
            }
          }
        }}
        data={inputValue}
        disabled={disabled || editedByOther}
        onChange={(evt, editor) => {
          inputChanged()
        }}
        onBlur={(e, editor) => {
          if (onBlur) {
            onBlur(e)
          }
          if (!meta.touched) {
            helpers.setTouched(true)
          }
        }}
        onFocus={(e, editor) => {
          if (useMultiuser) {
            startEditingField({
              ...muBag,
              fieldId: name
            })
          }
        }}
      />
      {useMultiuser && (
        <MUEditedByLabel color={userColor} userName={userName} />
      )}
      <FormErrorLabel
        label={helperArray}
        id={name}
        error={meta.error && meta.touched}
      />
    </div>
  )
}
